import React, { Component, Fragment } from 'react';
import {
    getCustomer,
    getOrder,
    getProductGroups,
    getUnits,
    reverseFormatPhone,
    formatProductType
} from '../../actions';
import { connect } from 'react-redux';
import { Header, Loading } from '../Common';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { getProductsFromEntries } from '../../helpers/productHelpers';
import './receipt.css'
const fontAwesome = (
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" rel="stylesheet" />
);

class Receipt extends Component {
    constructor(props) {
        super(props);

        this.setLocationState = this.setLocationState.bind(this);
        this.setPawPrints = this.setPawPrints.bind(this);

        this.state = {};
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const { orderId, customerId } = params;
        Auth.currentAuthenticatedUser().then(() => {
            this.props.getOrder(customerId, orderId);
            this.props.getCustomer(customerId);
            this.props.getProductGroups();
        }).catch(err => {
            this.props.history.push('/')
        })

        this.setLocationState(this.props.location)
    }

    componentDidUpdate() {
        const {
            customer,
            getCustomerError,
            order,
            getOrderError,
            productGroups,
            productGroupsError
        } = this.props;

        if (customer && !this.state.customer) {
            this.setState({ customer })
            this.setPawPrints(customer.interview.pets)
        }

        if (getCustomerError && !this.state.getCustomerError)
            this.setState({ getCustomerError })

        if (order && !this.state.order)
            this.setState({ order })

        if (getOrderError && !this.state.getOrderError)
            this.setState({ getOrderError })

        if (productGroups && !this.state.productGroups)
            this.setState({ productGroups })

        if (productGroupsError && !this.state.productGroupsError)
            this.setState({ productGroupsError })
    }

    setLocationState(location) {
        try {
            this.setState({ netSuite: location.state.netSuite })
        } catch (err) {
            console.error('No sales order')
        }
    }

    setPawPrints(pets) {
        if (pets) {
            let dogCount = 0;
            let pawPrints = [];
            dogCount += pets.Puppy += pets.Dog;
            if (dogCount) {
                for (let i = 0; i < dogCount; i++) {
                    pawPrints.push(
                        <i className="fa fa-paw paw-print"></i>
                    )
                }
            }
            this.setState({ pawPrints })
        }
    }

    sortProductsForPackingSlip(products) {
        // sort so TP and Paper Towels are first,
        // followed by other products alphabetically
        const isPaperProduct = (productType) => {
            return (
                productType.toLowerCase() === "papertowels" ||
                productType.toLowerCase() === "toiletpaper"
            )
        }

        const paperProducts = products.filter(p => isPaperProduct(p.productType));
        paperProducts.forEach(p => products.splice(products.findIndex(idx => idx.productType === p.productType), 1))

        products.sort((a,b) => {
            if(a.brandName > b.brandName) { return 1; }
            if(a.brandName < b.brandName) { return -1; }
            return 0;
        })

        return [ ...paperProducts, ...products]
    }

    renderCustomerInfo() {
        const { customer, order, netSuite } = this.state;
        if (customer && order && netSuite) {
            const { firstName, lastName, shipping, phone, email } = customer;
            const { expectedShipDate, expectedDeliveryDate } = order;
            const { address, city, state, zipcode } = shipping;
            const formattedPhone = reverseFormatPhone(phone);
            return (
                <Fragment>
                    <div className="receipt-top">
                        <div className="receipt-copy">
                            {this.renderNSOrderNum(netSuite.salesOrderName)}
                            Order Date: {moment(expectedShipDate).format('ll')}<br />
                            Delivery Date: {moment(expectedDeliveryDate).format('ll')}
                        </div>
                        <div className="receipt-copy">
                            <b>Deliver to</b><br />
                            {firstName} {lastName}<br />
                            {formattedPhone}<br />
                            {email}<br />
                            {address}<br />
                            {city}, {state} {zipcode}
                        </div>
                        <div className="receipt-copy">
                            <b>Return address</b><br />
                            Supply Drop<br />
                            301 Chicon St, Ste. B<br />
                            Austin, TX 78702
                        </div>
                    </div>
                    {this.renderAlert(formattedPhone)}
                </Fragment>
            )
        }
    }

    renderNSOrderNum(orderNum) {
        if (orderNum) {
            return (
                <Fragment>
                    <b>Order #: {orderNum}</b><br />
                </Fragment>
            )

        }
    }

    renderAlert(phone) {
        return (
            <div className="receipt-alert">
                We will text you at {phone} a week before your next order.
                If you need anything before your next delivery text us at 303-89 and we’ll get you covered.
            </div>
        )
    }

    renderTable() {
        const { order, customer, productGroups } = this.state;
        if (order && customer && productGroups) {
            const { productGroupEntries } = order
            const products = getProductsFromEntries(productGroupEntries, productGroups);
            const sortedProducts = this.sortProductsForPackingSlip(products);
            const rows = sortedProducts.map(product => {
                const { imageUrl, title, articles, displayUnits, articleType, productType } = product;
                const units = getUnits(displayUnits ? displayUnits : articleType, articles, true)
                return (
                    <tr className="receipt-row">
                        <td>
                            <img src={imageUrl} alt={title} className="receipt-row-img" />
                        </td>
                        <td>
                            {title} {formatProductType(productType)}
                        </td>
                        <td>
                            {articles} x {units}
                        </td>
                    </tr>
                )
            })

            return (
                <table className="receipt-table">
                    <thead>
                        <tr>
                            <th className="receipt-table-header">Item</th>
                            <th className="receipt-table-header"></th>
                            <th className="receipt-table-header">Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            )
        }
    }

    renderError() {
        const { getOrderError, getCustomerError } = this.state;
        if (getOrderError || getCustomerError) {
            return (
                <div className="receipt-error">
                    <b>Something went wrong.</b><br />
                    We are not able to show the receipt for this order at the moment. Please try again at another time.
                </div>
            )
        }
    }

    renderLoading() {
        const { customer, order, getOrderError, getCustomerError } = this.state;
        if (!(customer && order) && !getOrderError && !getCustomerError) {
            return (
                <div className="receipt-loading-container">
                    <Loading />
                </div>
            )
        }
    }

    renderPaws() {
        const { pawPrints } = this.state;
        if (pawPrints) {
            return pawPrints;
        }
    }

    render() {
        return (
            <div className="no-cloud no-cloud-white">
                {fontAwesome}
                <div className="receipt-container">
                    <Header />
                    {this.renderCustomerInfo()}
                    {this.renderError()}
                    {this.renderLoading()}
                    {this.renderTable()}
                    {this.renderPaws()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { customer, getCustomerError, gettingCustomer } = state.customers;
    const { gettingOrder, order, getOrderError } = state.orders;
    const { productGroups, productGroupsError } = state.products;
    return { customer, getCustomerError, gettingCustomer, gettingOrder, order, getOrderError, productGroups, productGroupsError }
}

export default connect(mapStateToProps, {
    getCustomer,
    getOrder,
    getProductGroups
})(Receipt);