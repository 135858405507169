import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getManifest, clearCustomersState, getProductGroups } from '../../actions';
import { Row, Col, Container } from 'react-bootstrap';

export class Projections extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.props.clearCustomersState();
        this.props.getProductGroups();
    }

    componentDidUpdate() {
        const { customer, manifestRes, productGroups } = this.props;

        if (customer && !this.state.customer) {
            this.setState({ customer });
            this.props.getManifest(customer.customerId);
        }

        if (manifestRes && !this.state.manifest) {
            this.setState({ manifest: manifestRes.manifest });
        }

        if(productGroups && !this.state.productGroups) {
            const productGroupsMap = {};
            productGroups.forEach(pg => {
                productGroupsMap[pg.groupName] = {};
                productGroupsMap[pg.groupName].groupName = pg.groupName;
                productGroupsMap[pg.groupName].brandName = pg.brandName;
                productGroupsMap[pg.groupName].displayName = pg.displayName;
                productGroupsMap[pg.groupName].pricePerArticle = pg.pricePerArticle;
                productGroupsMap[pg.groupName].articleType = pg.articleType;
                productGroupsMap[pg.groupName].badges = pg.badges;
                productGroupsMap[pg.groupName].articles = pg.possibleArticleCounts[0];
                productGroupsMap[pg.groupName].imageUrl = pg.imageUrl;
                productGroupsMap[pg.groupName].description = pg.description;
                productGroupsMap[pg.groupName].productType = pg.productType;
                productGroupsMap[pg.groupName].possibleArticleCounts = pg.possibleArticleCounts;
            })
            sessionStorage.setItem('productGroupsMap', JSON.stringify(productGroupsMap));
            this.setState({productGroups, productGroupsMap});
        }
    }

    renderIssues() {
        const { manifest } = this.state;
        if (manifest) {
            const { issues } = manifest;

            if (issues && issues.length) {
                const rows = issues.map(issue => {
                    return <li>{issue}</li>
                })
                return (
                    <div>
                        <h3 className="red">Customer Issues:</h3>
                        <ul>
                            {rows}
                        </ul>
                    </div>
                )
            }
        }
    }

    renderManifest() {
        const { manifest, productGroupsMap } = this.state;
        if (manifest && productGroupsMap) {
            const rows = [];
            let total = 0;
            Object.values(manifest.products).forEach(val => {
                try {
                    const {articleCount, productChangeReason, quantityChangeReason} = val;
                    const product = productGroupsMap[val.groupName];
                    const price = articleCount * product.pricePerArticle/100;
                    total += price;
                    rows.push(
                        <tr>
                            <th>{product.productType}</th>
                            <th>{product.brandName} {product.displayName}</th>
                            <th>{articleCount}</th>
                            <th>${price.toFixed(2)}</th>
                            <td>
                                <b>Product Change Reason:</b> {productChangeReason}<br/>
                                <b>Quantity Change Reason:</b> {quantityChangeReason}
                                
                            </td>
                        </tr>
                    )
                } catch (err) {
                    console.error(err);
                }
            })
            rows.push(
                <tr>
                    <th></th>
                    <th></th>
                    <th>Subtotal:</th>
                    <th>${total.toFixed(2)}</th>
                    <th></th>
                </tr>
            )
            return (
                <Row>
                    <Col xs={12}>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Product Type</th>
                                    <th>Product</th>
                                    <th>Articles</th>
                                    <th>Price</th>
                                    <th>Change Reasons</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            )
        }
    }

    render() {
        return (
            <Container>
                <h2>Projected Manifest</h2>
                {this.renderIssues()}
                {this.renderManifest()}
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    const { customer, error, manifestRes, manifestError } = state.customers;
    const {productGroups, productGroupsError} = state.products;
    return { customer, error, manifestRes, manifestError, productGroups, productGroupsError };
}

export default connect(mapStateToProps, {
    getManifest,
    clearCustomersState,
    getProductGroups
})(Projections);