import { CLEAR_GLOBAL_ERRORS } from './types';
import { handleApiError } from '../helpers/apiHelpers';

export const clearGlobalErrors = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_GLOBAL_ERRORS })
    }
}

export const globalErrorTest = () => {
    console.log('globalErrorTest')
    return (dispatch) => {
        const error = new Error('failed to create signature')
        error.name = 'AuthError'
        dispatch(handleApiError(error))
    }
}