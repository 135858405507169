import axios from 'axios';
import {
    GET_REPORT_SUCCESS,
    GET_REPORT_FAIL,
    GETTING_REPORT,
    GETTING_REPORTS,
    GET_REPORTS_SUCCESS,
    GET_REPORTS_FAIL,
    CLEAR_REPORT,
    UNAUTHORIZED
} from './types';
import { getAuthHeaders, handleApiError } from '../helpers/apiHelpers';
import keys from '../config';
const url = keys.apiUrl;

export const getReports = () => {
    return (dispatch) => {
        dispatch({ type: GETTING_REPORTS })
        getAuthHeaders().then(headers => {
            axios.get(`${url}/admin/reports`, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_REPORTS_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, GET_REPORTS_FAIL))
                })
                .catch(err => {
                    if (err === 'UNAUTHORIZED')
                        dispatch(handleApiError(err, UNAUTHORIZED))
                    else
                        dispatch(handleApiError(err, GET_REPORTS_FAIL))
                })
        }).catch(err => {
            dispatch(handleApiError(err, GET_REPORTS_FAIL))
        })
    }
}

export const getReport = (presginedUrl) => {
    return (dispatch) => {
        dispatch({ type: GETTING_REPORT })
        axios.get(presginedUrl)
            .then(res => {
                if (res.status === 200)
                    dispatch({ type: GET_REPORT_SUCCESS, payload: res.data });
                else
                    dispatch(handleApiError(res.error, GET_REPORT_FAIL))
            })
    }
}

export const clearReport = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_REPORT })
    }
}
