import React from 'react';
import {Col, Alert} from 'react-bootstrap';
import {Auth} from 'aws-amplify';
import {
    isAdmin,
    getCustomer,
    getProductGroups,
    productOptions,
    createEmergencyOrder,
    formatProductType
} from '../../actions';
import Header from '../Header';
import Details from '../Customers/Details';
import CustomerNav from '../Customers/CustomerNav';
import {connect} from 'react-redux';
import moment from 'moment';
import { Loading } from '../Common';

class EmergencyOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: {}
        };

        this.renderProductRows = this.renderProductRows.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.sendOrder = this.sendOrder.bind(this);
        this.clearProduct = this.clearProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.editProduct = this.editProduct.bind(this);
    }

    componentDidMount() {    
        Auth.currentAuthenticatedUser().then(data => {
            const jwt = data.signInUserSession.idToken.jwtToken;
            const authed = isAdmin(jwt);
            if(!authed) {
                this.props.history.push('/');
            }
        }).catch(err => {
            this.props.history.push('/');
        });
        const { match: { params } } = this.props;
        const { customerId } = params;

        this.setState({customerId});
        this.props.getCustomer(customerId);
        this.props.getProductGroups();
    }

    componentDidUpdate() {
        const {
            customer,
            productGroups,
            creatingOrder
        } = this.props;

        if(productGroups && !this.state.productGroups) {
            const productGroupsMap = {};
            productGroups.forEach(pg => {
                productGroupsMap[pg.groupName] = pg;
                productGroupsMap[pg.groupName].articles = pg.possibleArticleCounts.length ? pg.possibleArticleCounts[0] : 0;
            })
            sessionStorage.setItem('productGroupsMap', JSON.stringify(productGroupsMap));
            this.setState({productGroups, productGroupsMap});
        }

        if(creatingOrder && !this.state.creatingOrder) {
            this.setState({creatingOrder});
        }


        if(customer && !this.state.customer) {
            this.setState({customer});
        }
    }

    setOptions(manifest, productGroupsMap) {
        try {
            const products = {};

            Object.entries(manifest.products).forEach(entry => {
                productGroupsMap[entry[1].groupName].articles = entry[1].articleCount;
                products[entry[0]] = productGroupsMap[entry[1].groupName];
            });    
            sessionStorage.setItem('productGroupsMap', JSON.stringify(productGroupsMap));
            this.setState({productGroupsMap, products});
        } catch(err) {
            console.error(err);
        }
    }

    clearProduct() {
        this.setState({isAdding: false, selectedProduct: undefined})
    }

    removeProduct(productType) {
        const {products} = this.state;
        if(products[productType]) {
            delete products[productType]
            this.setState({products});
        } else {
            console.error(`Product not found for product type of ${productType}`)
        }
    }

    increment() {
        const {productGroupsMap, selectedProduct} = this.state;
        try {
            const product = productGroupsMap[selectedProduct];
            const packages = product.possibleArticleCounts;
            const index = packages.indexOf(product.articles);
            if(packages.length <= index + 1 || index === -1) {
                if(packages[0])
                    product.articles += packages[0];
                else if (packages[1])
                    product.articles += packages[1];
                else
                    this.setState({errMessage: "No known article counts for this product group"})
            } else {
                product.articles = packages[index + 1];
            }
            productGroupsMap[selectedProduct] = product;
            this.setState({productGroupsMap});
        } catch(err) {
            console.error(err);
        }
    }

    decrement() {
        const {productGroupsMap, selectedProduct} = this.state;
        try {
            const product = productGroupsMap[selectedProduct];
            const packages = productGroupsMap[selectedProduct].possibleArticleCounts;
            const index = packages.indexOf(product.articles);
            if(index > 0) {
                product.articles = packages[index - 1];
            } else {
                product.articles ? product.articles -= packages[0] : product.articles = 0;
            }
            productGroupsMap[selectedProduct] = product;
            this.setState({productGroupsMap});
        } catch(err) {
            console.error(err);
        }
    }

    renderCounter() {
        const {selectedProduct, productGroupsMap} = this.state;
        if(selectedProduct) {
            const product = productGroupsMap[selectedProduct];
            const {articles, articleType, pricePerArticle} = product;
            const units = articles === 1 ? articleType : articleType + (articleType.toLowerCase() === 'box' ? 'es' : 's');
            return (
                <div>
                    {this.renderAlert()}
                    <div className="counter-body">
                        <div className="counter-button" onClick={this.decrement}>—</div>
                        <div className="counter-value">{articles} {units}</div>
                        <div className="counter-button" onClick={this.increment}>+</div>
                    </div>
                    <div className="counter-body green">
                        <div>${(articles * pricePerArticle/100).toFixed(2)}</div>
                    </div>
                </div>
            )
        }   
    }

    renderAlert() {
        const {selectedProduct, products, productGroupsMap} = this.state;
        const product = productGroupsMap[selectedProduct];
        if(products[product.productType] && products[product.productType].groupName !== selectedProduct) {
            return (
                <Alert className="alert-small" variant="warning">Adding this product will replace {products[product.productType].brandName} {products[product.productType].displayName}</Alert>
            )
        }
    }

    onSaveClick() {
        const {selectedProduct, productGroupsMap, products} = this.state;
        const product = productGroupsMap[selectedProduct];
        products[product.productType] = product;
        this.setState({products, isAdding: false, selectedProduct: undefined})
    }

    sendOrder() {
        const {products, customerId} = this.state;

        const order = {}
        Object.values(products).forEach(product => {
            order[product.groupName] = {articleCount: product.articles};
        })

        this.props.createEmergencyOrder(customerId, order);
    }

    renderAddProduct() {
        const {products, productGroupsMap} = this.state;
        if(productGroupsMap) {
            return (
                <div className="flex-column text-center">
                    <div className="flex-row flex-space-around button-wrapper">
                        <button className="btn-primary dialog-btn-ok" onClick={() => this.setState({isAdding: true})}>Add Product</button>
                        <button className="btn-success dialog-btn-ok" disabled={!Object.values(products).length} onClick={this.sendOrder}>Complete Order</button>
                    </div>
                </div>
            )
        } else {
            return (
                <Loading />
            )
        }
    }

    editProduct(groupName) {
        this.setState({selectedProduct: groupName, isAdding: true})
    }

    renderProductRows() {
        const {products, customer} = this.state;
        let rows = [];
        if(products && customer && Object.values(products).length) {
            let subtotal = 0;
            rows = Object.values(products).map(product => {
                const {brandName, displayName, articles, articleType, pricePerArticle, productType, groupName} = product;
                subtotal += articles * pricePerArticle/100;
                return (
                    <tr>
                        <th>{formatProductType(productType)}</th>
                        <th>{brandName} {displayName}</th>
                        <td>{articles} {articles === 1 ? articleType : articleType + (articleType.toLowerCase() === 'box' ? 'es' : 's')}</td>
                        <td className="text-right">${(articles * pricePerArticle/100).toFixed(2)}</td>
                        <th onClick={() => this.removeProduct(productType)} className="red pointer text-right">Remove</th>
                        <th onClick={() => this.editProduct(groupName)} className="blue">Edit</th>
                    </tr>
                )
            })
            const tax = customer.shipping.state === "TX" ? subtotal * 0.0825 : 0;
            rows.push(
                <tr>
                    <td></td>
                    <td></td>
                    <th>Subtotal:</th>
                    <th className="text-right">${subtotal.toFixed(2)}</th>
                    <td></td>
                    <td></td>
                </tr>
            )
            rows.push(
                <tr>
                    <td></td>
                    <td></td>
                    <th>Estimated Tax:</th>
                    <th className="text-right">${tax.toFixed(2)}</th>
                    <td></td>
                    <td></td>
                </tr>
            )
            rows.push(
                <tr>
                    <td></td>
                    <td></td>
                    <th>Total:</th>
                    <th className="text-right">${(subtotal + tax).toFixed(2)}</th>
                    <td></td>
                    <td></td>
                </tr>
            )
            return rows;
        }
    }

    renderProductsModal() {
        const {
            products,
            productGroupsMap,
            selectedProduct,
            isAdding
        } = this.state;
    
        if(productGroupsMap && products && isAdding) {
            const optGroups = [];
            const productTypes = {};

            Object.values(productGroupsMap).forEach(group => {
                if(group.status === "Active" || group.status === "Legacy") {
                    if(!productTypes[group.productType]) productTypes[group.productType] = [group];
                    else productTypes[group.productType].push(group);
                }
            });
            Object.entries(productTypes).forEach(entry => {
                const productType = entry[0];
                const values = entry[1];
                const options = [];
                values.forEach(group => {
                    options.push(
                        <option key={group.groupName} label={`${group.brandName} ${group.displayName} ${formatProductType(group.productType)} - (${group.status})`} value={group.groupName} />
                    )
                })
                optGroups.push(
                    <optgroup label={productType}>
                        {options}
                    </optgroup>
                )
            })
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column text-center">
                        <div className="dialog-title-text">
                            {selectedProduct ? "Edit Product" : "Add Product"}
                        </div>
                        <div className="dialog-body-add-factor">
                            <div className="product-add-container">
                                <select className="product-select lg" onChange={(e) => this.setState({selectedProduct: e.target.value})}>
                                    <option>Select Product...</option>
                                    {optGroups}
                                </select>
                                {this.renderCounter()}
                            </div>
                        </div>
                        <div className="flex-row flex-space-between button-wrapper">
                            <button className="btn-success dialog-btn-ok" disabled={!selectedProduct} onClick={this.onSaveClick}>
                                Add
                            </button>
                            <button className="btn-primary dialog-btn-ok" onClick={this.clearProduct}>Cancel</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderSendModal() {
        const {creatingOrder} = this.state;
        const {orderCreateSuccess, orderCreateError} = this.props;
        if(creatingOrder) {
            if(orderCreateSuccess) {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-medium flex-column text-center">
                            <div className="dialog-title-text green">
                                Success! Order created 
                            </div>
                            <div className="dialog-body-add-factor">
                                Order Id: {orderCreateSuccess.orderId}<br/>
                                Delivery Method: {orderCreateSuccess.deliveryMethod}<br/>
                                Expected Delivery: {moment.utc(orderCreateSuccess.expectedDeliveryDate).format('ll')}
                            </div>
                            <div className="flex-row flex-space-between button-wrapper">
                                <button className="btn-primary dialog-btn-ok" onClick={() => this.setState({creatingOrder: false})}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            } else if(orderCreateError) {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-medium flex-column text-center red">
                            <div className="dialog-title-text">
                                Something went wrong.
                            </div>
                            <div className="dialog-body-add-factor">
                                {JSON.stringify(orderCreateError)}
                            </div>
                            <div className="flex-row flex-space-between button-wrapper">
                                <button className="btn-primary dialog-btn-ok" onClick={() => this.setState({creatingOrder: false})}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-medium flex-column text-center">
                            <div className="dialog-title-text">
                                Creating Order...
                            </div>
                        </div>
                    </div>
                )
            }

        }
    }

    render() {
        const {customerId} = this.state;
        return (
            <div>
                <Header />
                <CustomerNav history ={this.props.history} customerId={customerId} />
                <div className="sdrop-body flex-column">
                    {this.renderProductsModal()}
                    {this.renderSendModal()}
                    <Details />
                    <Col xs={12}><h2 style={{ marginTop: 20, marginLeft: 20 }}>Emergency Order</h2></Col>
                    <Col xs={12}>
                        <div>
                            {this.renderAddProduct()}
                        </div>
                    </Col>
                    <Col xs={12} className="flex-row flex-space-around">               
                        <div className="table-wrapper products-table">                     
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Product Type</th>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th className="text-right">Price</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderProductRows()}
                                </tbody>
                            </table>
                        </div>
                    </Col>                
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {customer, error} = state.customers;
    const {orderCreateSuccess, orderCreateError, creatingOrder} = state.orders;
    const {productGroups, productGroupsError} = state.products;
    return {customer, error, orderCreateError, orderCreateSuccess, creatingOrder, productGroups, productGroupsError};
}

export default connect(mapStateToProps, {
    getCustomer,
    getProductGroups,
    productOptions,
    createEmergencyOrder
})(EmergencyOrder);