import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getCustomerOrders,
    getCustomer,
    recharge,
    clearOrderState,
    getProductGroups
} from '../../actions';
import moment from 'moment';
import Header from '../Header';
import CustomerNav from './CustomerNav';
import { Loading } from '../Common';
import { Col, Row } from 'react-bootstrap';
import Refund from './Refund';
import * as Sentry from '@sentry/browser';
import { getFullOrder } from '../../helpers/orderHelpers';

class Orders extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.attemptRecharge = this.attemptRecharge.bind(this);
        this.clearAndClose = this.clearAndClose.bind(this);
        this.setToRefund = this.setToRefund.bind(this);
        this.updateOrderProductsMaps = this.updateOrderProductsMaps.bind(this);
        this.mapOrders = this.mapOrders.bind(this);
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const { customerId } = params;

        if (customerId && !this.state.customerId) {
            this.setState({ customerId });
            this.props.getCustomerOrders(customerId);
            this.props.getCustomer(customerId);
            this.props.getProductGroups();
        }

        if (!customerId && !this.state.loading) {
            this.setState({ loading: true });
            this.props.history.push('/')
        }
    }

    componentDidUpdate() {
        const { newOrders, customer, productGroups } = this.props;
        if (newOrders && !this.state.orders) {
            this.props.clearOrderState();
            this.setState({ orders: newOrders });
            this.mapOrders(newOrders)
        }

        if (customer && !this.state.customer) {
            this.setState({ customer });
        }

        if (productGroups && !this.state.productGroups) {
            try {
                const productGroupsMap = {};
                productGroups.forEach(group => {
                    productGroupsMap[group.groupName] = {};
                    productGroupsMap[group.groupName] = group;
                })
                if (this.state.ordersMap)
                    this.updateOrderProductsMaps(productGroupsMap);

                this.setState({ productGroups, productGroupsMap });
            } catch (err) {
                console.error(err);
            }
        }
    }

    updateOrderProductsMaps(productGroupsMap) {
        try {
            const { ordersMap } = this.state;
            Object.values(ordersMap).forEach(order => {
                Object.values(order.productsMap).forEach(group => {
                    const { groupName } = group;
                    if (productGroupsMap[groupName]) {
                        const { displayName, brandName, productType } = productGroupsMap[groupName];
                        Object.assign(group, { displayName, brandName, productType })
                    }
                })
            })
            this.setState({ ordersMap })
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    }

    mapOrders(orders) {
        try {
            const { productGroupsMap } = this.state;
            const ordersMap = {};
            orders.forEach(order => {
                ordersMap[order.orderId] = getFullOrder(order, productGroupsMap);
            })
            this.setState({ ordersMap })
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    }

    setToRefund(orderId) {
        const { ordersMap } = this.state;
        const refundOrder = ordersMap[orderId];
        if (refundOrder) {
            this.setState({ refundOrder })
        } else {
            console.error('order not found')
        }
    }

    attemptRecharge() {
        const { customerId, rechargeOrderId } = this.state;
        this.props.recharge(customerId, rechargeOrderId)
    }

    clearAndClose() {
        this.setState({ rechargeOrderId: null, refundOrder: null })
        this.props.clearOrderState();
    }

    renderRechargeBtn(status, orderId) {
        if (status === "PaymentFailed" || status === "AwaitingPayment") {
            return (
                <button className="btn-danger" onClick={() => this.setState({ rechargeOrderId: orderId })}>Attempt Recharge</button>
            )
        }
    }

    renderOrderRows() {
        const { ordersMap } = this.state;
        if (ordersMap) {
            const orderTables = Object.values(ordersMap).map(order => {
                const {
                    productsMap,
                    orderId,
                    subtotalCents,
                    discountCents,
                    expectedDeliveryDate,
                    expectedShipDate,
                    refundCents,
                    status,
                    taxCents
                } = order

                const productRows = Object.values(productsMap).map(product => {
                    const {
                        productType,
                        brandName,
                        displayName,
                        groupName,
                        articleType,
                        pricePerArticleCents,
                        chargedQuantity,
                        articlesPerPackage,
                        refundQuantity
                    } = product;
                    const articleCount = articlesPerPackage * chargedQuantity;
                    const refundArticleCount = articlesPerPackage *  refundQuantity;
                    const price = pricePerArticleCents * articleCount / 100;
                    return (
                        <tr>
                            <td>{productType}</td>
                            <td>{brandName} {displayName}<br />{groupName}</td>
                            <td>{articleCount} {articleType}(s)
                                {refundQuantity ? <b><br/>Refunded: {refundArticleCount} {articleType}(s)</b> : null}
                            </td>
                            <td>${price.toFixed(2)}</td>
                        </tr>
                    )
                })

                const totals = [];

                totals.push(
                    { name: 'Subtotal', value: subtotalCents },
                )

                if (discountCents)
                    totals.push({ name: 'Discount', value: -discountCents })

                totals.push(
                  { name: 'Tax', value: taxCents },
                )

                totals.push({ name: 'Total', value: (taxCents + subtotalCents - discountCents) })

                const table = (
                    <div className="table-wrapper">
                        <h2>Order Id: {orderId}</h2>
                        <h3>Ship Date: {moment.utc(expectedShipDate).format('MMM DD YYYY')} | Delivery Date: {moment.utc(expectedDeliveryDate).format('MMM DD YYYY')}</h3>
                        <h3>Status: {status}</h3>
                        <div className={status === "PaymentSucceeded" && !refundCents ? "btn-primary" : "hide"} style={{ width: 120, textAlign: "center" }} onClick={() => this.setToRefund(orderId)}>Refund Order</div>
                        {this.renderRechargeBtn(status, orderId)}
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Product Type</th>
                                    <th>Product Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productRows}
                                {this.renderTotals(totals)}
                            </tbody>
                        </table>
                    </div>
                )
                return { expectedDeliveryDate, table };
            })

            orderTables.sort((a, b) => {
                if (a.expectedDeliveryDate > b.expectedDeliveryDate) { return -1; }
                if (a.expectedDeliveryDate < b.expectedDeliveryDate) { return 1; }
                return 0;
            })

            const sortedTables = orderTables.map(order => {
                return order.table;
            })

            return sortedTables;
        } else {
            return (<Loading />)
        }
    }

    renderTotals(totals) {
        const rows = totals.map(entry => {
            const { name, value } = entry;
            return (
                <tr>
                    <th></th>
                    <th></th>
                    <th>{name}:</th>
                    <th>${(value / 100).toFixed(2)}</th>
                </tr>
            )
        })
        return rows;
    }

    formatPhone(value) {
        let num = '';
        num = `(${value.slice(2, 5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`;
        return num;
    }

    renderCustomerInfo() {
        const { customer } = this.state;
        if (customer) {
            return (
                <Col xs={9} className="customer-card table-wrapper">
                    <Row><h3>Order History for {customer.customerId}</h3></Row>
                    <Row><b>{customer.firstName} {customer.lastName}</b></Row>
                    <Row>{customer.shipping.address}</Row>
                    <Row>{customer.shipping.city}, {customer.shipping.state} {customer.shipping.zipcode}</Row>
                    <Row>{this.formatPhone(customer.phone)} - {customer.email}</Row>
                </Col>
            )
        }
    }

    renderRechargeModal() {
        const { rechargeOrderId } = this.state;
        const { recharging, rechargeSuccess, rechargeError } = this.props;
        if (recharging) {
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column text-center">
                        <div className="dialog-title-text">
                            Attempt to recharge payment method...
                    </div>
                    </div>
                </div>
            )
        } else if (rechargeSuccess) {
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column text-center">
                        <div className="dialog-title-text green">
                            Successfully recharged payment method! :)
                    </div>
                        <button className="btn btn-secondary dialog-btn-ok" onClick={this.clearAndClose}>Close</button>
                    </div>
                </div>
            )
        } else if (rechargeError) {
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column text-center">
                        <div className="dialog-title-text red">
                            Unable to recharge payment method :( <br />
                        Error: {JSON.stringify(rechargeError.message)}
                        </div>
                        <button className="btn btn-secondary dialog-btn-ok" onClick={this.clearAndClose}>Close</button>
                    </div>
                </div>
            )
        } else if (rechargeOrderId) {
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column text-center">
                        <div className="dialog-title-text">
                            Pause/Deactivate Customer
                    </div>
                        <div>Attempt to recharge payment method for order <b>{rechargeOrderId}</b>?</div>
                        <div className="flex-col-center flex-space-around modal-button-col">
                            <button className="btn btn-secondary dialog-btn-ok" onClick={() => this.setState({ rechargeOrderId: null })}>Cancel</button>
                            <button className="btn-danger dialog-btn-ok" onClick={this.attemptRecharge}>Recharge</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderRefund() {
        const { refundOrder, customer } = this.state;
        if (refundOrder && customer) {
            return <Refund order={refundOrder} productsMap={refundOrder.productsMap} groups={refundOrder.groups} customer={customer} close={this.clearAndClose} />
        }
    }

    render() {
        const { customerId } = this.state;
        return (
            <div>
                <Header />
                {this.renderRechargeModal()}
                <CustomerNav history={this.props.history} customerId={customerId} />
                <div className="sdrop-body flex-column">
                    {this.renderCustomerInfo()}
                    {this.renderOrderRows()}
                    {this.renderRefund()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { newOrders, rechargeSuccess, rechargeError, recharging } = state.orders;
    const { productGroups, productGroupsError } = state.products;
    const { customer } = state.customers;
    return { newOrders, customer, rechargeSuccess, rechargeError, recharging, productGroups, productGroupsError };
}

export default connect(mapStateToProps, {
    getCustomerOrders,
    getCustomer,
    recharge,
    clearOrderState,
    getProductGroups
})(Orders);
