import React, { Component } from 'react';
import Header from '../Header'
import { connect } from 'react-redux';
import {
    getRounder,
    clearRounder,
    optimize,
    isAdmin
} from '../../actions';
import AddSimulatorFamily from './AddSimulatorFamily';
import images from '../../assets/images';
import {Alert} from 'react-bootstrap';
import moment from 'moment';
import {Row, Col, Container} from 'react-bootstrap';
import './simulator.css';
import base64 from 'base-64';
import utf8 from 'utf8';
import {Auth} from 'aws-amplify';
import { Loading } from '../Common';

class Simulator extends Component {

    constructor(props) {
        super(props);

        this.renderFamilies = this.renderFamilies.bind(this);
        this.sendInterview = this.sendInterview.bind(this);
        this.renderPets = this.renderPets.bind(this);
        this.renderBathrooms = this.renderBathrooms.bind(this);

        this.onAgeEditChange = this.onAgeEditChange.bind(this);
        this.onPetChange = this.onPetChange.bind(this);
        this.onBathroomsChange = this.onBathroomsChange.bind(this);
        this.onCancelAdd = this.onCancelAdd.bind(this);
        this.onConfirmAdd = this.onConfirmAdd.bind(this);
        this.onAddFamilyClick = this.onAddFamilyClick.bind(this);

        this.state = {
            family: [{gender: "Male", age: 26, staysHome: false}],
            bathrooms: 1,
            totals: undefined,
            tiers: [
                "EcoFriendly",
                "NationallyRecognizedBrandLead",
                "SupplyDropPick",
                "Value",
                "SensitiveSkin",
                "SocialGood",
                "AllNatural"
            ],
            scents: [
                'Unscented',
                'Spring',
                'Citrus',
                'Lavender',
                'Earthy',
                'Other'
            ],
            dishwashingProducts: [
                "Sponge",
                "Brush",
                "Packs",
                "Liquid",
                "Gel",
                "DryingTabs"
            ],
            laundryPreferences: [
                "Packs",
                "Liquid",
                "FreeAndClear",
                "HighEfficiency",
                "FabricSoftener",
                "Other"
            ],
            tierPreferences: {
                "EcoFriendly": true
            },
            scentPreferences: {
                "Unscented": true
            },
            trashPreferences: [
                {
                    type: "Kitchen",
                    bags: 1,
                    frequency: 1
                }
            ],
            guests: 0,
            dishwashing: {
                frequency: 0,
                products: {
                    "Sponge": true
                }
            },
            laundry:{
                frequency: 1,
                preferences: {
                    "Packs": true
                }
            },
            housekeeping: 1,
            pets:{
                Dog:0,
                Cat:0
            },
            displayAddFamilyDialog: false,
            interviews: [],
            allOptions: [],
        };
    }

    componentDidUpdate() {
        if(this.props.rounder && !this.state.rounder) {
            this.setState({rounder: this.props.rounder, sending: false })
            this.setOptions(this.props.rounder);
            this.props.clearRounder();
        }        
    }

    componentDidMount() {
        if(!this.state.allOptions.length && !this.state.interviews.length) {
            const allOptions = JSON.parse(sessionStorage.getItem('allOptions'));
            const interviews = JSON.parse(sessionStorage.getItem('interviews'));
            if(allOptions) this.setState({allOptions, typeGroups: allOptions[allOptions.length - 1]});
            if(interviews) this.setState({interviews, interview: interviews[interviews.length - 1]});
        }
        Auth.currentAuthenticatedUser().then(data => {
            const jwt = data.signInUserSession.idToken.jwtToken;
            const authed = isAdmin(jwt);
            if(!authed) {
                this.props.history.push('/');
            }
        }).catch(err => {
            this.props.history.push('/');
        })
    }


    sendInterview() {
        const {
            bathrooms,
            family,
            pets,
            tierPreferences,
            scentPreferences,
            trashPreferences,
            interviews,
            sending
        } = this.state;
        if(!sending) {
            const errors = [];
            if(!bathrooms) errors.push('bathrooms');
            if(!family.length) errors.push('family');
            if(!Object.entries(tierPreferences).length) errors.push('tierPreferences');
            if(errors.length) {
                this.setState({error: `Please provide values for the following question: ${errors}`});
            } else {
                this.setState({error: ''});
                const cleanFamily = [...family] 
                cleanFamily.forEach(f => {
                    delete f.id;
                })

                const tiers = [];
                Object.entries(tierPreferences).forEach(t => {
                    if(t[1]) tiers.push(t[0]);
                });

                const scents = [];
                Object.entries(scentPreferences).forEach(s => {
                    if(s[1]) scents.push(s[0]);
                });

                if(!trashPreferences.length) errors.push('trash preferences');
                if(!tiers.length) errors.push('tier preference');
                if(!errors.length) {
                    const interview = {
                        bathrooms,
                        family: cleanFamily,
                        pets: {...pets},
                        tierPreferences: tiers,
                        trashPreferences
                    }
        
                    this.props.getRounder(interview);
                    interview.timestamp = moment().format('H:mm ZZ, MMMM Do, YYYY');
                    interviews.push(interview);
                    sessionStorage.setItem('interviews', JSON.stringify(interviews));
                    this.setState({sending: true, rounder: undefined, options: undefined, interview, interviews});
                } else {
                    this.setState({error: `Please ensure at least one option is selected for {errors}`});
                }
            }
        }
    }

    onTrashChange(value) {
        const {trashPreferences} = this.state;
        trashPreferences[0].bags = parseInt(value);
        this.setState({ trashPreferences });
    }

    onAgeEditChange(member, newAge) {
        const { family } = this.state;

        if(parseInt(newAge) > 125) newAge = 125;

        
        family.forEach(item => {
            if (item.id === member.id) {
                item.age = parseInt(newAge);
            }
        });
        this.setState({ family });
    }

    onPetChange(petType, number) {
        const { pets } = this.state;

        pets[petType] = parseInt(number);

        this.setState({ pets });
    }

    onBathroomsChange(number) {
        this.setState({ bathrooms: parseFloat(number) });
    }

    toggleTier(tier) {
        const {tierPreferences} = this.state;
        if(tierPreferences[tier]) {
            delete tierPreferences[tier];
        } else {
            tierPreferences[tier] = true;
        }
        this.setState({tierPreferences});
    }

    toggleScent(scent) {
        const {scentPreferences} = this.state;
        if(scentPreferences[scent]) {
            delete scentPreferences[scent];
        } else {
            scentPreferences[scent] = true;
        }
        this.setState({scentPreferences});
    }

    toggleDishwashingProduct(product) {
        const {dishwashing} = this.state;
        if(dishwashing.products[product]) {
            delete dishwashing.products[product];
        } else {
            dishwashing.products[product] = true;
        }
        this.setState({dishwashing});
    }

    updateDishFrequency(value) {
        const {dishwashing} = this.state;
        dishwashing.frequency = value;
        this.setState({dishwashing});
    }

    updateLaundryFrequency(value) {
        const {laundry} = this.state;
        laundry.frequency = value;
        this.setState({laundry});
    }

    toggleLaundryPreference(preference) {
        const {laundry} = this.state;
        if(laundry.preferences[preference]) {
            delete laundry.preferences[preference];
        } else {
            laundry.preferences[preference] = true;
        }
        this.setState({laundry});
    }

    onDeleteFamily(item) {
        const { family } = this.state;
        let indexToRemove = family.map(e => e.id).indexOf(item.id);

        family.splice(indexToRemove, 1);

        this.setState({ family });
    }

    onAddFamilyClick(e) {
        e.preventDefault();

        this.setState({ displayAddFamilyDialog: true });
    }

    setOptions(rounder) {   
        let options = [];

        rounder.forEach(option => {
            let group = options.filter(o => o.id === option.groupName)[0];
            if(group) {
                options = options.filter(o => o.id !== option.groupName);
                group.rawArticleCounts.push({
                    productId: option.productId,
                    articlesPerPackage: option.articlesPerPackage,
                });
                group.articleCounts = this.props.optimize(group.rawArticleCounts).payload;
                group.rawArticleCounts.sort((a,b) => {
                    return a.articlesPerPackage - b.articlesPerPackage;
                });
                group.manifest = group.articleCounts[JSON.stringify(group.articles)];
                options.push(group);
            } else {
                const group = {
                    id: option.groupName,
                    name: option.name,
                    type: option.type,
                    brand: option.brand,
                    manifest: {[option.productId]: option.roundedPackages},
                    articles: option.roundedPackages * option.articlesPerPackage, 
                    proposedArticles: option.proposedArticles,
                    predictedArticles: option.predictedArticles,
                    preferred: option.preferred,
                    productImage: images[option.groupName],
                    price: (option.pricePerUnit / 100),
                    rawArticleCounts: [{
                        productId: option.productId,
                        articlesPerPackage: option.articlesPerPackage,
                    }],
                    articleCounts: []
                }
    
                options.push(group);
            }
        })

        const canRemove = options.filter(o => o.type.toLowerCase() !== "trashbagskitchen" && o.type.toLowerCase() !== "toiletpaper" && o.type.toLowerCase() !== "papertowels");
        const cantRemove = options.filter(o => o.type.toLowerCase() === "trashbagskitchen" || o.type.toLowerCase() === "toiletpaper" || o.type.toLowerCase() === "papertowels");
        const newOptions = []

        cantRemove.sort((a, b) => (a.type > b.type) ? 1 : -1);
        
        newOptions.push(...cantRemove, ...canRemove);

        this.setState({options: newOptions});
        this.setTypeGroups(newOptions);
    }

    renderFamilies() {
        const { family } = this.state;

        let familyRows = family.map(item => {
            return (
                <div key={item.id} style={{ display: 'flex', flexDirection: 'row', width: 200, marginTop: 5 }}>
                    <div><b>{item.gender},</b><br/><b>Stays Home:</b> {item.staysHome ? "Y" : "N"}</div>
                    <input
                        className="editable-cell"
                        type="number"
                        max="125"
                        min="0"
                        value={item.age}
                        onChange={(event) => this.onAgeEditChange(item, event.target.value)}
                    />
                    <div style={{ marginLeft: 15 }}>
                        <div onClick={e => {
                            e.preventDefault();
                            this.onDeleteFamily(item);
                        }}>
                            <img src={images.trashcan} alt="delete" className="row-icon" />
                        </div>
                    </div>
                </div>
            );
        });     

        return (
            <div>
                <h2>Interview</h2>
                <div className="subtitle-text">Family</div>
                <div className="body-row-wrapper">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {familyRows}
                        <div style={{ marginLeft: 85, marginTop: 10 }}>
                            <div onClick={this.onAddFamilyClick}>
                                Add Family member
                                <img src={images.addCircle} alt="add" className="add-icon"/>
                            </div>
                        </div>
                    </div>                        
                </div>
            </div>
        );
    }

    renderPets() {
        const { pets } = this.state;

        return (
            <div style={{ marginTop: 20 }}>
                <div className="subtitle-text">Pets</div>
                <div className="body-row-wrapper">
                    <div className="pets-row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 60, marginLeft: 15 }}>
                        <div >
                            <div>Dogs</div>
                            <input
                                className="editable-cell"
                                type="number"
                                value={pets.Dog}
                                onChange={(event) => this.onPetChange('Dog', event.target.value)}
                            />
                        </div>
                        <div>
                            <div>Cats</div>
                            <input
                                className="editable-cell"
                                type="number"
                                value={pets.Cat}
                                onChange={(event) => this.onPetChange('Cat', event.target.value)}
                            />
                        </div>
                    </div>                        
                </div>
            </div>
        );
    }

    renderBathrooms() {
        const { bathrooms } = this.state;

        return (
            <div style={{ marginTop: 20 }}>
                <div className="subtitle-text">Bathrooms</div>
                <div className="body-row-wrapper">
                    <div style={{display: 'flex', flexDirection: 'row', width: 220, justifyContent: 'space-between',  marginLeft: 15}}>
                        <div>Number of bathrooms</div>
                        <input
                            className="editable-cell"
                            type="number"
                            step="0.5"
                            min="0"
                            value={bathrooms}
                            onChange={(event) => this.onBathroomsChange(event.target.value)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderTierPreference() {
        const {tiers} = this.state;
        const rows = tiers.map(tier => {
            return (
                <div className="checkboxgroup brand-choice">
                    <label>{tier === "NationallyRecognizedBrandLead" ? "NRB Lead" : tier}</label>
                    <input
                        type="checkbox"
                        checked={this.state.tierPreferences[tier]}
                        onClick={() => this.toggleTier(tier)}
                    />
                </div>
            )
        });
        return (
            <div style={{ marginTop: 20 }}>
                <div className="subtitle-text">Tier Preference</div>
                {rows}
            </div>
        )
    }

    renderRefills() {
        const {refills} = this.state;
        return (
            <div style={{ marginTop: 20 }}>
                <div className="subtitle-text">Refills</div>
                <label>
                {!refills ? <del>Prefers Refills</del> : 'Prefers Refills'}
                </label>
                <input
                    type="checkbox"
                    checked={refills}
                    onClick={() => this.setState({ refills: !refills})}
                />
            </div>
        )
    }

    renderScent() {
        const {scents} = this.state;
        const rows = scents.map(scent => {
            return (
                <div className="checkboxgroup brand-choice">
                    <label>{scent}</label>
                    <input
                        type="checkbox"
                        checked={this.state.scentPreferences[scent]}
                        onClick={() => this.toggleScent(scent)}
                    />
                </div>
            )
        });
        return (
            <div style={{ marginTop: 20 }}>
                <div className="subtitle-text">Scent Preference</div>
                {rows}
            </div>
        )
    }

    renderTrash() {
        const {trashPreferences} = this.state;
        return (
            <Col xs={12}>
                <Row className="subtitle-text">Kitchen Trash Bags</Row>
                <Row>
                    <div>Bag Count</div>
                    <input
                        className="editable-cell"
                        value={trashPreferences[0].bags}
                        type="number"
                        min="0"
                        onChange={(event) => this.onTrashChange(event.target.value)}
                    />
                </Row>                      
            </Col>
        );
    }

    renderGuests() {
        const {guests} = this.state;
        return (
            <div style={{ marginTop: 20 }}>
                <div className="subtitle-text">Guests</div>
                <div className="checkboxgroup brand-choice">
                    <input
                        type="number"
                        min="0"
                        max="100"
                        step="25"
                        value={guests}
                        onChange={(e) => this.setState({guests: parseInt(e.target.value)})}
                        className="editable-cell"
                    />
                </div>
            </div>
        )
    }

    renderDishwashing() {
        const {dishwashing, dishwashingProducts} = this.state;
        const {frequency} = dishwashing;
        const rows = dishwashingProducts.map(product => {
            return (
                <div className="checkboxgroup brand-choice">
                    <label>{product}</label>
                    <input
                        type="checkbox"
                        checked={dishwashing.products[product]}
                        onClick={() => this.toggleDishwashingProduct(product)}
                    />
                </div>
            )
        })

        return (
            <div style={{ marginTop: 20 }}>
                <div className="subtitle-text">Dishwashing</div>
                <div className="checkboxgroup brand-choice">
                <label>Frequency (weekly)</label>
                    <input
                        type="number"
                        min="0"
                        value={frequency}
                        onChange={(e) => this.updateDishFrequency(parseInt(e.target.value))}
                        className="editable-cell"
                    />
                </div>
                <div className="checkboxgroup brand-choice">
                    {rows}
                </div>
            </div>
        )
    }

    renderLaundry() {
        const {laundry, laundryPreferences} = this.state;
        const {frequency, preferences} = laundry;
        const rows = laundryPreferences.map(p => {
            return (
                <div className="checkboxgroup brand-choice">
                    <label>{p}</label>
                    <input
                        type="checkbox"
                        checked={preferences[p]}
                        onClick={() => this.toggleLaundryPreference(p)}
                    />
                </div>
            )
        })

        return (
            <div style={{ marginTop: 20 }}>
                <div className="subtitle-text">Laundry</div>
                <div className="checkboxgroup brand-choice">
                <label>Frequency (weekly)</label>
                    <input
                        type="number"
                        min="0"
                        value={frequency}
                        onChange={(e) => this.updateLaundryFrequency(parseInt(e.target.value))}
                        className="editable-cell"
                    />
                </div>
                <div className="checkboxgroup brand-choice">
                    {rows}
                </div>
            </div>
        )
    }

    renderHousekeeping() {
        const {housekeeping} = this.state;
        return (
            <div style={{ marginTop: 20 }}>
                <div className="subtitle-text">Housekeeping</div>
                <div className="checkboxgroup brand-choice">
                    <input
                        type="number"
                        min="0"
                        value={housekeeping}
                        onChange={(e) => this.setState({housekeeping: parseInt(e.target.value)})}
                        className="editable-cell"
                    />
                </div>
            </div>
        )
    }

    renderSendBtn() {
        const {rounder, sending} = this.state;
        if(!sending) {
            return (
                <button className="btn btn-primary update-button" onClick={this.sendInterview}>{!rounder ? 'Send': 'Update'}</button>
            )
        } else {
            return <Loading />
        }
    }

    renderExportBtn() {
        const {allOptions, interviews} = this.state;
        if(allOptions.length > 0 && interviews.length > 0 && interviews.length === allOptions.length) {
            const csv = this.convertToCSV();

            return (
                <a className="btn btn-primary update-button" href={`data:text/csv;charset=utf-8, ${encodeURIComponent(csv)}`} download="interview.csv">Export to CSV</a>
            )
        }
    }

    convertToCSV() {
        const {interviews, allOptions} = this.state;
        let headers = ["","Timestamp","Interview","","","","","","","","","","","","","","","","","","","","","","Products","\n"];
        let subheaders = ["","Male","","","","","","","Female","","","","","","","Total Family","Total Stays Home","Dog","Cat","Bathrooms","Tier Preference","Trash",""];
        let tertiaryHeaders = ["","0 to 3","4 to 11","12 to 17","18 to 24","25 to 44","45 to 64","65+","0 to 3","4 to 11","12 to 17","18 to 24","25 to 44","45 to 64","65+","","","","","","","Type","Bags"];
        const rows = [];
        const encodedRows = [];
        const totals = [];
        let rowTemplate = ["", // 0- Timestamp
            0, //1- Male 0-3
            0, //2- Male 4-11
            0, //3- Male 12-17
            0, //4- Male 18-24
            0, //5- Male 25-44
            0, //6- Male 45-64
            0, //7- Male 65+
            0, //8- Female 0-3
            0, //9- Female 4-11
            0, //10- Female 12-17
            0, //11- Female 18-24
            0, //12- Female 25-44
            0, //13- Female 45-64
            0, //14- Female 65+
            0, //15- Family Total
            0, //16- Total that Stay Home
            0, //17 Dog
            0, //18- Cat
            0, //19- Bathrooms
            0, //20- Brand/Tier Preferences
            0, //21- Trash Types
            0, //22- Trash Bags
        ];

        interviews.forEach(interview => {
            let row = [...rowTemplate];
            row[0] = '"' + interview.timestamp + '"';
            let staysHome = 0;
            interview.family.forEach(member => {
                if(member.staysHome) staysHome++;
                const age = member.age;
                const gender = member.gender;
                if(age < 4) {
                    gender === "Female" ? row[8] = parseInt(row[8])+1 : row[1] = parseInt(row[1])+1;
                } else if(age < 12) {
                    gender === "Female" ? row[9] = parseInt(row[9])+1 : row[2] = parseInt(row[2])+1;
                } else if(age < 18) {
                    gender === "Female" ? row[10] = parseInt(row[10])+1 : row[3] = parseInt(row[3])+1;
                } else if(age < 25) {
                    gender === "Female" ? row[11] = parseInt(row[11])+1 : row[4] = parseInt(row[4])+1;
                } else if(age < 45) {
                    gender === "Female" ? row[12] = parseInt(row[12])+1 : row[5] = parseInt(row[5])+1;
                } else if(age < 65) {
                    gender === "Female" ? row[13] = parseInt(row[13])+1 : row[6] = parseInt(row[6])+1;
                } else {
                    gender === "Female" ? row[14] = parseInt(row[14])+1 : row[7] = parseInt(row[7])+1;
                }
            });

            const trashType = [];
            const trashBags = [];
            const trashFrequency = [];
            interview.trashPreferences.forEach(t => {
                trashType.push(t.type);
                trashBags.push(t.bags);
                trashFrequency.push(t.frequency);
            });

            trashType.map(it => `${it}`).join(',');
            trashBags.map(it => `${it}`).join(',');
            trashFrequency.map(it => `${it}`).join(',');

            row[15] = interview.family.length;
            row[16] = staysHome;
            row[17] = interview.pets.Dog;
            row[18] = interview.pets.Cat;
            row[19] = interview.bathrooms;
            row[20] = '"' + interview.tierPreferences + '"';
            row[21] = '"' + trashType + '"'; // Sort out types and frequency
            row[22] = '"' + trashBags + '"'; // Sort out types and frequency

            rows.push(row);
            const text = JSON.stringify(interview);
            const bytes = utf8.encode(text);
            const encoded = base64.encode(bytes);
            encodedRows.push(['"' + encoded + '\n"']);
        })

        const types = [];
        for (let i = 0; i < allOptions.length; i++){
            const options = allOptions[i];
            let total = 0;
            for(let k = 0; k < options.length; k++ ) {
                const option = options[k].selectedProduct;
                if(types.includes(option.type)) {
                    const checkType = (type) => {return type === option.type};
                    const index = types.findIndex(checkType);
                    
                    let diff = rows[i].length - (index + 1) * 5 + 19
                    if(diff < 0) {
                        for(let j = 0; j < diff; j++) {
                            rows[i][rows[i].length + 1] = "";
                        }
                    }
                    const manifest = this.renderManifestStr(option.manifest);
                    const product = `"${option.brand} ${option.name}"`;
                    
                    rows[i][(index + 1) * 5 + 18] = option.proposedArticles;
                    rows[i][(index + 1) * 5 + 19] = option.articles;
                    rows[i][(index + 1) * 5 + 20] = '"' + manifest + '"';
                    rows[i][(index + 1) * 5 + 21] = product;
                    rows[i][(index + 1) * 5 + 22] = '"$' + (option.price * option.articles).toFixed(2) + '"';
                    total += option.price * option.articles;
                } else {
                    // Prevent added cells from commas in product name
                    const header = `"${option.type}"`;
                    subheaders.push(header, "", "", "", "");
                    tertiaryHeaders.push("Proposed", "Rounded", "Manifest", "Product Group", "Price")
                    types.push(option.type);
                    rows[i].push(option.proposedArticles);
                    rows[i].push(option.articles);
                    const manifest = this.renderManifestStr(option.manifest);
                    rows[i].push('"' + manifest + '"');           
                    
                    const product = `"${option.brand} ${option.name}"`;
                    rows[i].push(product);

                    rows[i].push(`"$${(option.price * option.articles).toFixed(2)}"`);
                    total += option.price * option.articles;
                }
                if(k === options.length - 1) {
                    totals.push(`$${total.toFixed(2)}`);
                }
            }
        }

        // Spacing
        subheaders.push("Total", "\n");
        tertiaryHeaders.push("\n");

        // Convert to csv strings
        tertiaryHeaders.map(it => `${it}`).join(',');
        for(let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if(row.length + 2 < subheaders.length) {
                const diff = subheaders.length - row.length - 2;
                for(let j = 0; j < diff; j++) {
                    row.push("");
                }
            }
            row.push(totals[i], "\n");
            row.map(it => `${it}`).join(',');
        }
        rows.map(it => `${it}`).join(',');

        headers = headers.map(it => `${it}`).join(',');
        subheaders = subheaders.map(it => `${it}`).join(',');

        encodedRows.forEach(row => {
            row.push("\n");
        });
        encodedRows.map(it => `${it}`).join(',');

        let data = [
            headers,
            subheaders,
            tertiaryHeaders,
            rows,
            encodedRows
        ]

        return data;
    }

    renderError() {
        const {error} = this.state;

        if(error) {
            return (
                <Alert variant="danger">{error}</Alert>
            )
        }
    }

    renderManifestStr(manifest) {
        let allProducts = '';       
        Object.entries(manifest).forEach(entry => {
            allProducts += allProducts ? `, ${entry[0]}: ${entry[1]}` : `${entry[0]}: ${entry[1]}`;
        });
        return allProducts;
    }

    setTypeGroups(options) {
        const typeGroups = [];
        options.forEach(product => {
            const typeGroup = typeGroups.filter(t => t.type === product.type);
            if(typeGroup.length) {
                if(product.preferred) {
                    typeGroup[0].selectedProduct = product
                }
                typeGroup[0].products.push(product)
            } else {
                typeGroups.push({type: product.type, selectedProduct: product.preferred ? product : undefined, products: [product]})
            }
        });

        const newGroups = typeGroups.filter(t => t.selectedProduct);
        const allOptions = JSON.parse(sessionStorage.getItem('allOptions')) ? JSON.parse(sessionStorage.getItem('allOptions')) : [];
        allOptions.push(newGroups)
        sessionStorage.setItem('allOptions', JSON.stringify(allOptions));
        this.setState({typeGroups: newGroups, allOptions});
    }

    renderTotal() {
        const {typeGroups} = this.state;
        if (typeGroups) {
            let total = 0;
            const body = [];
            typeGroups.forEach(group => {
                if(group.selectedProduct) {
                    const option = group.selectedProduct;
                    const manifest = this.renderManifestStr(option.manifest);
                    total += (option.price * option.articles);
                    body.push(
                        <tr>
                            <th scope="col" className="text-left">{option.brand} {option.name} {option.type}</th>
                            <td className="text-left"><b>Proposed:</b> {option.proposedArticles}<br/><b>Rounded:</b> {option.articles}<br/><b>Manifest:</b> {manifest}<br/><br/><b>Price:</b> ${(option.articles * option.price).toFixed(2)}</td>
                        </tr>
                    );
                }
            });
            body.push(
                <tr className="total-row">
                    <th scope="col" className="text-left">Total:</th>
                    <th scope="col" className="text-left">${total.toFixed(2)}</th>
                </tr>
            )
            return (
                <div>
                    <h2>Results</h2> 
                    <table className="table table-striped">    
                        <thead>
                            <tr>
                                <td>Product Group</td>
                                <td>Quantities</td>
                            </tr>
                        </thead>                    
                        <tbody>
                                {body}
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    onCancelAdd() {
        this.setState({ displayAddFamilyDialog: false });
    }

    onConfirmAdd(item) {
        const { family } = this.state;
        family.push(item);

        this.setState({ family, displayAddFamilyDialog: false });
    }

    renderAddEditFactorDialog() {
        const { displayAddFamilyDialog } = this.state;

        if (displayAddFamilyDialog) {
            return (
                <AddSimulatorFamily
                    onCancelClick={this.onCancelAdd}
                    onSaveClick={this.onConfirmAdd}
                />
            );
        }
    }

    render() {
        return (
            <div>
                <Header />
                <Container className="simulator-body">
                    <Row className="flex-row flex-space-around">
                        <Col xs={6}>
                            <Row>{this.renderAddEditFactorDialog()}</Row>
                            <Row>{this.renderFamilies()}</Row>
                            <Row>{this.renderPets()}</Row>
                            <Row>{this.renderBathrooms()}</Row>
                            <Row>{this.renderTierPreference()}</Row>
                            <Row>{this.renderTrash()}</Row>
                            <Row>{this.renderSendBtn()}</Row>
                            <Row>
                                {this.renderExportBtn()}
                                {this.renderError()}
                            </Row>
                        </Col>
                        <Col xs={6} className="simulator-col">
                            {this.renderTotal()}
                        </Col>
                    </Row>
                </Container>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    const {rounder, optimized, error} = state.interviews;
    return { optimized, rounder, error };
}

export default connect(mapStateToProps,{
    getRounder,
    clearRounder,
    optimize
})(Simulator);
