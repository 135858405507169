import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    approveOrder,
    cancelOrder,
    clearOrderState,
    clearApprovalState,
    getOrder,
    getProductGroups,
    isAdmin,
} from '../../actions';
import Header from '../Header';
import './ordercalculation.css';
import { Auth } from 'aws-amplify';
import Details from '../Customers/Details';
import { Loading } from '../Common';
import { Link } from 'react-router-dom'
import { getProductsFromEntries } from '../../helpers/productHelpers';
import { getFullOrder } from '../../helpers/orderHelpers';

class OrderReview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetchingData: false,
            interviewFactors: undefined,
            order: undefined,
            approving: false,
            isApproved: false
        }

        this.approveOrder = this.approveOrder.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
        this.setLocationState = this.setLocationState.bind(this);
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(data => {
            const jwt = data.signInUserSession.idToken.jwtToken;
            const authed = isAdmin(jwt);
            if (!authed) {
                this.props.history.push('/');
            }
        }).catch(err => {
            this.props.history.push('/');
        })
        const { match: { params } } = this.props;
        const { customerId, orderId } = params
        this.setState({ orderId, customerId })
        if (orderId) {
            this.props.clearOrderState();
            this.setState({ orderId, customerId });
            this.props.getOrder(customerId, orderId);
        }
        this.props.getProductGroups();
        this.setLocationState(this.props.location);
    }

    componentDidUpdate() {
        const { products, productGroups, productGroupsError, order, getOrderError, isApproved } = this.props;
        const { status } = this.state;

        if (order && !this.state.order) {
            try {
                this.setState({ order })
            } catch (error) {
                if (typeof (error) === "string")
                    this.setState({ error })
                else if (error.message)
                    this.setState({ error: error.message })
                else {
                    this.setState({ error: "An error has occured" })
                    console.error(error)
                }
            }
        }
        if (products && !this.state.products) {
            this.setState({ products });
        }
        if (getOrderError && !this.state.error) {
            if (typeof (getOrderError) === "string")
                this.setState({ error: getOrderError })
            else if (getOrderError.message)
                this.setState({ error: getOrderError.message })
            else {
                this.setState({ error: "An error has occured" })
                console.error(getOrderError)
            }
        }
        if (isApproved && status !== "Pending Shipping") {
            this.setState({ status: "Pending Shipping" })
        }

        if(productGroups && !this.state.productGroups) {
            this.setState({ productGroups })
        }
        if(productGroupsError && !this.state.productGroupsError) {
            this.setState({ productGroupsError })
        }
    }

    setLocationState(location) {
        try {
            const { netSuite, status } = location.state;
            this.setState({ netSuite, status })
        } catch (err) {
            console.error('No sales order')
        }
    }

    approveOrder() {
        const { approving, orderId, customerId } = this.state;
        if (!approving && orderId) {
            this.props.approveOrder(customerId, orderId);
            this.setState({ approving: true })
        }
    }

    cancelOrder() {
        const { orderId, customerId } = this.state;
        const { cancelingOrder } = this.props;
        if (!cancelingOrder && orderId) {
            this.props.cancelOrder(customerId, orderId);
        }
    }

    renderInterviewRows() {
        const { interview } = this.state;
        const rows = [];
        if (interview) {
            Object.entries(interview).forEach(item => {
                rows.push(
                    <tr style={{ height: 50 }}>
                        <th>{JSON.stringify(item[0])}</th>
                        <td>{JSON.stringify(item[1])}</td>
                    </tr>
                );
            });

            return rows;
        }
    }

    renderButtonRow() {
        const { order, customerId, netSuite, status } = this.state;
        if (order && customerId) {
            const { orderId, expectedShipDate, expectedDeliveryDate, createdAt } = order;
            return (
                <div className="factor-calc-row">
                    <div>
                        <span className="section-subtitle-text-main">Order: {orderId}</span><br />
                        <b>Status:</b> {status}<br />
                        <b>Expected Ship Date:</b> {moment.utc(expectedShipDate).format('ll')}<br />
                        <b>Expected Delivery Date:</b> {moment.utc(expectedDeliveryDate).format('ll')}<br />
                        <b>Created:</b> {moment.utc(createdAt).local().format('lll')}<br />
                    </div>
                    <div>
                        <span className="btn btn-danger" onClick={() => this.setState({ canceling: true })}>Cancel Order</span>
                        {status === "Pending Approval" ? <span className="btn btn-primary" onClick={this.approveOrder}>Approve Order</span> : null}
                        <Link className="btn btn-primary" to={{ pathname: `/customers/${customerId}/orders/${orderId}/packing-slip`, state: { createdAt, netSuite } }}>Print Packing Slip</Link>
                    </div>
                </div>
            )
        }
    }

    renderCancelingModal() {
        const { canceling } = this.state;
        const { isCanceled, cancelingOrder, cancelingError } = this.props;
        if (canceling) {
            if (cancelingError) {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-small flex-column text-center">
                            <div className="dialog-title-text">
                                Oops there's an error
                            </div>
                            <div className="dialog-body-text error-color">
                                {cancelingError}
                            </div>
                            <div className="flex-self-center">
                                <button className="btn-primary dialog-btn-ok" onClick={() => this.setState({ canceling: false })}>OK</button>
                            </div>
                        </div>
                    </div>
                );
            } else if (isCanceled) {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-small flex-column text-center">
                            <div className="dialog-title-text success-color">
                                Success !
                            </div>
                            <div className="dialog-body-text">
                                Order canceled!
                            </div>
                            <div className="flex-self-center">
                                <button
                                    className="btn-primary dialog-btn-ok"
                                    onClick={() => this.props.history.push('/orders')}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                );
            } else if (cancelingOrder) {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-small flex-column text-center">
                            <div className="dialog-title-text">
                                Canceling
                            </div>
                            <div className="dialog-body-text">
                                <div className="content body-results flex-self-center">
                                    <span><div class="spinner-grow spinner-border-sm" role="status" /></span>
                                    <span><div class="spinner-grow spinner-border-sm" role="status" /></span>
                                    <span><div class="spinner-grow spinner-border-sm" role="status" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-small flex-column text-center">
                            <div className="dialog-title-text">
                                Are you sure you want to cancel order?
                            </div>
                            <div className="flex-self-center">
                                <button
                                    className="btn btn-danger dialog-btn-ok"
                                    onClick={this.cancelOrder}
                                >
                                    Cancel Order
                                </button>
                                <button
                                    className="btn btn-secondary dialog-btn-ok"
                                    onClick={() => this.setState({ canceling: false })}
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    renderTable() {
        return (
            <div className="content flex-row body-results">
                <table className="order-table">
                    <thead className="order-table-head">
                        <tr>
                            <th>Brand</th>
                            <th>Type</th>
                            <th>Article Count</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    {this.renderTableRows()}
                </table>
            </div>
        )
    }

    renderTableRows() {
        const { productGroups } = this.state;
        const order = getFullOrder(this.state.order, productGroups);
        if (order && productGroups) {
            const { productGroupEntries, subtotalCents, discountCents, taxCents, refundCents, totalCents } = order;
            const products = getProductsFromEntries(productGroupEntries, productGroups)
            const rows = products.map(product => {
                const { title, articles, productType, pricePerArticleCents } = product;
                return (
                    <tr className="order-table-row">
                        <th>{title}</th>
                        <td>{productType}</td>
                        <td>{articles}</td>
                        <td>${(articles * pricePerArticleCents / 100).toFixed(2)}</td>
                    </tr>
                )
            })
            rows.push(
                <tr className="order-table-row">
                    <th></th>
                    <td></td>
                    <th>Subtotal:</th>
                    <td>${(subtotalCents/100).toFixed(2)}</td>
                </tr>
              )
                
            if (discountCents > 0) {
              rows.push(
                <tr className="order-table-row">
                  <th></th>
                  <td></td>
                  <th>Discount:</th>
                  <td>-${(discountCents/100).toFixed(2)}</td>
                </tr>
              )
            }
            
            rows.push(
              <tr className="order-table-row">
                <th></th>
                <td></td>
                <th>Taxes:</th>
                <td>${(taxCents/100).toFixed(2)}</td>
              </tr>
            )
            if (refundCents > 0) {
              rows.push(
                <tr className="order-table-row">
                  <th></th>
                  <td></td>
                  <th>Refunds:</th>
                  <td>-${(discountCents/100).toFixed(2)}</td>
                </tr>
              )
            } 
            
            rows.push(
              <tr className="order-table-row">
                  <th></th>
                  <td></td>
                  <th>Total:</th>
                  <td>${(totalCents/100).toFixed(2)}</td>
              </tr>
            )
            return rows
        } else {
            return <Loading />
        }
    }

    renderApproveDialog() {
        const { approving } = this.state;
        const { isApproved, approvalError } = this.props;
        if (approving) {
            if (approvalError) {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-small flex-column text-center">
                            <div className="dialog-title-text">
                                Oops there's an error
                            </div>
                            <div className="dialog-body-text error-color">
                                {approvalError}
                            </div>
                            <div className="flex-self-center">
                                <button className="btn-primary dialog-btn-ok" onClick={() => this.setState({ approving: false })}>OK</button>
                            </div>
                        </div>
                    </div>
                );
            } else if (isApproved) {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-small flex-column text-center">
                            <div className="dialog-title-text success-color">
                                Success !
                            </div>
                            <div className="dialog-body-text">
                                Order updated!
                            </div>
                            <div className="flex-self-center">
                                <button
                                    className="btn-primary dialog-btn-ok"
                                    onClick={() => this.setState({ approving: false })}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-small flex-column text-center">
                            <div className="dialog-title-text">
                                Updating
                            </div>
                            <div className="dialog-body-text">
                                <div className="content body-results flex-self-center">
                                    <span><div class="spinner-grow spinner-border-sm" role="status" /></span>
                                    <span><div class="spinner-grow spinner-border-sm" role="status" /></span>
                                    <span><div class="spinner-grow spinner-border-sm" role="status" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    renderError() {
        const { error } = this.state;
        if (error) {
            return (
                <div className="modal-container">
                    <div className="alert-dialog-small flex-column text-center">
                        <div className="dialog-title-text">
                            Oops there's an error
                        </div>
                        <div className="dialog-body-text error-color">
                            {error}
                        </div>
                        <div className="flex-self-center">
                            <button className="btn-primary dialog-btn-ok" onClick={() => this.props.history.push('/orders')}>OK</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <Header />
                <div className="sdrop-body flex-column">
                    {this.renderApproveDialog()}
                    {this.renderCancelingModal()}
                    {this.renderButtonRow()}
                    {this.renderTable()}
                    {this.renderError()}
                    <Details history={this.props.history} />
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    const { order, getOrderError, isApproved, approvalError, approvingOrder, isCanceled, cancelingError, cancelingOrder } = state.orders;
    const { interview, error } = state.interviews;
    const { productGroups, productGroupsError } = state.products;
    return { order, getOrderError, interview, error, isApproved, approvalError, approvingOrder, isCanceled, cancelingError, cancelingOrder, productGroups, productGroupsError };
};

export default connect(mapStateToProps, {
    approveOrder,
    getProductGroups,
    getOrder,
    clearApprovalState,
    cancelOrder,
    clearOrderState
})(OrderReview);
