import React from 'react';
import images from '../assets/images';
import {Auth} from 'aws-amplify';
import {Navbar, Nav, NavDropdown, Button} from 'react-bootstrap';

const Header = () => {
    const signOut = () => {
        Auth.signOut().then(() => {
            window.location.href = '/';
        })
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="header-navbar" fixed="top">
            <Navbar.Brand><img className="header-logo" src={images.logo} alt="logo"/></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto bg-light">
                <NavDropdown title="Simulator" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/simulator">Manual</NavDropdown.Item>
                    <NavDropdown.Item href="/autosimulator">Auto-Simulator</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Orders" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/orders">Pending</NavDropdown.Item>                
                    <NavDropdown.Item href="/upcoming">Upcoming Monthaversaries</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/products">Products</Nav.Link>
                <Nav.Link href="/customers">Customers</Nav.Link>
                <Nav.Link href="/feedback">Feedback</Nav.Link>
                <Nav.Link href="/reports">Reports</Nav.Link>
                <NavDropdown title="Tools" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/qr-generator">QR Code Generator</NavDropdown.Item>
                </NavDropdown>
                </Nav>
                <Nav className="bg-light">
                    <Nav.Link href="/resources">Resources</Nav.Link>
                    <Nav.Link><Button className="btn-sm" variant="outline-primary" onClick={signOut}>Sign Out</Button></Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
