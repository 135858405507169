import axios from 'axios';
import {
    SEND_SMS_SUCCESS,
    SEND_SMS_FAIL,
    SENDING_SMS,
    CLEAR_SMS,
    UNAUTHORIZED
} from './types'
import { getAuthHeaders, handleApiError } from '../helpers/apiHelpers';
import keys from '../config';
const url = keys.apiUrl;

export const sendSMS = (customerId) => {
    return (dispatch) => {
        dispatch({ type: SENDING_SMS })
        getAuthHeaders().then(headers => {
            axios.post(`${url}/admin/manifests/${customerId}/contact`, {}, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: SEND_SMS_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, SEND_SMS_FAIL))
                })
                .catch(err => {
                    if (err === 'UNAUTHORIZED')
                        dispatch(handleApiError(err, UNAUTHORIZED))
                    else
                        dispatch(handleApiError(err, SEND_SMS_FAIL))
                })
        }).catch(err => {
            dispatch(handleApiError(err, SEND_SMS_FAIL))
        })
    }
}

export const clearMessageState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_SMS })
    }
}
