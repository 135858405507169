import {
    GET_REPORT_SUCCESS,
    GET_REPORT_FAIL,
    GETTING_REPORT,
    GETTING_REPORTS,
    GET_REPORTS_SUCCESS,
    GET_REPORTS_FAIL,
    CLEAR_REPORT
} from '../actions/types';

const INITIAL_STATE = {
    report: undefined,
    getReportError: undefined,
    gettingReport: false,
    reports: undefined,
    getReportsError: undefined,
    gettingReports: false,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_REPORT_SUCCESS:
            return { ...state, report: action.payload, getReportError: undefined, gettingReport: false };
        case GET_REPORT_FAIL:
            return { ...state, report: undefined, getReportError: action.error, gettingReport: false };
        case GETTING_REPORT:
            return { ...state, report: undefined, getReportError: undefined, gettingReport: true };
        case CLEAR_REPORT:
            return { ...state, report: undefined, getReportError: undefined, gettingReport: false };
        case GETTING_REPORTS:
            return { ...state, reports: undefined, getReportsError: undefined, gettingReports: true };
        case GET_REPORTS_SUCCESS:
            return { ...state, reports: action.payload, getReportsError: undefined, gettingReports: false };
        case GET_REPORTS_FAIL:
            return { ...state, reports: undefined, getReportsError: action.error, gettingReports: false };
        default:
            return state;
    }
}