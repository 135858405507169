import React, {Component} from 'react';
import Header from '../Header';

class Resources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: [
                // make types 'docs', 'analytics'
                {
                    title: 'Admin Docs',
                    description: 'Google doc on the Admin console',
                    link: 'https://docs.google.com/document/d/1uJG8XIfIWNHZqH3HTqfQNJ6B9c64-VvknC3h5NYZlPA/edit',
                    type: 'GUIDE'
                },
                {
                    title: "GA: Overview",
                    description: "Overview of sessions, events, etc.",
                    link:"https://analytics.google.com/analytics/web/#/report/content-event-overview/a148893093w211782253p203299824/",
                    type: "ANALYTICS"
                },
                {
                    title: "GA: Quantities Adjusted",
                    description: "Product Adjustment Events",
                    link:"https://analytics.google.com/analytics/web/#/report/content-event-events/a148893093w211782253p203299824/explorer-segmentExplorer.segmentId=analytics.eventCategory&_r.drilldown=analytics.eventCategory:ChangeProductQuantity/",
                    type: "ANALYTICS"
                },
                {
                    title: "Product Category Details",
                    description: "",
                    link:"https://docs.google.com/spreadsheets/d/1OhYyR_-c1qayukA7u6a-eZwL_ZxYMjacLYlon_Wi6Xo/edit#gid=0",
                    type: "PRODUCTS"
                },
                {
                    title: "Satyam's Metric List",
                    description: "",
                    link:"https://docs.google.com/spreadsheets/d/1v0vkqO6LnxooNCyR3ChJyMG_LCkmspbLjXYrIdZluro/edit#gid=0",
                    type: "ANALYTICS"
                },
                {
                    title: "#customer-service",
                    description: "Doc outlining slack interaction protocol and other customer service matters",
                    link:"https://docs.google.com/document/d/11sDEVpROwn8XxlxAfC9TtCbJ9mOZU1pwDewUo83mBhc/edit#heading=h.z28sfzocklwh",
                    type: "GUIDE"
                },
                {
                    title: "SD Analytics Guide",
                    description: "",
                    link: "https://docs.google.com/document/d/1RYWilp4TPX6SHbDHA6J6MSKfjt9uEF64mlnJr4mfWQU/edit",
                    type: "GUIDE"
                },
                {
                    title: "Figma Interview Designs",
                    description: "Visual designs for interview app components",
                    link: "https://www.figma.com/file/gfqLg0nCJaib1uyXLxcEqk/Supply-Drop-2019?node-id=356%3A0",
                    type: "DESIGN"
                },
                {
                    title: "Product Images",
                    description: "Drive folder with product images",
                    link: "https://drive.google.com/drive/u/0/folders/0AKDVbxklyPGZUk9PVA",
                    type: "DESIGN"
                },
                {
                    title: "Other Design Resources",
                    description: "Hunter & Bard designs, and creative components/designs",
                    link: "https://drive.google.com/drive/u/0/folders/177A0UeBAkO8UVhnd-H-eVetP8JNMCAgX",
                    type: "DESIGN"
                },
                {
                    title: "Interview Questions",
                    description: "Stages of Interview Questions",
                    link: "https://docs.google.com/spreadsheets/d/1ej45skps3aYKH9KiILtr_cthEP3tyMWOhpQkWU1tyxU/edit#gid=1482305651",
                    type: "CONTENT"
                },
                {
                    title: "Pinpoint Overview",
                    description: "",
                    link: "https://console.aws.amazon.com/pinpoint/home?region=us-east-1#/apps/bc9e7216c9a14f9b93df6953cca2760d",
                    type: "ANALYTICS"
                },
                {
                    title: "Pinpoint Events",
                    description: "",
                    link: "https://console.aws.amazon.com/pinpoint/home?region=us-east-1#/apps/bc9e7216c9a14f9b93df6953cca2760d/analytics/events",
                    type: "ANALYTICS"
                }
            ]
        };
    }

    renderResources() {
        const {resources} = this.state;
        const rows = [];

        resources.sort((a,b) => {
            if(a.type < b.type) { return -1; }
            if(a.type > b.type) { return 1; }
            return 0;
        })

        resources.forEach(resource => {
            const {
                link,
                description,
                title,
                type
            } = resource;

            rows.push(
                <tr>
                    <th scope="flex-row">
                        <a href={link} target="_blank" rel="noopener noreferrer">{title}</a>
                    </th>
                    <td>{type}</td>
                    <td>{description}</td>
                </tr>
            )
        });
        return rows;
    }

    render() {
        return (
            <div>
                <Header />
                <div className="sdrop-body short flex-column">
                    <div className="table-wrapper products-table">
                        <h2>External Resources</h2>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">External Resources</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Details</th>         
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderResources() }
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginBottom: 100 }} />
                </div>
            </div>
        )
    }
}

export default Resources;