import React, {Component} from 'react';
import Header from '../Header';
import {getFeedback, isAdmin, getCustomers} from '../../actions';
import {connect} from 'react-redux';
import {Auth} from 'aws-amplify';
import moment from 'moment';

class Feedback extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.sortByQuestion = this.sortByQuestion.bind(this);
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(data => {
            const jwt = data.signInUserSession.idToken.jwtToken;
            const authed = isAdmin(jwt);
            if(!authed) {
                this.props.history.push('/');
            } else {
                this.props.getFeedback();
                this.props.getCustomers();
            }
        }).catch(err => {
            this.props.history.push('/');
        })
    }

    componentDidUpdate() {
        const {feedback, error, customers} = this.props;
        if(feedback && !this.state.feedback) {
            this.setState({feedback: feedback.feedbacks});
        } 
        if(!feedback && error && !this.state.error) {
            console.error(error);
            this.setState({error});
        }
        if(customers && !this.state.customers && !this.state.customersErr) {
            try {
                const customersMap = {};
                customers.forEach(customer => {
                    customersMap[customer.customerId] = customer;
                })
                this.setState({customers, customersMap})
            } catch(err) {
                this.setState({customers, customersErr: err})
            }
        }
    }

    renderFeedbackRows() {
        const {feedback, customersMap} = this.state;

        if(feedback && customersMap) {
            const rows = [];

            feedback.forEach(f => {
                const idArr = f.id.split('|');
                let customerId;
                let customer;
                if(idArr[0] === "identity") {
                    customerId = idArr[1].split('us-east-1:')[1];
                }
                if(customerId && customersMap[customerId]) {
                    customer = customersMap[customerId];
                }
                let date;
                const questionStrArr = f.question.split(" ");
                const question = questionStrArr[0];
                questionStrArr.shift();
                date = questionStrArr.join(" ");
                date = moment.utc(date).format('lll')
                rows.push(
                    <tr>
                        <td>{question}</td>
                        <td>{date}</td>
                        <td>{f.feedback}</td>
                        <th scope="row">{customer ? `${customer.firstName} ${customer.lastName} ${customer.emai} ${customer.customerId}` : f.id}</th>
                    </tr>
                )
            })
            return rows;
        }
    }

    sortByQuestion() {
        let {feedback, sortByQuestion} = this.state;
        if(sortByQuestion) {
            feedback.sort((a,b) => {
                if(a.question < b.question) { return -1; }
                if(a.question > b.question) { return 1; }
                return 0;
            });
        } else {
            feedback.sort((a,b) => {
                if(a.question < b.question) { return 1; }
                if(a.question > b.question) { return -1; }
                return 0;
            })
        }
        this.setState({feedback, sortByQuestion: !sortByQuestion});
    }

    renderQuestionArrow() {
        const {sortByQuestion} = this.state;
        if(sortByQuestion === false) {
            return (<span>&uarr;</span>)
        } else if (sortByQuestion) {
            return (<span>&darr;</span>)
        } 
    }

    render() {
        return (
            <div>
                <Header />
                <div className="sdrop-body short flex-column">
                    <div className="table-wrapper">
                        <h2>Feedback</h2>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Question {this.renderQuestionArrow()}</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Feedback</th>
                                    <th scope="col">Cognito Identity Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderFeedbackRows() }
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginBottom: 100 }} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {feedback, error, customers} = state.customers;
    return {feedback, error, customers};
}

export default connect(mapStateToProps, {
    getFeedback,
    getCustomers
})(Feedback);