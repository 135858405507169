import * as Sentry from '@sentry/browser';
import { Auth } from 'aws-amplify';
import {isAdmin} from '../actions';
import { SIGNED_IN_AUTH_ERROR, NEW_USER_AUTH_ERROR } from '../actions/types';

export const handleApiError = (error, type) => {
        if (error.name === 'AuthError') {
            if (error.message === 'failed to create signature') {
                Sentry.captureEvent("apiHelper handling \"failed to create\" signature error")
                // if signedIn user
                // use redux to send to login page
                type = SIGNED_IN_AUTH_ERROR;
            } else {
                Sentry.captureEvent("apiHelper handling \"Signed in user encountered missing Auth\" error")
                // if !signedIn user
                // use redux to this will send to home page
                type = NEW_USER_AUTH_ERROR;
            }
        }
        if (error.response && error.response.data) {
            Sentry.addBreadcrumb({
                category: "error response",
                message: error.response.data,
                level: Sentry.Severity.Info
            })
        }
        Sentry.captureException(error);
        return (dispatch) => dispatch({ type, error })
}

export const getAuthHeaders = (additionalHeaders) => new Promise((resolve, reject) => {
    try {
        Auth.currentSession().then(data => {
            const {jwtToken} = data.idToken
            if(isAdmin(jwtToken)) {
                const headers = { 'Authorization': jwtToken };
                if(additionalHeaders) {
                    Object.entries(additionalHeaders).forEach(entry => {
                        headers[entry[0]] = entry[1];
                    });
                }
                resolve({headers});
            } else {
                reject(new Error('UNAUTHORIZED'));
            }
        }).catch(err => {
            Sentry.captureException(err);
            reject(err);
        })
    } catch (err) {
        console.error(err)
        const explicitErr = new Error({ name: 'AuthError', message: 'Signed in user encountered missing Auth' });
        Sentry.captureException(explicitErr)
        Sentry.captureException(err)
        throw explicitErr;
    }
})