import axios from 'axios';
import {
	GET_ORDERS_PENDING_APPROVAL_SUCCESS,
	GET_ORDERS_PENDING_APPROVAL_FAIL,
	GET_ORDERS_PENDING_SHIPMENT_SUCCESS,
	GET_ORDERS_PENDING_SHIPMENT_FAIL,
	GET_ORDER_SUCCESS,
	GET_ORDER_FAIL,
	GET_CUSTOMER_ORDERS_FAIL,
	GET_NEW_CUSTOMER_ORDERS_SUCCESS,
	APPROVING_ORDER,
	APPROVE_ORDER_SUCCESS,
	APPROVE_ORDER_FAIL,
	CLEAR_APPROVAL_STATE,
	UNAUTHORIZED,
	GET_MONTHAVERSARIES_FAIL,
	GET_MONTHAVERSARIES_SUCCESS,
	GETTING_MONTHAVERSARIES,
	CREATING_ORDER,
	CREATE_ORDER_SUCCESS,
	CREATE_ORDER_FAIL,
	PUT_MANIFEST_SUCCESS,
	PUT_MANIFEST_FAIL,
	PUTTING_MANIFEST,
	RECHARGING,
	RECHARGE_SUCCESS,
	RECHARGE_FAIL,
	RESCHEDULING,
	RESCHEDULE_SUCCESS,
	RESCHEDULE_FAIL,
	CANCEL_ORDER_SUCCESS,
	CANCEL_ORDER_FAIL,
	CANCELLING_ORDER,
	GETTING_ORDERS_PENDING_APPROVAL,
	GETTING_ORDERS_PENDING_SHIPMENT,
	CLEAR_ORDERS_STATE,
	CLEAR_RESCHEDULING,
	REFUNDING,
	REFUND_SUCCESS,
	REFUND_FAIL,
	ADJUSTING_CADENCE,
	ADJUST_CADENCE_FAIL,
	ADJUST_CADENCE_SUCCESS,
	RECURRING_ORDER_SUCCESS,
	RECURRING_ORDER_FAIL,
	CREATING_RECURRING
} from './types';
import { getAuthHeaders, handleApiError } from '../helpers/apiHelpers';
import keys from '../config';
const url = keys.apiUrl;

export const getOrdersPendingApproval = () => {
	return (dispatch) => {
		dispatch({ type: GETTING_ORDERS_PENDING_APPROVAL })
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/orders?nsOrderStatus=A`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_ORDERS_PENDING_APPROVAL_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, GET_ORDERS_PENDING_APPROVAL_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_ORDERS_PENDING_APPROVAL_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, GET_ORDERS_PENDING_APPROVAL_FAIL))
		})
	}
}

export const getOrdersPendingShipment = () => {
	return (dispatch) => {
		dispatch({ type: GETTING_ORDERS_PENDING_SHIPMENT })
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/orders?nsOrderStatus=B`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_ORDERS_PENDING_SHIPMENT_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, GET_ORDERS_PENDING_SHIPMENT_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_ORDERS_PENDING_SHIPMENT_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, GET_ORDERS_PENDING_SHIPMENT_FAIL))
		})
	}
}

export const recharge = (customerId, orderId) => {
	return (dispatch) => {
		dispatch({ type: RECHARGING })
		getAuthHeaders().then(headers => {
			axios.put(`${url}/admin/customers/${customerId}/orders/${orderId}/recharge`, {}, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: RECHARGE_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, RECHARGE_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, RECHARGE_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, RECHARGE_FAIL))
		})
	}
}

export const refundOrder = (customerId, orderId, body) => {
	return (dispatch) => {
		dispatch({ type: REFUNDING })
		getAuthHeaders().then(headers => {
			axios.put(`${url}/admin/customers/${customerId}/orders/${orderId}/refund`, body, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: REFUND_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, REFUND_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, REFUND_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, REFUND_FAIL))
		})
	}
}

export const reschedule = (customerId, deliverAt) => {
	return (dispatch) => {
		dispatch({ type: RESCHEDULING })
		getAuthHeaders().then(headers => {
			axios.put(`${url}/admin/manifests/${customerId}/schedule`, { deliverAt }, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: RESCHEDULE_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, RESCHEDULE_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, RESCHEDULE_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, RESCHEDULE_FAIL))
		})
	}
}

export const adjustCadence = (customerId, newCadenceWeeks) => {
	return (dispatch) => {
		dispatch({ type: ADJUSTING_CADENCE })
		getAuthHeaders().then(headers => {
			axios.put(`${url}/admin/manifests/${customerId}/cadence`, { newCadenceWeeks }, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: ADJUST_CADENCE_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, ADJUST_CADENCE_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, ADJUST_CADENCE_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, ADJUST_CADENCE_FAIL))
		})
	}
}

export const getOrder = (customerId, orderId) => {
	return (dispatch) => {
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/customers/${customerId}/orders/${orderId}?new=1`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_ORDER_SUCCESS, payload: res.data.order });
					else
						dispatch(handleApiError(res.error, GET_ORDER_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_ORDER_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, GET_ORDER_FAIL))
		})
	}
}

export const createRecurringOrder = (customerId) => {
	return (dispatch) => {
		dispatch({ type: CREATING_RECURRING })
		getAuthHeaders().then(headers => {
			axios.post(`${url}/admin/customers/${customerId}/recurring`, {}, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: RECURRING_ORDER_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, RECURRING_ORDER_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, RECURRING_ORDER_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, RECURRING_ORDER_FAIL))
		})
	}
}

export const getMonthaversaries = () => {
	return (dispatch) => {
		dispatch({ type: GETTING_MONTHAVERSARIES })
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/manifests/`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_MONTHAVERSARIES_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, GET_MONTHAVERSARIES_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_MONTHAVERSARIES_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, GET_MONTHAVERSARIES_FAIL))
		})
	}
}

export const clearApprovalState = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_APPROVAL_STATE })
	}
}

export const clearOrderState = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_ORDERS_STATE })
	}
}

export const clearReschedulingState = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_RESCHEDULING })
	}
}

export const approveOrder = (customerId, orderId) => {
	return (dispatch) => {
		dispatch({ type: APPROVING_ORDER })
		getAuthHeaders().then(headers => {
			axios.put(`${url}/admin/customers/${customerId}/orders/${orderId}/approve`, {}, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: APPROVE_ORDER_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, APPROVE_ORDER_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, APPROVE_ORDER_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, APPROVE_ORDER_FAIL))
		})
	}
}

export const cancelOrder = (customerId, orderId) => {
	return (dispatch) => {
		dispatch({ type: CANCELLING_ORDER })
		getAuthHeaders().then(headers => {
			axios.put(`${url}/admin/customers/${customerId}/orders/${orderId}/cancel`, {}, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: CANCEL_ORDER_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, CANCEL_ORDER_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, CANCEL_ORDER_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, CANCEL_ORDER_FAIL))
		})
	}
}

export const getCustomerOrders = (customerId) => {
	return (dispatch) => {
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/customers/${customerId}/orders?new=1`, headers)
				.then(res => {
					if (res.status === 200) {
						const type = GET_NEW_CUSTOMER_ORDERS_SUCCESS;
						dispatch({ type, payload: res.data.orders });
					}
					else
						dispatch(handleApiError(res.error, GET_CUSTOMER_ORDERS_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_CUSTOMER_ORDERS_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, GET_CUSTOMER_ORDERS_FAIL))
		})
	}
}

export const createEmergencyOrder = (customerId, products) => {
	return (dispatch) => {
		dispatch({ type: CREATING_ORDER })
		getAuthHeaders().then(headers => {
			axios.post(`${url}/admin/orders`, { customerId, products }, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: CREATE_ORDER_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, CREATE_ORDER_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, CREATE_ORDER_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, CREATE_ORDER_FAIL))
		})
	}
}

export const putManifest = (customerId, products) => {
	return (dispatch) => {
		dispatch({ type: PUTTING_MANIFEST })
		getAuthHeaders().then(headers => {
			axios.put(`${url}/admin/manifests/${customerId}`, { products }, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: PUT_MANIFEST_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, PUT_MANIFEST_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, PUT_MANIFEST_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, PUT_MANIFEST_FAIL))
		})
	}
}
