import React, { Fragment } from 'react';
import moment from 'moment';

const CustomerRow = ({
	name,
	customerId,
	email,
	phone,
	shipping,
	manifest,
	createdAt,
	status
}) => {
	const formattedPhone = () => {
		if (phone) {
			let num = '';
			num = `(${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 12)}`;
			return num;
		}
	}

	const renderManifestDetails = () => {
		if (manifest) {
			const { shipAt, deliverAt, modified, viewed } = manifest
			return (
				<p>
					<b>Modified:</b> {JSON.stringify(modified)}<br />
					<b>Viewed:</b> {JSON.stringify(viewed)}<br />
					<b>Next Ship Date:</b><br /> {moment(shipAt).format('ll')}<br />
					<b>Next Delivery Date:</b><br /> {moment(deliverAt).format('ll')}
				</p>
			)
		}
	}

	const renderShipping = () => {
		if (shipping) {
			return (
				<Fragment>
					{shipping.address}<br />
					{shipping.city}, {shipping.state} {shipping.zipcode}<br />
				</Fragment>
			)
		}
	}

	return (
		<tr className={status === "Active" ? "" : status === "Created" ? "gold" : status === "Paused" ? "blue" : "red"}>
			<th scope="row">
				{name} - {email}<br />
				{formattedPhone()}<br />
				{renderShipping()}
				{customerId}
			</th>
			<td>{manifest ? manifest.cadenceWeeks : ""}</td>
			<td className="text-left">{renderManifestDetails()}</td>
			<td>{createdAt}</td>
			<td><a href={`/customers/${customerId}`}>View Details</a></td>
			<td><a href={`/customers/${customerId}/manifest`}>Edit Manifest</a></td>
		</tr>
	);

}

export default CustomerRow;
