import React, { Fragment } from 'react';

export const ActionsModal = ({
    close,
    success,
    error,
    updating
}) => {
    const headerCopy = success ? "Success!" : error ? error : "Updating";
    const body = () => {
        if(updating) {
            return (
                <Fragment>
                    <span><div class="spinner-grow spinner-border-sm" role="status" /></span>
                    <span><div class="spinner-grow spinner-border-sm" role="status" /></span>
                    <span><div class="spinner-grow spinner-border-sm" role="status" /></span>
                </Fragment>
            )
        } else
            return <button className="btn-primary dialog-btn-ok"onClick={close}>OK</button>
    }
    return (
        <div className="modal-container">
            <div className="alert-dialog-small flex-column text-center">
                <div className="dialog-title-text success-color">
                    {headerCopy}
                </div>
                <div className="dialog-body-text">
                    <div className="flex-self-center">
                        {body()}
                    </div>
                </div>
            </div>
        </div>
    )
}