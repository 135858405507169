import * as Sentry from '@sentry/browser';
const isProd = (process.env.NODE_ENV === 'production');

export default class Logger {
    warn(body) {
        if (!isProd)
            console.warn(body)
    }

    error(event, exception, assertionMessage, assertion, logData) {
        if(assertion)
            console.assert(assertion, assertionMessage);

        if(logData)
            console.log(logData);
        
        if(isProd) {
            console.trace();
            if(exception) {
                Sentry.captureEvent(event);
                Sentry.captureException(exception);
            } else {
                Sentry.captureException(event);
            }
        } else {
            if(exception) {
                console.group('Thrown error:', event);
                console.error('Error context', exception);
            } else {
                console.error(event)
            }
        }
    }

    log(...args) {
        if(!isProd)
            console.log(...args);
    }

    group(obj) {
        if(!isProd)
            console.group(obj);
    }

    assert(assertion, assertionMessage) {
        if(!isProd)
            console.assert(assertion, assertionMessage);
    }
}
