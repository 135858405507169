export const
    // USERS
    CLEAR_UNAUTHED_STATE = 'CLEAR_UNAUTHED_STATE',

    //Orders
    GET_ORDERS_PENDING_APPROVAL_SUCCESS = 'GET_ORDERS_PENDING_APPROVAL_SUCCESS',
    GET_ORDERS_PENDING_APPROVAL_FAIL = 'GET_ORDERS_PENDING_APPROVAL_FAIL',
    GETTING_ORDERS_PENDING_APPROVAL = 'GETTING_ORDERS_PENDING_APPROVAL',
    GET_ORDERS_PENDING_SHIPMENT_SUCCESS = 'GET_ORDERS_PENDING_SHIPMENT_SUCCESS',
    GET_ORDERS_PENDING_SHIPMENT_FAIL = 'GET_ORDERS_PENDING_SHIPMENT_FAIL',
    GETTING_ORDERS_PENDING_SHIPMENT = 'GETTING_ORDERS_PENDING_SHIPMENT',
    GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS',
    GET_ORDER_FAIL = 'GET_ORDER_FAIL',
    GET_CUSTOMER_ORDERS_SUCCESS = 'GET_CUSTOMER_ORDERS_SUCCESS',
    GET_NEW_CUSTOMER_ORDERS_SUCCESS = 'GET_NEW_CUSTOMER_ORDERS_SUCCESS',
    GET_CUSTOMER_ORDERS_FAIL = 'GET_CUSTOMER_ORDERS_FAIL',
    APPROVING_ORDER = 'APPROVING_ORDER',
    APPROVE_ORDER_SUCCESS = 'APPROVE_ORDER_SUCCESS',
    APPROVE_ORDER_FAIL = 'APPROVE_ORDER_FAIL',
    CLEAR_APPROVAL_STATE = 'CLEAR_APPROVAL_STATE',
    CREATING_ORDER = 'CREATING_ORDER',
    CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS',
    CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL',
    GET_MONTHAVERSARIES_FAIL = 'GET_MONTHAVERSARIES_FAIL,',
    GET_MONTHAVERSARIES_SUCCESS = 'GET_MONTHAVERSARIES_SUCCESS,',
    GETTING_MONTHAVERSARIES = 'GETTING_MONTHAVERSARIES',
    PUT_MANIFEST_FAIL = 'PUT_MANIFEST_FAIL',
    PUT_MANIFEST_SUCCESS = 'PUT_MANIFEST_SUCCESS',
    PUTTING_MANIFEST = 'PUTTING_MANIFEST',
    RECHARGING = 'RECHARGING',
    RECHARGE_SUCCESS = 'RECHARGE_SUCCESS',
    RECHARGE_FAIL = 'RECHARGE_FAIL',
    RESCHEDULING = 'RESCHEDULING',
    RESCHEDULE_SUCCESS = 'RESCHEDULE_SUCCESS',
    RESCHEDULE_FAIL = 'RESCHEDULE_FAIL',
    CLEAR_RESCHEDULING = 'CLEAR_RESCHEDULING',
    CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS',
    CANCEL_ORDER_FAIL = 'CANCEL_ORDER_FAIL',
    CANCELLING_ORDER = 'CANCELLING_ORDER',
    CLEAR_ORDERS_STATE = 'CLEAR_ORDERS_STATE',
    REFUNDING = 'REFUNDING',
    REFUND_SUCCESS = 'REFUND_SUCCESS',
    REFUND_FAIL = 'REFUND_FAIL',
    ADJUSTING_CADENCE = 'ADJUSTING_CADENCE',
    ADJUST_CADENCE_FAIL = 'ADJUST_CADENCE_FAIL',
    ADJUST_CADENCE_SUCCESS = 'ADJUST_CADENCE_SUCCESS',
    RECURRING_ORDER_SUCCESS = 'RECURRING_ORDER_SUCCESS',
    RECURRING_ORDER_FAIL = 'RECURRING_ORDER_FAIL',
    CREATING_RECURRING = 'CREATING_RECURRING',

    //Customers
    GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS',
    GET_CUSTOMER_FAIL = 'GET_CUSTOMER_FAIL',
    GETTING_CUSTOMER = 'GETTING_CUSTOMER',
    GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS',
    GET_CUSTOMERS_FAIL = 'GET_CUSTOMERS_FAIL',
    GET_FEEDBACK_FAIL = 'GET_FEEDBACK_FAIL',
    GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS',
    SIGNIN_SUCCESS = 'SIGNIN_SUCCESS',
    GET_MANIFEST_SUCCESS = 'GET_MANIFEST_SUCCESS',
    GET_MANIFEST_FAIL = 'GET_MANIFEST_FAIL',
    SIGNING_IN = 'SIGNING_IN',
    SIGNIN_FAIL = 'SIGNIN_FAIL',
    UNAUTHORIZED = 'UNAUTHORIZED',
    GET_CUSTOMER_EVENTS_SUCCESS = 'GET_CUSTOMER_EVENTS_SUCCESS',
    GET_CUSTOMER_EVENTS_FAIL = 'GET_CUSTOMER_EVENTS_FAIL',
    UPDATE_STATUS_FAIL = 'UPDATE_STATUS_FAIL',
    UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS',
    UPDATING_STATUS = 'UPDATING_STATUS',
    CLEAR_CUSTOMERS_STATE = 'CLEAR_CUSTOMERS_STATE',

    //products
    GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL',
    GET_PRODUCT_GROUPS_SUCCESS = 'GET_PRODUCT_GROUPS_SUCCESS',
    GET_PRODUCT_GROUPS_FAIL = 'GET_PRODUCT_GROUPS_FAIL',

    // Interviews
    GET_ROUNDER_SUCCESS = 'GET_SURVEY_SUCCESS',
    GET_ROUNDER_FAIL = 'GET_SURVEY_FAIL',
    GET_AUTOROUNDER_SUCCESS = 'GET_AUTOROUNDER_SUCCESS',
    GET_AUTOROUNDER_FAIL = 'GET_AUTOROUNDER_FAIL',
    CLEAR_INTERVIEW = 'CLEAR_INTERVIEW',
    CLEAR_SIMULATOR = 'CLEAR_SIMULATOR',
    OPTIMIZE_SUCCESS = 'OPTIMIZE_SUCCESS,',
    OPTIMIZE_FAIL = 'OPTIMIZE_FAIL',
    SIMULATE_SUCCESS = 'SIMULATE_SUCCESS,',
    SIMULATE_FAIL = 'SIMULATE_FAIL',
    // Products
    UPDATE_PRODUCT_START_SAVING = 'UPDATE_PRODUCT_START_SAVING',
    ADD_PRODUCT_START_SAVING = 'ADD_PRODUCT_START_SAVING',
    DELETE_PRODUCT_START_SAVING = 'DELETE_PRODUCT_START_SAVING',

    // Messaging
    SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS',
    SEND_SMS_FAIL = 'SEND_SMS_FAIL',
    SENDING_SMS = 'SENDING_SMS',
    CLEAR_SMS = 'CLEAR_SMS',

    // Reports
    GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS',
    GET_REPORT_FAIL = 'GET_REPORT_FAIL',
    GETTING_REPORT = 'GETTING_REPORT',
    GETTING_REPORTS = 'GETTING_REPORTS',
    GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS',
    GET_REPORTS_FAIL = 'GET_REPORTS_FAIL',
    CLEAR_REPORT = 'CLEAR_REPORT',

    // Global Errors
    SIGNED_IN_AUTH_ERROR = 'SIGNED_IN_AUTH_ERROR',
    NEW_USER_AUTH_ERROR = 'NEW_USER_AUTH_ERROR',
    CLEAR_GLOBAL_ERRORS = 'CLEAR_GLOBAL_ERRORS'