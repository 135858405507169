import React, {Component} from 'react';
import Header from './Header';
import {getReports} from '../actions';
import {connect} from 'react-redux';
import {Loading} from './Common';
import moment from 'moment';

class Reports extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.props.getReports();
    }

    componentDidUpdate() {
        const {reports, getReportsError} = this.props;
        if(reports && !this.state.reports)
            this.setState({reports})
        if(getReportsError && !this.state.getReportsError)
            this.setState({getReportsError})
    }

    renderReports() {
        const {reports} = this.state;
        if(reports) {
            const {generatedReports} = reports;
            const buttons = [];
            const sortedReports = Object.entries(generatedReports).map(entry => {
                const report = entry[0];
                const generatedReport = entry[1];
                const {presignedUrl,lastGeneratedAt,description} = generatedReport;
                return {report, presignedUrl,lastGeneratedAt,description}
            });
            sortedReports.sort((a,b) => {
                if(a.report > b.report) { return 1; }
                if(a.report < b.report) { return -1; }
                return 0;
            })
            sortedReports.forEach(r => {
                const {report, presignedUrl,lastGeneratedAt,description} = r;
                buttons.push(
                    <div className="flex-row report-btn-row">
                        <a className="btn-primary" href={presignedUrl} download={`${report}.csv`}>{report}</a> ({moment.utc(lastGeneratedAt).local().format('YYYY-MM-DD h:mm:ss a')}) ({description})
                    </div>
                )
            })
            return (
                <div>
                    <h2>Click one to get and download report</h2>
                    <div className="reports-container">
                        {buttons}
                    </div>
                </div>
            )
        } else {
            return <Loading />
        }
    }

    render() {
        return (
            <div className="sdrop-body">
                <Header history={this.props.history} />
                {this.renderReports()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {reports, gettingReports, getReportsError} = state.reports;
    return {reports, gettingReports, getReportsError}
}

export default connect(mapStateToProps, {
    getReports
})(Reports);
