import React from 'react';
import { Link } from 'react-router-dom';

const OrderRow = ({
    orderId,
    customerId,
    customerStr,
    createdDate,
    expectedShipDate,
    deliveryDate,
    status,
    deliveryMethod,
    netSuite,
    createdAt,
    customerSince
}) => {
    console.log(createdAt)
    return (
        <tr>
            <th className="order-link">
                <Link to={{pathname: `/customers/${customerId}/orders/${orderId}/review`, state: { netSuite, status }}}>{orderId}</Link>
            </th>
            {createdAt ? 
            <td className="order-link">
                <Link to={{pathname: `/customers/${customerId}/orders/${orderId}/packing-slip`, state: {createdAt, netSuite}}} target="_blank">Packing Slip</Link> 
            </td> :
            <td className="order-link"> 
            </td>
            }       
            <td className="order-link">
                <Link to={{pathname: `/customers/${customerId}`}}>{customerStr}</Link>
            </td>
            <td className="text-center">{expectedShipDate}</td>
            <td className="text-center">{deliveryDate}</td>
            <td className="text-center">{deliveryMethod}</td>
            <td className="text-center">{netSuite ? netSuite.nsCustomerId : ""}</td>
            <td className="text-center">{createdDate}</td>
            <td className="text-center">{customerSince}</td>
        </tr>
    );

}

export default OrderRow;
