import React from 'react';

const NotFound = () => {
    return (
        <div>
            <h2 className="text-center">404 Page Not Found</h2>
            <button onClick={() => window.location.href = ''}>Go Home</button>
        </div>
    )
}

export default NotFound;