import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {clearReschedulingState, reschedule} from '../../actions';
import moment from 'moment';

class DateSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.setNewDeliveryDate = this.setNewDeliveryDate.bind(this);
        this.clearAndClose = this.clearAndClose.bind(this);
    }

    componentDidUpdate() {
        
    }

    renderDateSelector() {
        const {allowedDeliveryDates, manifest, rescheduleSuccess, rescheduleError, rescheduling} = this.props;
        const {deliverAt, needsDateErr} = this.state;
        if(!rescheduleSuccess && !rescheduleError && !rescheduling) {
            const options = allowedDeliveryDates.map(date => {
                return <option value={date}>{moment(date).format('ddd MMM D, YYYY')}</option>
            })
    
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column flex-space-between text-center">
                        <div className="dialog-title-text">Change Delivery Date</div>
                        Current Contact Date: {moment(manifest.contactAt).format('ll')}<br/>
                        Current Delivery Date: {moment(manifest.deliverAt).format('ll')}
                        <select className="text-center" onChange={(e) => this.setState({deliverAt: e.target.value})}>
                            <option>Select Date...</option>
                            {options}
                        </select>
                        <div className="flex-row flex-space-between">
                            <button className="btn btn-secondary dialog-btn-ok" onClick={this.clearAndClose}>Cancel</button>
                            <button className="btn-primary dialog-btn-ok" onClick={this.setNewDeliveryDate}>Send</button>
                        </div>
                        <div className={!deliverAt && needsDateErr ? "red" : "hide"}>Please select a date</div>
                    </div>
                </div>
            )
        }
    }

    setNewDeliveryDate() {
        try {
            const {deliverAt} = this.state;
            const {customer} = this.props;
			if(deliverAt) {
                const {customerId, status} = customer;
                if(status === "Paused") 
                    this.props.updateCustomerStatus(customerId, 'resume', deliverAt);
                else 
                    this.props.reschedule(customerId, deliverAt);
			} else {
				this.setState({needsDateErr: true})
			}
		} catch (err) {
			console.error(err)
		}
    }

    renderError() {
        const {rescheduleError} = this.props;
        if (rescheduleError) {
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column text-center">
                        <div className="dialog-title-text red">
                            Reschedule failed :(
                        </div>
                        <button className="btn btn-secondary dialog-btn-ok" onClick={this.clearAndClose}>Close</button>
                    </div>
                </div>
            )
        }
    }

    clearAndClose() {
        this.props.clearReschedulingState();
        this.props.close();
    }

    renderSuccess() {
        const {rescheduleSuccess} = this.props;
        if (rescheduleSuccess) {
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column text-center">
                        <div className="dialog-title-text green">
                            Update was successful! :)
                        </div>
                        <button className="btn btn-secondary dialog-btn-ok" onClick={this.clearAndClose}>Close</button>
                    </div>
                </div>
            )
        }
    }

    renderLoading() {
        const {rescheduling} = this.props;
        if(rescheduling) {
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column text-center">
                        <div className="dialog-title-text">
                            Updating...
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <Fragment>
                {this.renderDateSelector()}
                {this.renderError()}
                {this.renderSuccess()}
                {this.renderLoading()}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {rescheduling, rescheduleSuccess, rescheduleError} = state.orders;
    return {rescheduling, rescheduleSuccess, rescheduleError};
}

export default connect(mapStateToProps, {
    clearReschedulingState,
    reschedule
})(DateSelection)