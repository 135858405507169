
export function getFullOrder(order, productGroupsMap) {
  if (!order) {
    return order
  }
  
  const { productGroupEntries, taxes, discounts, refunds } = order;
  order.productsMap = {};
  order.taxCents = 0;
  order.discountCents = 0;
  order.refundCents = 0;

  // productsMap - productId as key
  // instances of productsMap with groupName as key have been renamed to productGroupsMap
  productGroupEntries.forEach(group => {
      const { products, articleType, pricePerArticleCents, groupName } = group;
      products.forEach(product => {
          const { productId, quantity } = product;
          order.productsMap[productId] = product
          let refundQuantity = 0;
          let chargedQuantity = quantity;
          Object.assign(order.productsMap[productId], { articleType, pricePerArticleCents, groupName, refundQuantity, chargedQuantity })
          if (productGroupsMap && productGroupsMap[groupName]) {
              const { brandName, displayName, productType } = productGroupsMap[groupName];
              Object.assign(order.productsMap[productId], { brandName, displayName, productType })
          }
      })
  })

  // if taxes, sum up for display
  taxes.forEach(tax => {
      order.taxCents += tax.amountCents;
  })

  // if discounts, sum up for display
  discounts.forEach(discount => {
      order.discountCents += discount.amountCents;
  })

  // if refunds, sum up for display
  refunds.forEach(refund => {
      const { amountCents, packages } = refund;
      order.refundCents += amountCents;
      Object.entries(packages).forEach(entry => {
          const productId = entry[0],
              quantity = entry[1];
          order.productsMap[productId].refundQuantity += quantity;
          order.productsMap[productId].chargedQuantity -= quantity;
      })
  })

  return order;
}
