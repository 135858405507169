import {
  GET_ORDERS_PENDING_APPROVAL_SUCCESS,
  GET_ORDERS_PENDING_APPROVAL_FAIL,
  GETTING_ORDERS_PENDING_APPROVAL,
  GET_ORDERS_PENDING_SHIPMENT_SUCCESS, 
  GET_ORDERS_PENDING_SHIPMENT_FAIL,
  GETTING_ORDERS_PENDING_SHIPMENT,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  GET_NEW_CUSTOMER_ORDERS_SUCCESS,
  GET_CUSTOMER_ORDERS_FAIL,
  APPROVE_ORDER_FAIL,
  APPROVE_ORDER_SUCCESS,
  APPROVING_ORDER,
  CLEAR_APPROVAL_STATE,
  CREATING_ORDER,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_SUCCESS,
  GET_MONTHAVERSARIES_FAIL,
  GET_MONTHAVERSARIES_SUCCESS,
  PUT_MANIFEST_SUCCESS,
  PUT_MANIFEST_FAIL,
  PUTTING_MANIFEST,
  RECHARGING,
  RECHARGE_SUCCESS,
  RECHARGE_FAIL,
  RESCHEDULING,
  RESCHEDULE_SUCCESS,
  RESCHEDULE_FAIL,
  CLEAR_RESCHEDULING,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  CANCELLING_ORDER,
  CLEAR_ORDERS_STATE,
  REFUNDING,
  REFUND_SUCCESS,
  REFUND_FAIL,
  ADJUSTING_CADENCE,
  ADJUST_CADENCE_FAIL,
  ADJUST_CADENCE_SUCCESS,
  RECURRING_ORDER_SUCCESS,
  RECURRING_ORDER_FAIL,
  CREATING_RECURRING
} from '../actions/types';

const INITIAL_STATE = {
  error:'',
  approvalErr:'',
  approvalOrders: undefined,
  shippingOrders: undefined,
  selectedOrder: undefined,
  order: undefined,
  nextOrder: undefined,
  prevOrder: undefined,
  orderPosition: undefined,
  csv: undefined,
  manifests: undefined,
  orderCreateSuccess: undefined,
  orderCreateError: undefined,
  creatingOrder: false,
  putManifestError: undefined,
  putManifestSuccess: undefined,
  puttingManifest: false
};

export default (state= INITIAL_STATE, action) => {
  switch(action.type){
    case GET_ORDERS_PENDING_APPROVAL_SUCCESS:
        return { ...state, approvalOrders: action.payload, approvalOrdersError: '', gettingApprovalOrders: false};
    case GET_ORDERS_PENDING_APPROVAL_FAIL:
        return { ...state, approvalOrders: undefined, approvalOrdersError: action.error, gettingApprovalOrders: false};
    case GETTING_ORDERS_PENDING_APPROVAL:
        return { ...state, approvalOrders: undefined, approvalOrdersError: undefined, gettingApprovalOrders: true};
    case GET_ORDERS_PENDING_SHIPMENT_SUCCESS:
      return { ...state, shippingOrders: action.payload, shippingOrdersError: '', gettingShippingOrders: false};
    case GET_ORDERS_PENDING_SHIPMENT_FAIL:
        return { ...state, shippingOrders: undefined, shippingOrdersError: action.error, gettingShippingOrders: false};
    case GETTING_ORDERS_PENDING_SHIPMENT:
        return { ...state, shippingOrders: undefined, shippingOrdersError: undefined, gettingShippingOrders: true};
    case GET_ORDER_SUCCESS:
      return { ...state, order: action.payload, getOrderError: undefined};
    case GET_ORDER_FAIL:
        return { ...state, order: undefined, getOrderError: action.error};
    case CLEAR_ORDERS_STATE:
        return {
          ...state,
          order: undefined,
          getOrderError: undefined,
          orders: undefined,
          error: undefined,
          refunding: false,
          refundError: undefined,
          refundSuccess: undefined,
          recharging: false,
          rechargeSuccess: undefined,
          rechargeError: undefined,
          adjustingCadence: false,
          adjustCadenceSuccess: undefined,
          adjustCadenceError: undefined,
          creatingRecurring: false,
          recurringSuccess: undefined,
          recurringError: undefined
        }
    case GET_NEW_CUSTOMER_ORDERS_SUCCESS:
      return { ...state, newOrders: action.payload, error: ''};
    case GET_CUSTOMER_ORDERS_FAIL:
        return { ...state, orders: undefined, error: action.payload};
    case APPROVE_ORDER_SUCCESS:
      return { ...state, isApproved: action.payload, appovalError: '', approvingOrder: false};
    case APPROVE_ORDER_FAIL:
        return { ...state, isApproved: undefined, approvalError: action.error, approvingOrder: false};
    case APPROVING_ORDER:
        return { ...state, isApproved: undefined, approvalError: undefined, approvingOrder: true};
    case CLEAR_APPROVAL_STATE:
        return { ...state, isApproved: undefined, approvalError: undefined, approvingOrder: false};
    case CANCEL_ORDER_SUCCESS:
          return { ...state, cancelingOrder: false, isCanceled: action.payload, cancelError: false}
    case CANCEL_ORDER_FAIL:
          return { ...state, cancelingOrder: false, isCanceled: false, cancelError: action.error}
    case CANCELLING_ORDER:
          return { ...state, cancelingOrder: true, isCancelled: false, cancelError: false}
    case GET_MONTHAVERSARIES_SUCCESS:
      return { ...state, manifests: action.payload, error: undefined };
    case GET_MONTHAVERSARIES_FAIL:
      return { ...state, manifests: undefined, error: action.error };
    case CREATING_ORDER:
      return { ...state, orderCreateError: undefined, orderCreateSuccess: undefined, creatingOrder: true};
    case CREATE_ORDER_SUCCESS:
      return { ...state, orderCreateError: undefined, orderCreateSuccess: action.payload, creatingOrder: false};
    case CREATE_ORDER_FAIL:
      return { ...state, orderCreateError: action.error, orderCreateSuccess: undefined, creatingOrder: false};
    case PUTTING_MANIFEST:
      return { ...state, putManifestError: undefined, putManifestSuccess: undefined, puttingManifest: true};
    case PUT_MANIFEST_SUCCESS:
      return { ...state, putManifestError: undefined, putManifestSuccess: true, puttingManifest: false};
    case PUT_MANIFEST_FAIL:
      return { ...state, putManifestError: action.error, putManifestSuccess: undefined, puttingManifest: false};
    case RECHARGING:
      return { ...state, recharging: true, rechargeSuccess: undefined, rechargeError: undefined}
    case RECHARGE_SUCCESS:
      return { ...state, recharging: false, rechargeSuccess: action.payload, rechargeError: undefined}
    case RECHARGE_FAIL:
      return { ...state, recharging: false, rechargeSuccess: undefined, rechargeError: action.error}
    case REFUNDING:
      return { ...state, refunding: true, refundSuccess: undefined, refundError: undefined}
    case REFUND_SUCCESS:
      return { ...state, refunding: false, refundSuccess: action.payload, refundError: undefined}
    case REFUND_FAIL:
      return { ...state, refunding: false, refundSuccess: undefined, refundError: action.error}
    case RESCHEDULING:
      return { ...state, rescheduling: true, rescheduleSuccess: undefined, rescheduleError: undefined}
    case RESCHEDULE_SUCCESS:
      return { ...state, rescheduling: false, rescheduleSuccess: action.payload, rescheduleError: undefined}
    case RESCHEDULE_FAIL:
      return { ...state, rescheduling: false, rescheduleSuccess: undefined, rescheduleError: action.error}
    case CLEAR_RESCHEDULING:
      return { ...state, rescheduling: false, rescheduleSuccess: undefined, rescheduleError: undefined}
    case ADJUSTING_CADENCE:
      return { ...state, adjustingCadence: true, adjustCadenceSuccess: false, adjustCadenceError: undefined}
      case ADJUST_CADENCE_SUCCESS:
        return { ...state, adjustingCadence: false, adjustCadenceSuccess: action.payload, adjustCadenceError: undefined}
    case ADJUST_CADENCE_FAIL:
      return { ...state, adjustingCadence: false, adjustCadenceSuccess: undefined, adjustCadenceError: action.error}
    case RECURRING_ORDER_SUCCESS:
        return { ...state, creatingRecurring: false, recurringSuccess: action.payload, recurringError: undefined}
    case RECURRING_ORDER_FAIL:
        return { ...state, creatingRecurring: false, recurringSuccess: undefined, recurringError: action.error}
    case CREATING_RECURRING:
        return { ...state, creatingRecurring: true, recurringSuccess: undefined, recurringError: undefined}
    default:
      return state;
  }
}
