import React, { Component } from 'react';
import Header from '../Header';
import CustomerNav from './CustomerNav';
import { connect } from 'react-redux';
import { getCustomerEvents, getCustomer } from '../../actions';
import { Col, Row, Container } from 'react-bootstrap';

class Events extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const { customerId } = params;

        this.props.getCustomerEvents(customerId);
        this.props.getCustomer(customerId);
        this.setState({ customerId })
    }

    componentDidUpdate() {
        const {
            events,
            eventsError,
            customer,
            error
        } = this.props;

        if (events && !this.state.events) {
            this.setState({ events });
        } else if (eventsError && !this.state.error) {
            this.setState({ error: eventsError });
        }
        if (customer && !this.state.customer) {
            this.setState({ customer });
        } else if (error && !this.state.error) {
            this.setState({ error });
        }
    }

    formatPhone(value) {
        let num = '';
        num = `(${value.slice(2, 5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`;
        return num;
    }

    renderEventsError() {
        return (
            <div></div>
        )
    }

    renderEvents() {
        const { events } = this.state;
        if (events && !events.length) {
            return (
                <h3>No events for this user</h3>
            )
        } else if (events && events.length) {
            const rows = events.map(e => {
                const payload = JSON.parse(e.payload);
                const payloadRows = Object.entries(payload).map(p => {
                    return <li>{JSON.stringify(p[0])}: {JSON.stringify(p[1])}</li>
                })

                return (
                    <tr>
                        <td>{e.ts}</td>
                        <td>{e.type}</td>
                        <td><ul>{payloadRows}</ul></td>
                        <td style={{ whiteSpace: 'pre-line' }}>{e.pretty}</td>
                    </tr>
                )
            })
            return (
                <div className="table-wrapper">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Timestamp</th>
                                <th>Event Type</th>
                                <th>Payload</th>
                                <th>Pretty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            )
        }
    }

    renderCustomerInfo() {
        const { customer } = this.state;
        if (customer) {
            return (
                <Col xs={9} className="customer-card">
                    <Row>{customer.customerId}</Row>
                    <Row><b>{customer.firstName} {customer.lastName}</b></Row>
                    <Row>{customer.shipping.city}, {customer.shipping.state}</Row>
                    <Row>{this.formatPhone(customer.phone)} - {customer.email}</Row>
                </Col>
            )
        }
    }

    render() {
        const { customerId } = this.state;
        return (
            <div>
                <Header />
                <CustomerNav history={this.props.history} customerId={customerId} showMessage={() => this.setState({ showMessage: true })} />
                <div>
                    <Container>
                        <div className="sdrop-body flex-column">
                            <Row>
                                <h2 style={{ marginTop: 20 }}>Customer Events</h2>
                            </Row>
                            <Row style={{ marginTop: 20 }}>
                                {this.renderCustomerInfo()}
                            </Row>
                            {this.renderEvents()}
                            {this.renderEventsError()}
                        </div>
                    </Container>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { events, eventsError, customer, error } = state.customers;
    return { events, eventsError, customer, error };
}

export default connect(mapStateToProps, {
    getCustomerEvents,
    getCustomer
})(Events);
