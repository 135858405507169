import { combineReducers } from 'redux';
import customersReducer from './customersReducer';
import interviewsReducer from './interviewsReducer';
import globalErrorsReducer from './globalErrorReducer';
import messagingReducer from './messagingReducer';
import ordersReducer from './ordersReducer';
import productsReducer from './productsReducer';
import reportsReducer from './reportsReducer';
import usersReducer from './usersReducer';

export default combineReducers({
  customers: customersReducer,
  globalErrors: globalErrorsReducer,
  interviews: interviewsReducer,
  messaging: messagingReducer,
  orders: ordersReducer,
  products: productsReducer,
  reports: reportsReducer,
  users: usersReducer
});

