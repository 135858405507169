import axios from 'axios';
import keys from '../config';
import {
    GET_ROUNDER_SUCCESS,
    GET_ROUNDER_FAIL,
    GET_AUTOROUNDER_SUCCESS,
    GET_AUTOROUNDER_FAIL,
    CLEAR_INTERVIEW,
    CLEAR_SIMULATOR,
    OPTIMIZE_SUCCESS,
    SIMULATE_SUCCESS,
    SIMULATE_FAIL,
    UNAUTHORIZED
} from './types';
import { getAuthHeaders, handleApiError } from '../helpers/apiHelpers';
const url = keys.apiUrl;

export const getRounder = (interviewData) => {
    return async (dispatch) => {
        getAuthHeaders().then(headers => {
            axios.post(`${url}/admin/rounder`, interviewData, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_ROUNDER_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, GET_ROUNDER_FAIL))
                })
                .catch(err => {
                    if (err === 'UNAUTHORIZED')
                        dispatch(handleApiError(err, UNAUTHORIZED))
                    else
                        dispatch(handleApiError(err, GET_ROUNDER_FAIL))
                })
        }).catch(err => {
            dispatch(handleApiError(err, GET_ROUNDER_FAIL))
        })
    }
}

export const getAutoRounder = (interviewData, timestamp) => {
    return async (dispatch) => {
        getAuthHeaders().then(headers => {
            axios.post(`${url}/admin/rounder`, interviewData, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_AUTOROUNDER_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, GET_AUTOROUNDER_FAIL))
                })
                .catch(err => {
                    if (err === 'UNAUTHORIZED')
                        dispatch(handleApiError(err, UNAUTHORIZED))
                    else
                        dispatch(handleApiError(err, GET_AUTOROUNDER_FAIL))
                })
        }).catch(err => {
            dispatch(handleApiError(err, GET_AUTOROUNDER_FAIL))
        })
    }
}

export const simulate = (interviewData, timestamp) => {
    return async (dispatch) => {
        getAuthHeaders().then(headers => {
            axios.post(`${url}/admin/simulate`, interviewData, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: SIMULATE_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, SIMULATE_FAIL))
                })
                .catch(err => {
                    if (err === 'UNAUTHORIZED')
                        dispatch(handleApiError(err, UNAUTHORIZED))
                    else
                        dispatch(handleApiError(err, SIMULATE_FAIL))
                })
        }).catch(err => {
            dispatch(handleApiError(err, SIMULATE_FAIL))
        })
    }
}

export const clearRounder = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_INTERVIEW
        })
    }
}

export const clearSimulator = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SIMULATOR
        })
    }
}


const makeCombinations = (productA, productB) => {
    const mesh = {};
    for (let i = 0; i < 25; ++i) {
        for (let j = 0; j < 25; ++j) {
            if (i === 0 && j === 0) continue;
            const value = productA.articlesPerPackage * i +
                productB.articlesPerPackage * j;
            if (!(value in mesh)) mesh[value] = [];
            const combination = {};
            if (i !== 0) combination[productA.productId] = i;
            if (j !== 0) combination[productB.productId] = j;
            mesh[value].push(combination);
        }
    }
    return mesh;
};

export const productOptions = (products) => {
    let options = [];
    products.forEach(option => {
        if (!option.hidden) {

            let group = options.filter(o => o.id === option.groupName)[0];
            if (group) {
                options = options.filter(o => o.id !== option.groupName);
                group.rawArticleCounts.push({
                    productId: option.productId,
                    articlesPerPackage: option.articlesPerPackage,
                });
                group.articleCounts = optimize(group.rawArticleCounts).payload;
                group.rawArticleCounts.sort((a, b) => {
                    return a.articlesPerPackage - b.articlesPerPackage;
                });
                group.manifest = group.articleCounts[JSON.stringify(group.articles)];
                options.push(group);
            } else {
                const group = {
                    id: option.groupName,
                    name: option.displayName,
                    type: option.type,
                    brand: option.itemBrand,
                    price: (option.stripePriceCents / 100),
                    articleType: option.articleType,
                    manifest: {},
                    articles: 0,
                    rawArticleCounts: [{
                        productId: option.productId,
                        articlesPerPackage: option.articlesPerPackage,
                    }],
                    articleCounts: []
                }

                options.push(group);
            }
        }
    })

    const typeGroups = [];
    options.forEach(product => {
        const typeGroup = typeGroups.filter(t => t.type === product.type);
        if (typeGroup.length) {
            typeGroup[0].products.push(product)
        } else {
            typeGroups.push({ type: product.type, products: [product] })
        }
    });

    return typeGroups;
}

export const optimize = (products) => {
    const combinations = products.reduce((acc, val, i) => {
        if ((i + 1) >= products.length) return acc;
        const combo = makeCombinations(val, products[i + 1]);
        return Object.keys(combo).reduce((obj, key) => {
            if (!(key in acc)) acc[key] = [];
            acc[key] = [...acc[key], ...combo[key]];
            return acc;
        }, acc);
    }, {});

    /*
      combinations has shape:
      {
        articlesNeeded1: [
          {productId1: packages1, ..., productIdN: packagesN},
          {productId1: packages1, ..., productIdM: packagesM},
          ...
        ],
        ...
      }
    */

    Object.keys(combinations).forEach(key => {
        let packages = 0;
        let selected;
        if (combinations[key].length > 1) {
            combinations[key].forEach(combo => {
                let total = 0;
                Object.values(combo).forEach(val => {
                    total += val;
                });
                if (!packages || packages > total) {
                    packages = total;
                    selected = combo;
                }
            })
            combinations[key] = selected;
        } else {
            combinations[key] = combinations[key][0];
        }
    })
    return {
        type: OPTIMIZE_SUCCESS,
        payload: combinations
    }
}
