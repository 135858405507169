import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCustomerOrders, getProductGroups, clearOrderState } from '../../actions';
import { Col, Row, Container } from 'react-bootstrap';
import { Loading } from '../Common';
import {
    createProductGroupsMap,
    createProductTypesMapFromEntries,
    getProductsFromEntries
} from '../../helpers/productHelpers';
import Logger from '../../helpers/Logger';
const logger = new Logger();

class FirstOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.props.getProductGroups();
    }

    componentDidUpdate() {
        const { customer, orders, rounder, productGroups } = this.props;

        if (customer && !this.state.customer) {
            this.setState({ customer });
            this.props.getCustomerOrders(customer.customerId);
            this.props.getProductGroups();
        }

        if (orders && !this.state.orders) {
            this.props.clearOrderState();
            orders.sort((a, b) => {
                const d1 = new Date(a.createdAt).getTime();
                const d2 = new Date(b.createdAt).getTime();
                if (d1 < d2) { return -1; }
                if (d1 > d2) { return 1; }
                return 0;
            });
            this.setState({ orders });
        }

        if (rounder && rounder.productSets && !this.state.productSets)
            this.setState({ productSets: rounder.productSets });

        if (productGroups && !this.state.productGroups)
            this.setState({ productGroups })
    }

    renderRows() {
        const { productSets, orders, productGroups } = this.state;
        if (orders && productSets && productGroups) {
            try {
                const { productGroupEntries } = orders[0];
                const products = getProductsFromEntries(productGroupEntries, productGroups)
                const res = createProductGroupsMap(productGroups)
                const { productGroupsMap } = res;
                logger.group(productGroupsMap)
                if (!productGroupsMap) {
                    throw res.error
                }
                products.sort((a, b) => {
                    if (a.productType > b.productType) { return 1; }
                    if (a.productType < b.productType) { return -1; }
                    return 0;
                });
                const { productTypesMap, error } = createProductTypesMapFromEntries(productGroupEntries, productGroupsMap);
                if (!error) {
                    products.forEach(product => {
                        const { title, articles, groupName, productType } = product;
                        if (productTypesMap[productType]) {
                            productTypesMap[productType].chosenProduct = title;
                            productTypesMap[productType].chosenArticles = articles;
                            productTypesMap[productType].chosenProductGroup = groupName;
                        } else
                            logger.error(new Error('productGroup not present in productGroupsMap'))
                    });

                    Object.entries(productSets).forEach(entry => {
                        if (productTypesMap[entry[0]]) {
                            const { groupName } = entry[1].preferred;
                            productTypesMap[entry[0]].recommendedProduct = `${productGroupsMap[groupName].brandName} ${productGroupsMap[groupName].displayName}`;
                            productTypesMap[entry[0]].proposedArticles = entry[1].preferred.proposedArticles;
                        }
                    })
                    const rows = Object.entries(productTypesMap).map(entry => {
                        logger.log(productTypesMap)
                        logger.log(entry)
                        return (
                            <tr>
                                <th>{entry[0]}</th>
                                <td>{entry[1].chosenProduct}<br />{entry[1].chosenArticles} articles</td>
                                <td>{entry[1].recommendedProduct}<br />{entry[1].proposedArticles} articles</td>
                            </tr>
                        )
                    })
                    return rows;
                } else {
                    throw error;
                }
            } catch (err) {
                console.error(err);
            }
        } else {
            return (
                <Loading />
            )
        }
    }

    render() {
        return (
            <Container>
                <h2>First Order</h2>
                <Row>
                    <Col xs={12}>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Product Type</th>
                                    <th>Selected</th>
                                    <th>Recommended</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderRows()}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    const { customer, error } = state.customers;
    const { orders } = state.orders;
    const { productGroups, productGroupsError } = state.products;
    const { rounder } = state.interviews;
    return { customer, error, orders, rounder, productGroups, productGroupsError };
}

export default connect(mapStateToProps, {
    getCustomerOrders,
    getProductGroups,
    clearOrderState
})(FirstOrder);