const Demographics = ({keyDemos}) => {
    const demographics = [
        // ASHLEY
        {
            name: "ASHLEY",
            family: [
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 1,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 4.5,
            tierPreferences: [
                "SupplyDropPick",
                "EcoFriendly"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 5
                },
                {
                    type: 'Recyclable',
                    bags: 1,
                    frequency: 3
                },
                {
                    type: 'Compost',
                    bags: 1,
                    frequency: 2
                }

            ],
            guests: 75,
            dishwashing: {
                frequency: 4,
                products: [
                    "Packs"
                ]
            },
            refills: false,
            scentPreferences: [
                "Lavender"
            ],
            laundry: {
                frequency: 5,
                preferences: [
                    "Packs",
                    "HighEfficiency"
                ]
            },
            housekeeping: 4
        },
        // COLE
        {
            name: "COLE",
            family: [
                {
                    age: 18,
                    gender: "Male",
                    staysHome: false
                },
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 1,
            tierPreferences: [
                "Value"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 3
                },
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 2
                },
            ],
            guests: 50,
            dishwashing: {
                frequency: 2,
                products: [
                    "Packs"
                ]
            },
            refills: false,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 2,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 2
        },
        // STAN
        {
            name: "STAN",
            family: [
                {
                    age: 45,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 45,
                    gender: "Female",
                    staysHome: false
                },
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "NationallyRecognizedBrandLead",
                "SupplyDropPick"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 6
                },
                {
                    type: 'Recyclable',
                    bags: 1,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 1
                },
            ],
            guests: 25,
            dishwashing: {
                frequency: 5,
                products: [
                    "Liquid"
                ]
            },
            refills: true,
            scentPreferences: [
                "Citrus"
            ],
            laundry: {
                frequency: 6,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 5
        },
        // CINDY
        {
            name: "CINDY",
            family: [
                {
                    age: 25,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Female",
                    staysHome: false
                },
            ],
            pets: {
                Puppy: 0,
                Dog: 1,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 4,
            tierPreferences: [
                "NationallyRecognizedBrandLead",
                "Value"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 5
                },
            ],
            guests: 75,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Spring"
            ],
            laundry: {
                frequency: 7,
                preferences: [
                    "Liquid",
                    "FreeAndClear"
                ]
            },
            housekeeping: 4
        },
        // MARY LOU
        {
            name: "MARY LOU",
            family: [
                {
                    age: 65,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 65,
                    gender: "Male",
                    staysHome: true
                },
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "NationallyRecognizedBrandLead",
                "SensitiveSkin"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 3
                },
            ],
            guests: 25,
            dishwashing: {
                frequency: 3,
                products: [
                    "Packs"
                ]
            },
            refills: false,
            scentPreferences: [
                "Unscented"
            ],
            laundry: {
                frequency: 3,
                preferences: [
                    "Liquid",
                    "FreeAndClear"
                ]
            },
            housekeeping: 3
        },
        // ABIGAIL
        {
            name: "ABIGAIL",
            family: [
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                },
            ],
            pets: {
                Puppy: 0,
                Dog: 1,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "SupplyDropPick",
                "Value"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 4
                },
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 2
                },
            ],
            guests: 100,
            dishwashing: {
                frequency: 3,
                products: [
                    "Packs"
                ]
            },
            refills: false,
            scentPreferences: [
                "Lavender"
            ],
            laundry: {
                frequency: 2,
                preferences: [
                    "FreeAndClear"
                ]
            },
            housekeeping: 2
        },
        // CONNOR
        {
            name: "CONNOR",
            family: [
                {
                    age: 18,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Male",
                    staysHome: false
                },
            ],
            pets: {
                Puppy: 0,
                Dog: 1,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 4,
            tierPreferences: [
                "Value",
                "NationallyRecognizedBrandLead",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 3
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                },
            ],
            guests: 100,
            dishwashing: {
                frequency: 4,
                products: [
                    "Liquid"
                ]
            },
            refills: false,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 3,
                preferences: [
                    "Packs"
                ]
            },
            housekeeping: 2
        },
        // ALISON
        {
            name: "ALISON",
            family: [
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
            ],
            pets: {
                Puppy: 0,
                Dog: 1,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 1.5,
            tierPreferences: [
                "SensitiveSkin",
                "SupplyDropPick",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 3
                },
            ],
            guests: 0,
            dishwashing: {
                frequency: 2,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Other"
            ],
            laundry: {
                frequency: 1,
                preferences: [
                    "Liquid",
                    "FreeAndClear"
                ]
            },
            housekeeping: 2
        },
        // BRYANT
        {
            name: "BRYANT",
            family: [
                {
                    age: 45,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 45,
                    gender: "Female",
                    staysHome: false
                },
            ],
            pets: {
                Puppy: 0,
                Dog: 2,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 4.5,
            tierPreferences: [
                "SupplyDropPick",
                "SensitiveSkin",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 5
                },
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 1
                },
            ],
            guests: 75,
            dishwashing: {
                frequency: 5,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Spring"
            ],
            laundry: {
                frequency: 5,
                preferences: [
                    "Liquid",
                    "FreeAndClear"
                ]
            },
            housekeeping: 5
        },
        // KATIE
        {
            name: "KATIE",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 1,
                    gender: "Female",
                    staysHome: false
                },
            ],
            pets: {
                Puppy: 0,
                Dog: 2,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3,
            tierPreferences: [
                "SensitiveSkin",
                "NationallyRecognizedBrandLead",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 1,
                    frequency: 5
                },
            ],
            guests: 50,
            dishwashing: {
                frequency: 9,
                products: [
                    "Packs"
                ]
            },
            refills: false,
            scentPreferences: [
                "Unscented"
            ],
            laundry: {
                frequency: 8,
                preferences: [
                    "Liquid",
                    "FreeAndClear"
                ]
            },
            housekeeping: 8
        },
    ]

    const others = [
        // TEST1
        {
            name: "TEST1",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 1,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 1,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "NationallyRecognizedBrandLead"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 5
                },
                {
                    type: 'Recyclable',
                    bags: 1,
                    frequency: 3
                },
                {
                    type: 'Compost',
                    bags: 1,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 1
                }
            ],
            guests: 50,
            dishwashing: {
                frequency: 4,
                products: [
                    "Packs"
                ]
            },
            refills: false,
            scentPreferences: [
                "Lavender"
            ],
            laundry: {
                frequency: 5,
                preferences: [
                    "HighEfficiency"
                ]
            },
            housekeeping: 4
        },
        // TEST2
        {
            name: "TEST2",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 1,
                    gender: "Female",
                    staysHome: true
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 2,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "SupplyDropPick"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 5
                },
                {
                    type: 'Recyclable',
                    bags: 1,
                    frequency: 3
                },
                {
                    type: 'Compost',
                    bags: 1,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 1
                }
            ],
            guests: 50,
            dishwashing: {
                frequency: 4,
                products: [
                    "Brush"
                ]
            },
            refills: false,
            scentPreferences: [
                "Lavender"
            ],
            laundry: {
                frequency: 5,
                preferences: [
                    "HighEfficiency"
                ]
            },
            housekeeping: 4
        },
        // TEST3
        {
            name: "TEST3",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 1,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 1,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "EcoFriendly"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 5
                },
                {
                    type: 'Recyclable',
                    bags: 1,
                    frequency: 3
                },
                {
                    type: 'Compost',
                    bags: 1,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 1
                }
            ],
            guests: 50,
            dishwashing: {
                frequency: 4,
                products: [
                    "Packs"
                ]
            },
            refills: false,
            scentPreferences: [
                "Lavender"
            ],
            laundry: {
                frequency: 5,
                preferences: [
                    "HighEfficiency"
                ]
            },
            housekeeping: 4
        },
        // TEST4
        {
            name: "TEST4",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 1,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 2,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "SensitiveSkin"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 5
                },
                {
                    type: 'Recyclable',
                    bags: 1,
                    frequency: 3
                },
                {
                    type: 'Compost',
                    bags: 1,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 1
                }
            ],
            guests: 50,
            dishwashing: {
                frequency: 4,
                products: [
                    "Packs"
                ]
            },
            refills: false,
            scentPreferences: [
                "Lavender"
            ],
            laundry: {
                frequency: 5,
                preferences: [
                    "HighEfficiency"
                ]
            },
            housekeeping: 4
        },
        // TEST5
        {
            name: "TEST5",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 1,
                    gender: "Female",
                    staysHome: true
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "Value"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 5
                },
                {
                    type: 'Recyclable',
                    bags: 1,
                    frequency: 3
                },
                {
                    type: 'Compost',
                    bags: 1,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 1
                }
            ],
            guests: 50,
            dishwashing: {
                frequency: 4,
                products: [
                    "Packs"
                ]
            },
            refills: false,
            scentPreferences: [
                "Lavender"
            ],
            laundry: {
                frequency: 5,
                preferences: [
                    "HighEfficiency"
                ]
            },
            housekeeping: 4
        },
        // TEST6
        {
            name: "TEST6",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "NationallyRecognizedBrandLead",
                "SupplyDropPick"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Sponge"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST7
        {
            name: "TEST7",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "NationallyRecognizedBrandLead",
                "Value"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST8
        {
            name: "TEST8",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "NationallyRecognizedBrandLead",
                "SensitiveSkin"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST9
        {
            name: "TEST9",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 1,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "NationallyRecognizedBrandLead",
                "EcoFriendly"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST10
        {
            name: "TEST10",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 1,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "SupplyDropPick",
                "NationallyRecognizedBrandLead"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST11
        {
            name: "TEST11",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "SupplyDropPick",
                "Value"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Gel"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST12
        {
            name: "TEST12",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: true
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "SupplyDropPick",
                "SensitiveSkin"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST13
        {
            name: "TEST13",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "SupplyDropPick",
                "EcoFriendly"
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST14
        {
            name: "TEST14",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 1,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "SensitiveSkin",
                "NationallyRecognizedBrandLead",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
         // TEST15
         {
            name: "TEST15",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 2,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "SensitiveSkin",
                "SupplyDropPick",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST16
        {
            name: "TEST16",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "SensitiveSkin",
                "EcoFriendly",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST17
        {
            name: "TEST17",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 2,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "SensitiveSkin",
                "Value",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST18
        {
            name: "TEST18",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "Value",
                "NationallyRecognizedBrandLead",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST19
        {
            name: "TEST19",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 1,
                Dog: 2,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "Value",
                "SupplyDropPick",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST20
        {
            name: "TEST20",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "Value",
                "EcoFriendly",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST21
        {
            name: "TEST21",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 12,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 18,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 5.5,
            tierPreferences: [
                "Value",
                "SensitiveSkin",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 7
                },
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 2,
                    frequency: 2
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 100,
            dishwashing: {
                frequency: 7,
                products: [
                    "Packs"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 10,
                preferences: [
                    "Liquid"
                ]
            },
            housekeeping: 8
        },
        // TEST22
        {
            name: "TEST22",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 0,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 1,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "NationallyRecognizedBrandLead",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 5
                },
                {
                    type: 'Outdoor',
                    bags: 2,
                    frequency: 2
                }
            ],
            guests: 25,
            dishwashing: {
                frequency: 2,
                products: [
                    "Gel"
                ]
            },
            refills: true,
            scentPreferences: [
                "Unscented"
            ],
            laundry: {
                frequency: 0,
                preferences: [
                    "Packs"
                ]
            },
            housekeeping: 8
        },
        // TEST23
        {
            name: "TEST23",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 0,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "SupplyDropPick",
            ],
            trashPreferences: [
                {
                    type: 'Recyclable',
                    bags: 2,
                    frequency: 10
                },
                {
                    type: 'Compost',
                    bags: 1,
                    frequency: 5
                }
            ],
            guests: 75,
            dishwashing: {
                frequency: 2,
                products: [
                    "Liquid"
                ]
            },
            refills: false,
            scentPreferences: [
                "Unscented"
            ],
            laundry: {
                frequency: 0,
                preferences: [
                    "FabricSoftener"
                ]
            },
            housekeeping: 1
        },
        // TEST24
        {
            name: "TEST24",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 0,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 1,
                Dog: 1,
                Cat: 1,
                Kitten: 1,
                Other: 1
            },
            bathrooms: 3.5,
            tierPreferences: [
                "EcoFriendly",
            ],
            trashPreferences: [
                {
                    type: 'Compost',
                    bags: 4,
                    frequency: 1
                },
                {
                    type: 'Outdoor',
                    bags: 10,
                    frequency: 1
                },
            ],
            guests: 25,
            dishwashing: {
                frequency: 0,
                products: [
                    "Liquid"
                ]
            },
            refills: true,
            scentPreferences: [
                "Spring"
            ],
            laundry: {
                frequency: 0,
                preferences: [
                    "Packs"
                ]
            },
            housekeeping: 40
        },
        // TEST25
        {
            name: "TEST25",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 0,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "SensitiveSkin",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 5
                },
            ],
            guests: 0,
            dishwashing: {
                frequency: 40,
                products: [
                    "Gel"
                ]
            },
            refills: true,
            scentPreferences: [
                "Unscented"
            ],
            laundry: {
                frequency: 50,
                preferences: [
                    "HighEfficiency"
                ]
            },
            housekeeping: 4
        },
        // TEST 26
        {
            name: "TEST26",
            family: [
                {
                    age: 25,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 4,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 25,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 0,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 3.5,
            tierPreferences: [
                "Value",
            ],
            trashPreferences: [
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 20
                },
            ],
            guests: 100,
            dishwashing: {
                frequency: 9,
                products: [
                    "Sponge"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 5,
                preferences: [
                    "Packs"
                ]
            },
            housekeeping: 4
        },
        // TEST 27
        {
            name: "TEST27",
            family: [
                {
                    age: 0,
                    gender: "Male",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 1,
            tierPreferences: [
                "NationallyRecognizedBrandLead",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 1,
                    frequency: 1
                },
            ],
            guests: 50,
            dishwashing: {
                frequency: 0,
                products: [
                    "Sponge"
                ]
            },
            refills: true,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 1,
                preferences: [
                    "Packs"
                ]
            },
            housekeeping: 0
        },
        // TEST 28
        {
            name: "TEST28",
            family: [
                {
                    age: 25,
                    gender: "Female",
                    staysHome: true
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 0,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 2.5,
            tierPreferences: [
                "Value",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 2,
                    frequency: 1
                },
                {
                    type: 'Recyclable',
                    bags: 1,
                    frequency: 1
                },
                {
                    type: 'Compost',
                    bags: 1,
                    frequency: 1
                },
                {
                    type: 'Outdoor',
                    bags: 1,
                    frequency: 1
                },
            ],
            guests: 0,
            dishwashing: {
                frequency: 3,
                products: [
                    "Liquid",
                    "Gel",
                    "Packs"
                ]
            },
            refills: false,
            scentPreferences: [
                "Unscented"
            ],
            laundry: {
                frequency: 3,
                preferences: [
                    "FreeAndClear"
                ]
            },
            housekeeping: 4
        },
        // TEST 29
        {
            name: "TEST29",
            family: [
                {
                    age: 2,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 2,
                    gender: "Female",
                    staysHome: false
                }
            ],
            pets: {
                Puppy: 0,
                Dog: 2,
                Cat: 0,
                Kitten: 0,
                Other: 0
            },
            bathrooms: 2,
            tierPreferences: [
                "EcoFriendly",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 10,
                    frequency: 1
                },
            ],
            guests: 100,
            dishwashing: {
                frequency: 8,
                products: [
                    "Gel"
                ]
            },
            refills: false,
            scentPreferences: [
                "Earthy"
            ],
            laundry: {
                frequency: 4,
                preferences: [
                    "HighEfficiency"
                ]
            },
            housekeeping: 4
        },
        // TEST 30
        {
            name: "TEST30",
            family: [
                {
                    age: 2,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 2,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 8,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 8,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 14,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 14,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 20,
                    gender: "Male",
                    staysHome: true
                },
                {
                    age: 20,
                    gender: "Female",
                    staysHome: true
                },
                {
                    age: 28,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 28,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 48,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 48,
                    gender: "Female",
                    staysHome: false
                },
                {
                    age: 68,
                    gender: "Male",
                    staysHome: false
                },
                {
                    age: 68,
                    gender: "Female",
                    staysHome: false
                },
            ],
            pets: {
                Puppy: 1,
                Dog: 1,
                Cat: 1,
                Kitten: 1,
                Other: 1
            },
            bathrooms: 10,
            tierPreferences: [
                "NationallyRecognizedBrandLead",
                "SupplyDropPick",
            ],
            trashPreferences: [
                {
                    type: 'Kitchen',
                    bags: 5,
                    frequency: 5
                },
                {
                    type: 'Compost',
                    bags: 5,
                    frequency: 5
                },
                {
                    type: 'Recyclable',
                    bags: 5,
                    frequency: 5
                },
                {
                    type: 'Outdoor',
                    bags: 5,
                    frequency: 5
                },
            ],
            guests: 100,
            dishwashing: {
                frequency: 15,
                products: [
                    "Gel"
                ]
            },
            refills: true,
            scentPreferences: [
                "Spring"
            ],
            laundry: {
                frequency: 15,
                preferences: [
                    "Packs"
                ]
            },
            housekeeping: 12
        },
    ]

    demographics.forEach(demo => {
        demo.self = demo.family[0];
    });

    others.forEach(o => {
        o.self = o.family[0];
    })

    return [...demographics, ...others];
}

export default Demographics;