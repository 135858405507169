import React, {Component} from 'react';
import {connect} from 'react-redux';
import Header from '../Header';
import moment from 'moment';
import {
    getMonthaversaries,
    getCustomers,
    createRecurringOrder,
    clearOrderState
} from '../../actions';
import { Loading, ActionsModal } from '../Common';

class Monthaversaries extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderContactAtArrow = this.renderContactAtArrow.bind(this);
        this.sortByContactAt = this.sortByContactAt.bind(this);
        this.sortByShipAt = this.sortByShipAt.bind(this);
        this.sortByDeliverAt = this.sortByDeliverAt.bind(this);
        this.sortByStatus = this.sortByStatus.bind(this);
        this.sortByCadence = this.sortByCadence.bind(this);
        this.sortByIssues = this.sortByIssues.bind(this);
    }

    componentDidMount() {
        this.props.getMonthaversaries();
        this.props.getCustomers();
    }

    componentDidUpdate() {
        const {manifests, customers} = this.props;
        if(manifests && !this.state.manifests) {
            manifests.sort((a,b) => {
                if(a.contactAt < b.contactAt) { return -1; }
                if(a.contactAt > b.contactAt) { return 1; }
                return 0;
            })
            this.setState({manifests});
        }

        if(customers && !this.state.customersMap) {
            const customersMap = {};
            customers.forEach(customer => {
                const id = customer.customerId;
                customersMap[id] = {};
                Object.entries(customer).forEach(entries => {
                    customersMap[id][entries[0]] = entries[1];
                })

            });
            this.setState({customersMap})
        }
    }

    sortByContactAt() {
        let {manifests, sortByContactAt} = this.state;
        if(sortByContactAt) {
            manifests.sort((a,b) => {
                if(a.contactAt < b.contactAt) { return -1; }
                if(a.contactAt > b.contactAt) { return 1; }
                return 0;
            });
        } else {
            manifests.sort((a,b) => {
                if(a.contactAt < b.contactAt) { return 1; }
                if(a.contactAt > b.contactAt) { return -1; }
                return 0;
            })
        }
        this.setState({
            manifests,
            sortByContactAt: !sortByContactAt,
            sortByIssues: undefined,
            sortByShipAt: undefined,
            sortByDeliverAt: undefined,
            sortByStatus: undefined,
            sortByCadence: undefined
        });
    }
    
    sortByCadence() {
        let {manifests, sortByCadence} = this.state;
        if(sortByCadence) {
            manifests.sort((a,b) => {
                if(a.cadenceWeeks < b.cadenceWeeks) { return -1; }
                if(a.cadenceWeeks > b.cadenceWeeks) { return 1; }
                return 0;
            });
        } else {
            manifests.sort((a,b) => {
                if(a.cadenceWeeks < b.contcadenceWeeksctAt) { return 1; }
                if(a.cadenceWeeks > b.cadenceWeeks) { return -1; }
                return 0;
            })
        }
        this.setState({
            manifests,
            sortByCadence: !sortByCadence,
            sortByContactAt: undefined,
            sortByIssues: undefined,
            sortByShipAt: undefined,
            sortByDeliverAt: undefined,
            sortByStatus: undefined,
        });
    }

    sortByShipAt() {
        let {manifests, sortByShipAt} = this.state;
        if(sortByShipAt) {
            manifests.sort((a,b) => {
                if(a.shipAt < b.shipAt) { return -1; }
                if(a.shipAt > b.shipAt) { return 1; }
                return 0;
            });
        } else {
            manifests.sort((a,b) => {
                if(a.shipAt < b.shipAt) { return 1; }
                if(a.shipAt > b.shipAt) { return -1; }
                return 0;
            })
        }
        this.setState({
            manifests,
            sortByShipAt: !sortByShipAt,
            sortByContactAt: undefined,
            sortByIssues: undefined,
            sortByDeliverAt: undefined,
            sortByStatus: undefined,
            sortByCadence: undefined
        });
    }

    sortByDeliverAt() {
        let {manifests, sortByDeliverAt} = this.state;
        if(sortByDeliverAt) {
            manifests.sort((a,b) => {
                if(a.deliverAt < b.deliverAt) { return -1; }
                if(a.deliverAt > b.deliverAt) { return 1; }
                return 0;
            });
        } else {
            manifests.sort((a,b) => {
                if(a.deliverAt < b.deliverAt) { return 1; }
                if(a.deliverAt > b.deliverAt) { return -1; }
                return 0;
            })
        }
        this.setState({
            manifests,
            sortByDeliverAt: !sortByDeliverAt,
            sortByIssues: undefined,
            sortByContactAt: undefined,
            sortByShipAt: undefined,
            sortByStatus: undefined,
            sortByCadence: undefined
        });
    }

    sortByStatus() {
        let {manifests, sortByStatus} = this.state;
        if(sortByStatus) {
            manifests.sort((a,b) => {
                if(a.status < b.status) { return -1; }
                if(a.status > b.status) { return 1; }
                return 0;
            });
        } else {
            manifests.sort((a,b) => {
                if(a.status < b.status) { return 1; }
                if(a.status > b.status) { return -1; }
                return 0;
            })
        }
        this.setState({
            manifests,
            sortByStatus: !sortByStatus,
            sortByIssues: undefined,
            sortByDeliverAt: undefined,
            sortByContactAt: undefined,
            sortByShipAt: undefined,
            sortByCadence: undefined
        });
    }

    sortByIssues() {
        let {manifests, sortByIssues} = this.state;
        if(sortByIssues) {
            manifests.sort((a,b) => {
                if(a.issues.length < b.issues.length) { return -1; }
                if(a.issues.length > b.issues.length) { return 1; }
                return 0;
            });
        } else {
            manifests.sort((a,b) => {
                if(a.issues.length < b.issues.length) { return 1; }
                if(a.issues.length > b.issues.length) { return -1; }
                return 0;
            })
        }
        this.setState({
            manifests,
            sortByIssues: !sortByIssues,
            sortByStatus: undefined,
            sortByDeliverAt: undefined,
            sortByContactAt: undefined,
            sortByShipAt: undefined,
            sortByCadence: undefined
        });
    }

    renderMonthaversaries() {
        const {manifests, customersMap} = this.state;
        if(manifests && customersMap) {
            const rows = [];
            manifests.forEach(manifest => {
                if(customersMap[manifest.customerId]) {
                    const {status, viewed, modified, shipAt, deliverAt, contactAt, customerId, cadenceWeeks} = manifest;
                    const color = manifest.status.toUpperCase() === "CONTACTED" ? 'green' : '';
                    const issues = [];
                    if(manifest.issues && manifest.issues.length) {
                        manifest.issues.forEach(issue => {
                            issues.push(
                                <li>{issue}</li>
                            )
                        })
                    }
                    const todayOrSooner = moment(shipAt).valueOf() <= moment().valueOf();
                    const retryButton = () => {
                        if(todayOrSooner)
                            return <div onClick={() => this.props.createRecurringOrder(customerId)} className="btn-primary">Retry failed order?</div>
                    }

                    rows.push(
                        <tr>
                            <th className={"blue"}><a href={`/customers/${customerId}`}>{customersMap[customerId].firstName} {customersMap[customerId].lastName}<br/>{customerId}</a></th>
                            <th className={color}>{contactAt ? moment.utc(contactAt).format('ddd, MMM D,YYYY') : "Unknown"}</th>
                            <th className={color}>{cadenceWeeks}</th>
                            <th className={todayOrSooner ? "red" : color}>
                                {moment.utc(shipAt).format('ddd, MMM D,YYYY')}
                                {retryButton()}
                            </th>
                            <th className={color}>{moment.utc(deliverAt).format('ddd, MMM D,YYYY')}</th>
                            <th className={color}>{status.toUpperCase()}</th>
                            <th className={issues.length ? "red" : color}>{issues.length ? <ul>{issues}</ul> : ""}</th>
                            <th>
                                Modified: {JSON.stringify(modified)}<br/>
                                Viewed: {JSON.stringify(viewed)}
                            </th>
                            <th className={color}>{customersMap[customerId].createdAt ? moment.utc(customersMap[customerId].createdAt).local().format('lll') : "Unknown"}</th>
                        </tr>
                    )
                } else {
                    console.error("Unknown customerId", manifest.customerId)
                }
            })
            return (
                <div className="table-wrapper">
                    <p><i>*Orders will continue to show as "failed" even after successful recreation</i></p>
                    <h2>Upcoming Monthaversaries</h2>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th onClick={this.sortByContactAt} className="pointer">Contact Date {this.renderContactAtArrow()}</th>
						        <th className="pointer" onClick={this.sortByCadence}>Cadence Weeks{this.renderCadenceArrow()}</th>
                                <th onClick={this.sortByShipAt} className="pointer">Ship Date {this.renderShipAtArrow()}</th>
                                <th onClick={this.sortByDeliverAt} className="pointer">Delivery Date {this.renderDeliverAtArrow()}</th>
                                <th onClick={this.sortByStatus} className="pointer">Status {this.renderStatusArrow()}</th>
                                <th onClick={this.sortByIssues} className="pointer">Issues {this.renderIssuesArrow()}</th>
                                <th className="pointer">Viewed/Modified</th>
                                <th>Customer Since</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return (
                <Loading />
            )
        }
    }

    renderCadenceArrow() {
		const {sortByCadence} = this.state;
		if(sortByCadence === false) {
            return (<span>&uarr;</span>)
        } else if (sortByCadence) {
            return (<span>&darr;</span>)
        } 
	}

    renderContactAtArrow() {
        const {sortByContactAt} = this.state;
        if(sortByContactAt === false) {
            return (<span>&uarr;</span>)
        } else if (sortByContactAt) {
            return (<span>&darr;</span>)
        } 
    }

    renderShipAtArrow() {
        const {sortByShipAt} = this.state;
        if(sortByShipAt === false) {
            return (<span>&uarr;</span>)
        } else if (sortByShipAt) {
            return (<span>&darr;</span>)
        } 
    }

    renderIssuesArrow() {
        const {sortByIssues} = this.state;
        if(sortByIssues === false) {
            return (<span>&uarr;</span>)
        } else if (sortByIssues) {
            return (<span>&darr;</span>)
        } 
    }

    renderDeliverAtArrow() {
        const {sortByDeliverAt} = this.state;
        if(sortByDeliverAt === false) {
            return (<span>&uarr;</span>)
        } else if (sortByDeliverAt) {
            return (<span>&darr;</span>)
        } 
    }

    renderStatusArrow() {
        const {sortByStatus} = this.state;
        if(sortByStatus === false) {
            return (<span>&uarr;</span>)
        } else if (sortByStatus) {
            return (<span>&darr;</span>)
        } 
    }

    renderRecurringModal() {
        const {creatingRecurring, recurringSuccess, recurringError} = this.props;
        if(creatingRecurring || recurringSuccess || recurringError) {
            return (
                <ActionsModal
                    close={this.props.clearOrderState}
                    success={recurringSuccess}
                    error={recurringError}
                    updating={creatingRecurring}
                />
            )
        }
    }

    render() {
        return (
            <div>
                <Header />
                <div className="sdrop-body flex-column short">
                    {this.renderMonthaversaries()}
                    {this.renderRecurringModal()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {manifests, error, creatingRecurring, recurringSuccess, recurringError} = state.orders;
    const {customers} = state.customers;
    return {manifests, customers, error, creatingRecurring, recurringSuccess, recurringError};
}

export default connect(mapStateToProps, {
    getMonthaversaries,
    getCustomers,
    createRecurringOrder,
    clearOrderState
})(Monthaversaries);