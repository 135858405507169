import React, {Component} from 'react';
import SignIn from './SignIn/SignIn';
import {
    getCustomers,
    getOrdersPendingShipment,
    getOrdersPendingApproval,
    clearUnauthed
} from '../actions';
import {connect} from 'react-redux';
import Header from './Header';
import moment from 'moment';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeCount: 0,
            inactiveCount: 0,
            abandonedCount: 0,
            pausedCount: 0,
            shippingToday: 0,
            recentSignUps: {},
            ordersMap: {},
            shippingMap: {}
        };

        this.props.getCustomers();
        this.props.getOrdersPendingApproval();
        this.props.getOrdersPendingShipment();

        this.setSignedIn = this.setSignedIn.bind(this);
    }

    componentDidUpdate() {
        const {customers, shippingOrders, approvalOrders} = this.props;
        let {activeCount, inactiveCount, abandonedCount, pausedCount, recentSignUps, shippingToday} = this.state;
        const last24 = new Date();
        last24.setDate(last24.getDate()-1)
        if(customers && !this.state.customers) {
            customers.sort((a,b) => {
                if(a.createdAt > b.createdAt) { return -1; }
                if(a.createdAt < b.createdAt) { return 1; }
                return 0;
            })
            
            customers.sort((a,b) => {
                if(a.status === "Active" && b.status !== "Active") { return -1; }
                if(a.status !== "Active" && b.status === "Active")  { return 1; }
                return 0;
            })
            customers.forEach(customer => {
                if(customer.status !== "Active") {
                    if(customer.status === "Created") abandonedCount++;
                    else if (customer.status === "Paused") pausedCount++;
                    else inactiveCount++;
                    customers.push(customers.splice(customers.indexOf(customer), 1)[0]);
                } else {
                    activeCount++;
                    if(moment.utc(customer.createdAt).local().isSameOrAfter(last24, 'second'))
                        recentSignUps[customer.customerId] = customer;
                }
            })
            this.setState({customers, inactiveCount, abandonedCount, pausedCount, activeCount, recentSignUps});
        }


        if (approvalOrders && !this.state.approvalOrders) {
            let ordersMap = {};
            const orders = approvalOrders;
            orders.forEach(order => {
                if(moment.utc(order.expectedShipDate).format('l') === moment(last24).format('l')) {
                    shippingToday ++;
                }
                ordersMap[order.orderId] = {
                    orderId: order.orderId,
                    customerId: order.customerId,
                    expectedShipDate: moment.utc(order.expectedShipDate).format('ll'),
                    deliveryDate: moment.utc(order.expectedDeliveryDate).format('ll'),
                    createdDate: moment.utc(order.createdAt).local().format('lll'),
                    deliveryMethod: order.deliveryMethod,
                    netSuite: order.netSuite,
                    //TODO: Loop through products to show product counts
                    interviewId: order.interviewId,
                    products: order.products

                };
            });
            this.setState({ ordersMap, orders, shippingToday, approvalOrders });
        } 

        if (shippingOrders && !this.state.shippingOrders) {
            let shippingMap = {};
            shippingOrders.forEach(order => {
                if(moment.utc(order.expectedShipDate).format('l') === moment().format('l')) {
                    shippingToday ++;
                }
                shippingMap[order.orderId] = {
                    orderId: order.orderId,
                    customerId: order.customerId,
                    expectedShipDate: moment.utc(order.expectedShipDate).format('ll'),
                    deliveryDate: moment.utc(order.expectedDeliveryDate).format('ll'),
                    createdDate: moment.utc(order.createdAt).local().format('lll'),
                    deliveryMethod: order.deliveryMethod,
                    netSuite: order.netSuite,
                    //TODO: Loop through products to show product counts
                    interviewId: order.interviewId,
                    products: order.products
                };
            });
            this.setState({ shippingMap, shippingOrders,shippingToday });
        } 
    }

    setSignedIn() {
        this.props.clearUnauthed();
        this.setState({signedIn: true})
    }

    renderQuickStats() {
        const {activeCount, inactiveCount, pausedCount, abandonedCount, recentSignUps, shippingMap, ordersMap, customers, shippingToday} = this.state;
        return (
            <div id="quick-stats">
                <div onClick={() => {this.props.history.push({
                    pathname: '/customers',
                    state: {customers, inactiveCount, activeCount, pausedCount}
                })}}>Active Customers: <b>{activeCount}</b></div>
                <div className="gold" onClick={() => {this.props.history.push({
                    pathname: '/customers',
                    state: {customers, inactiveCount, activeCount, pausedCount}
                })}}>Abandoned Customers: <b>{abandonedCount}</b></div>
                <div className="blue" onClick={() => {this.props.history.push({
                    pathname: '/customers',
                    state: {customers, inactiveCount, activeCount, pausedCount}
                })}}>Paused Customers: <b>{pausedCount}</b></div>
                <div className="red" onClick={() => {this.props.history.push({
                    pathname: '/customers',
                    state: {customers, inactiveCount, activeCount, pausedCount}
                })}}>Inactive Customers: <b>{inactiveCount}</b></div>
                <div onClick={() => {this.props.history.push({
                    pathname: '/customers',
                    state: {customers, inactiveCount, activeCount, pausedCount}
                })}}>Signups in the last 24: <b>{Object.keys(recentSignUps).length}</b></div>
                <div onClick={() => {this.props.history.push({
                    pathname: '/orders',
                    state: {ordersMap, shippingMap}
                })}}>Orders Pending Approval: <b>{Object.keys(ordersMap).length}</b></div>
                <div onClick={() => {this.props.history.push({
                    pathname: '/orders',
                    state: {ordersMap, shippingMap},
                    search: "?focus=shipping"
                })}}>Orders Pending Shipping: <b>{Object.keys(shippingMap).length}</b></div>
                <div onClick={() => {this.props.history.push({
                    pathname: '/orders',
                    state: {ordersMap, shippingMap},
                    search: "?focus=shipping"
                })}}>Orders Shipping Today: <b>{shippingToday}</b></div>
            </div>
        )
    }

    renderMondayPayments() {
        return (
            <div id="monday-payments-container"><iframe title="Monday" src="https://view.monday.com/embed/456567384-2d0397889b7efa7e128d532475893438" width="750" height="500"></iframe></div>
        )
    }

    render() {
        const {signedIn} = this.state;
        if(!signedIn) {
            return (<SignIn setSignedIn={this.setSignedIn} signedIn={signedIn} />)
        } else {
            return (
                <div>
                    <Header />
                    <div id="home-container">
                        {this.renderMondayPayments()}
                        {this.renderQuickStats()}
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const { customers, customer, error } = state.customers;
    const { shippingOrders, approvalOrders } = state.orders;
    return { customers, customer, error, shippingOrders, approvalOrders };
  }
  
  export default connect(mapStateToProps, {
    getCustomers,
    getOrdersPendingShipment,
    getOrdersPendingApproval,
    clearUnauthed
  })(Home);