import React, { Component } from 'react';
import Header from '../Header'
import {
    isAdmin
} from '../../actions';
import {Row, Col, Container} from 'react-bootstrap';
import {Auth} from 'aws-amplify';
import './qrgenerator.css';
import { QRCode } from 'react-qrcode-logo';

class QRGenerator extends Component {

    constructor(props) {
        super(props);

        this.state = {
          qrURL: "https://supplydrop.com/dashboard",
          qrIcon: "https://images.ctfassets.net/qhwoiitgc21a/6DSrQfYgSoedzjIr3GAm2s/f69781f6789f8579874b1edffa4c8afb/icon-supplydrop-clouds.png",
          qrfgcolor: "#000000",
          qrbgcolor: "#FFFFFF",
          qrsize: 200,
        };
    }

    componentDidUpdate() {
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(data => {
            const jwt = data.signInUserSession.idToken.jwtToken;
            const authed = isAdmin(jwt);
            if(!authed) {
                this.props.history.push('/');
            }
        }).catch(err => {
            this.props.history.push('/');
        })
    }

    render() {
        let {
          qrURL,
          qrIcon,
          qrfgcolor,
          qrbgcolor,
          qrsize,
        } = this.state;
        return (
            <div>
                <Header />
                <Container className="qr-generator-body">
                    <Row className="flex-row flex-space-around">
                        <Col xs={6}>
                            <Row>
                              <label for="qrurl"><b>QR URL:</b></label>
                              <textarea
                                  id="qrurl"
                                  rows="3"
                                  cols="60"
                                  value={qrURL}
                                  onChange={(event) => this.setState({qrURL: event.target.value})}
                              />
                            </Row>
                            <Row>
                              <label for="qricon"><b>QR Icon:</b></label>
                              <textarea
                                  id="qricon"
                                  rows="1"
                                  cols="60"
                                  value={qrIcon}
                                  onChange={(event) => this.setState({qrIcon: event.target.value})}
                              />
                            </Row>
                            <Row>
                              <label for="qrfgcolor"><b>QR Color:</b></label>
                              <input
                                  id="qrfgcolor"
                                  type="text"
                                  value={qrfgcolor}
                                  onChange={(event) => this.setState({qrfgcolor: event.target.value})}
                              />
                            </Row>
                            <Row>
                              <label for="qrbgcolor"><b>QR Background Color:</b></label>
                              <input
                                  id="qrbgcolor"
                                  type="text"
                                  value={qrbgcolor}
                                  onChange={(event) => this.setState({qrbgcolor: event.target.value})}
                              />
                            </Row>
                            <Row>
                              <label for="qrsize"><b>QR Size:</b></label>
                              <input
                                  id="qrsize"
                                  type="text"
                                  value={qrsize}
                                  onChange={(event) => this.setState({qrsize: event.target.value})}
                              />
                            </Row>
                            <Row>
                              <QRCode 
                                size={qrsize}
                                logoOpacity={1.0}
                                logoWidth={qrsize*0.3}
                                logoHeight={qrsize*0.3}
                                value={qrURL}
                                logoImage={qrIcon}
                                fgColor={qrfgcolor}
                                bgColor={qrbgcolor}
                              />
                            </Row>
                            <Row>
                              <a href={qrURL}>Link</a>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

        );
    }
}

export default QRGenerator;
