import React, { Component } from 'react';
import images from '../../assets/images';
import './addsimulatorfamily.css'

class AddSimulatorHousehold extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTypeMember: this.props.editFactor ? this.props.editFactor.type : 'Male',
            age: 0,
            editFactor: this.props.editFactor
        }

        this.onSaveClick = this.onSaveClick.bind(this);
    }

    onMemberSelect(type) {
        this.setState({ selectedTypeMember: type });
    }

    onSaveClick() {
        const {
            selectedTypeMember,
            age,
            staysHome
        } = this.state;

        this.props.onSaveClick({
            id: new Date().getTime(),
            gender: selectedTypeMember,
            age: parseInt(age),
            staysHome
        })
    }

    setAge(age) {
        if(parseInt(age) > 125) age = 125;

        this.setState({age: parseInt(age)})
    }


    render() {
        const { age, staysHome } = this.state;

        return (
            <div className="modal-container">
                <div className="alert-dialog-medium flex-column text-center">
                    <div className="dialog-title-text">
                        Add to Family
                    </div>
                    <div className="dialog-body-add-factor">
                        <div className="select-family-member">
                            Select Family Member
                        </div>
                        <div className="family-picker-wrapper">
                            <div className="interview-item">
                                <div className="rounded-wrapper" onClick={() => this.onMemberSelect('Male')} >
                                    <img className="card-main-image" src={images.maleIcon} alt="man"/>
                                    {
                                        this.state.selectedTypeMember === 'Male' && 
                                        <img className="member-check-image" src={images.checkCircle} alt="Add Male" />
                                    }
                                </div>
                            </div>
        
                            <div className="interview-item">
                                <div className="rounded-wrapper" onClick={() => this.onMemberSelect('Female')} >
                                    <img className="card-main-image" src={images.femaleIcon} alt="woman" />
                                    {
                                        this.state.selectedTypeMember === 'Female' && 
                                        <img className="member-check-image" src={images.checkCircle} alt="Add Female" />
                                    }
                                </div>
                            </div>                            
                            <div className="age-wrapper">
                                Age:
                                <div className="age-input-wrapper">
                                    <input
                                        className="editable-cell age-input"
                                        value={age}
                                        type="number"
                                        max="125"
                                        min="0"
                                        onChange={(event) => this.setAge(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="age-wrapper">
                                Stays Home:
                                <div className="age-input-wrapper">
                                    <input
                                        className="editable-cell age-input"
                                        value={staysHome}
                                        type="checkbox"
                                        onChange={() => this.setState({ staysHome: !staysHome })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-row flex-space-between button-wrapper">
                        <button className="btn-success dialog-btn-ok" onClick={this.onSaveClick}>Add</button>
                        <button className="btn-primary dialog-btn-ok" onClick={this.props.onCancelClick}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddSimulatorHousehold;
