import React, {Component} from 'react';
import Header from '../Header';
import CustomerNav from './CustomerNav';
import Details from './Details';
import {Col, Alert} from 'react-bootstrap';
import {connect} from 'react-redux';
import {getProductGroups, formatProductType, putManifest, getManifest, getCustomer} from '../../actions';
import { Loading } from '../Common';

class Manifest extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.sendOrder = this.sendOrder.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.clearProduct = this.clearProduct.bind(this);
        this.editProduct = this.editProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const { customerId } = params;

        this.props.getProductGroups();
        this.props.getManifest(customerId);
        this.props.getCustomer(customerId);
        this.setState({customerId})
    }

    componentDidUpdate() {
        const {productGroups, manifestRes, puttingManifest, putManifestSuccess, customer} = this.props;
        if(productGroups && !this.state.productGroups) {
            const productGroupsMap = {};
            productGroups.forEach(pg => {
                productGroupsMap[pg.groupName] = {};
                productGroupsMap[pg.groupName].groupName = pg.groupName;
                productGroupsMap[pg.groupName].brandName = pg.brandName;
                productGroupsMap[pg.groupName].displayName = pg.displayName;
                productGroupsMap[pg.groupName].pricePerArticle = pg.pricePerArticle;
                productGroupsMap[pg.groupName].articleType = pg.articleType;
                productGroupsMap[pg.groupName].badges = pg.badges;
                productGroupsMap[pg.groupName].articles = pg.possibleArticleCounts[0];
                productGroupsMap[pg.groupName].imageUrl = pg.imageUrl;
                productGroupsMap[pg.groupName].description = pg.description;
                productGroupsMap[pg.groupName].productType = pg.productType;
                productGroupsMap[pg.groupName].possibleArticleCounts = pg.possibleArticleCounts;
                productGroupsMap[pg.groupName].status = pg.status
            })
            this.setState({productGroups, productGroupsMap});
        }

        if(manifestRes && !this.state.manifest && this.state.productGroupsMap) {
            const {manifest, allowedDeliveryDates} = manifestRes;
            this.setState({manifest, allowedDeliveryDates});
            this.setOptions(manifest, this.state.productGroupsMap, productGroups)
        }

        if(puttingManifest && !this.state.puttingManifest) {
            this.setState({puttingManifest: true})
        }

        if(this.state.puttingManifest && putManifestSuccess) {
            setTimeout(() => {
                this.setState({puttingManifest: false})
            }, 3000)
        }
        if(customer && !this.state.customer) {
            this.setState({customer})
        }
    }
    
    editProduct(groupName) {
        const {productGroupsMap} = this.state;
        this.setState({selectedProduct: groupName, isAdding: true, errMessage: "", newQuantity: productGroupsMap[groupName].articles})
    }

    removeProduct(productType) {
        const {products} = this.state;
        if(products[productType]) {
            delete products[productType]
            this.setState({products});
        } else {
            console.error(`Product not found for product type of ${productType}`)
        }
    }

    renderProductRows() {
        const {products, customer} = this.state;
        let rows = [];
        if(products && customer) {
            let subtotal = 0;
            rows = Object.values(products).map(product => {
                const {brandName, displayName, articles, articleType, pricePerArticle, productType, groupName} = product;
                subtotal += articles * pricePerArticle/100;
                return (
                    <tr>
                        <th>{formatProductType(productType)}</th>
                        <th>{brandName} {displayName}</th>
                        <td>{articles} {articles === 1 ? articleType : articleType + (articleType.toLowerCase() === 'box' ? 'es' : 's')}</td>
                        <td className="text-right">${(articles * pricePerArticle/100).toFixed(2)}</td>
                        <th onClick={() => this.removeProduct(productType)} className="red pointer text-right">Remove</th>
                        <th onClick={() => this.editProduct(groupName)} className="blue">Edit</th>
                    </tr>
                )
            })
            const tax = customer.shipping.state === "TX" ? subtotal * 0.0825 : 0;
            rows.push(
                <tr>
                    <td></td>
                    <td></td>
                    <th>Subtotal:</th>
                    <th className="text-right">${subtotal.toFixed(2)}</th>
                    <td></td>
                    <td></td>
                </tr>
            )
            rows.push(
                <tr>
                    <td></td>
                    <td></td>
                    <th>Estimated Tax:</th>
                    <th className="text-right">${tax.toFixed(2)}</th>
                    <td></td>
                    <td></td>
                </tr>
            )
            rows.push(
                <tr>
                    <td></td>
                    <td></td>
                    <th>Total:</th>
                    <th className="text-right">${(subtotal + tax).toFixed(2)}</th>
                    <td></td>
                    <td></td>
                </tr>
            )
        }
        return rows;
    }

    onSaveClick() {
        const {selectedProduct, productGroupsMap, products, newQuantity} = this.state;
        if(newQuantity !== undefined)
            productGroupsMap[selectedProduct].articles = newQuantity;
        
            const product = productGroupsMap[selectedProduct];
        products[product.productType] = product;
        this.setState({products, isAdding: false, selectedProduct: undefined, newQuantity: undefined})
    }

    sendOrder() {
        const {products, customerId, manifest} = this.state;
        const order = {}
        Object.values(products).forEach(product => {
            order[product.groupName] = {articleCount: product.articles};
            if(manifest.products[product.productType]) {
                if(manifest.products[product.productType].groupName !== product.groupName)
                    order[product.groupName].productChangeReason = 'AdminChanged';
                else if(product.articles !== manifest.products[product.productType].articleCount)
                    order[product.groupName].quantityChangeReason = 'AdminChanged';    
            }
        })

        this.props.putManifest(customerId, order)
    }

    setOptions(manifest, productGroupsMap) {
        try {
            const products = {};

            Object.entries(manifest.products).forEach(entry => {
                const {groupName, articleCount} = entry[1];
                productGroupsMap[groupName].articles = articleCount;
                if(!productGroupsMap[groupName].possibleArticleCounts.includes(articleCount)) {
                    productGroupsMap[groupName].possibleArticleCounts.push(articleCount);
                    productGroupsMap[groupName].possibleArticleCounts.sort((a,b) => {
                        return a - b;
                    });
                    const counts = productGroupsMap[groupName].possibleArticleCounts;
                    const lastIndex = counts[counts.length - 1]
                    const lowestVal = counts[0] ? counts[0] : counts[1];
                    if(articleCount === lastIndex && articleCount > lowestVal) {
                        for (let i = 2; i < articleCount; i++) {
                            if(articleCount > lowestVal * i) {
                                if(!counts.includes(lowestVal * i)) {
                                    productGroupsMap[groupName].possibleArticleCounts.push(lowestVal * i);
                                }
                            } else break;
                        }
                        productGroupsMap[groupName].possibleArticleCounts.sort((a,b) => {
                            return a - b;
                        });
                    }
                }
                products[entry[0]] = productGroupsMap[groupName];
            });    
            sessionStorage.setItem('productGroupsMap', JSON.stringify(productGroupsMap));
            this.setState({productGroupsMap, products});
        } catch(err) {
            console.error(err);
        }
    }

    clearProduct() {
        this.setState({isAdding: false, selectedProduct: undefined, errMessage: "", newQuantity: undefined})
    }

    increment() {
        const {productGroupsMap, selectedProduct, newQuantity} = this.state;
        try {
            let quantity;
            const product = productGroupsMap[selectedProduct];
            if(newQuantity !== undefined) 
                quantity = newQuantity;
            else
                quantity = product.articles;
            const packages = product.possibleArticleCounts;
            const index = packages.indexOf(quantity);
            if(packages.length <= index + 1 || index === -1) {
                if(packages[0])
                    quantity += packages[0];
                else if (packages[1])
                    quantity += packages[1];
                else
                    this.setState({errMessage: "No known article counts for this product group"})
            } else {
                quantity = packages[index + 1];
            }
            if(newQuantity !== undefined)
                this.setState({newQuantity: quantity});
            else {
                product.articles = quantity;
                productGroupsMap[selectedProduct] = product;
                this.setState({productGroupsMap});
            }
        } catch(err) {
            console.error(err);
        }
    }

    decrement() {
        const {productGroupsMap, selectedProduct, newQuantity} = this.state;
        try {
            let quantity;
            const product = productGroupsMap[selectedProduct];
            if(newQuantity !== undefined)
                quantity = newQuantity;
            else 
                quantity = product.articles;
            const packages = productGroupsMap[selectedProduct].possibleArticleCounts;
            const index = packages.indexOf(quantity);
            if(index > 0) {
                quantity = packages[index - 1];
            } else {
                quantity ? quantity -= packages[0] : quantity = 0;
            }
            if(newQuantity !== undefined)
                this.setState({newQuantity: quantity});
            else {
                product.articles = quantity;
                productGroupsMap[selectedProduct] = product;
                this.setState({productGroupsMap});
            }
        } catch(err) {
            console.error(err);
        }
    }

    renderSendModal() {
        const {puttingManifest} = this.state;
        const {putManifestSuccess, putManifestError} = this.props;
        if(puttingManifest) {
            if(putManifestSuccess) {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-medium flex-column text-center">
                            <div className="dialog-title-text green">
                                Success! Order updated 
                            </div>
                        </div>
                    </div>
                )
            } else if(putManifestError) {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-medium flex-column text-center red">
                            <div className="dialog-title-text">
                                Something went wrong.
                            </div>
                            <div className="dialog-body-add-factor">
                                {JSON.stringify(putManifestError)}
                            </div>
                            <div className="flex-row flex-space-between button-wrapper">
                                <button className="btn-primary dialog-btn-ok" onClick={() => this.setState({puttingManifest: false})}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="modal-container">
                        <div className="alert-dialog-medium flex-column text-center">
                            <div className="dialog-title-text">
                                Updating Manifest...
                            </div>
                        </div>
                    </div>
                )
            }

        }
    }

    renderCounter() {
        const {selectedProduct, productGroupsMap, newQuantity} = this.state;
        if(selectedProduct) {
            const product = productGroupsMap[selectedProduct];
            const {articles, articleType, pricePerArticle} = product;
            const quantity = newQuantity !== undefined ? newQuantity : articles;
            const units = articles === 1 ? articleType : articleType + (articleType.toLowerCase() === 'box' ? 'es' : 's');
            return (
                <div>
                    {this.renderAlert()}
                    <div className="counter-body">
                        <div className="counter-button" onClick={this.decrement}>—</div>
                        <div className="counter-value">{quantity} {units}</div>
                        <div className="counter-button" onClick={this.increment}>+</div>
                    </div>
                    <div className="counter-body green">
                        <div>${(quantity * pricePerArticle/100).toFixed(2)}</div>
                    </div>
                </div>
            )
        }   
    }

    renderAlert() {
        const {selectedProduct, products, productGroupsMap, errMessage} = this.state;
        const product = productGroupsMap[selectedProduct];
        if(errMessage) 
            return <Alert className="alert-small" variant="danger">{errMessage}</Alert>
        else if(products[product.productType] && products[product.productType].groupName !== selectedProduct) {
            return (
                <Alert className="alert-small" variant="warning">Adding this product will replace {products[product.productType].brandName} {products[product.productType].displayName}</Alert>
            )
        }
    }

    renderEditProductModal() {
        const {
            products,
            productGroupsMap,
            selectedProduct,
            isAdding
        } = this.state;

        if(productGroupsMap && products && isAdding) {
            const optGroups = [];
            const productTypes = {};

            Object.values(productGroupsMap).forEach(group => {
                if(group.status === "Active" || group.status === "Legacy") {
                    if(!productTypes[group.productType]) productTypes[group.productType] = [group];
                    else productTypes[group.productType].push(group);
                }
            });
            Object.entries(productTypes).forEach(entry => {
                const productType = entry[0];
                const values = entry[1];
                const options = [];
                values.forEach(group => {
                    options.push(
                        <option key={group.groupName} label={`${group.brandName} ${group.displayName} ${formatProductType(group.productType)} - (${group.status})`} value={group.groupName} />
                    )
                })
                optGroups.push(
                    <optgroup label={productType}>
                        {options}
                    </optgroup>
                )
            })
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column text-center">
                        <div className="dialog-title-text">
                            {selectedProduct ? "Edit Product" : "Add Product"}
                        </div>
                        <div className="dialog-body-add-factor">
                            <div className="product-add-container">
                                <select className="product-select lg" onChange={(e) => this.setState({selectedProduct: e.target.value})}>
                                    <option>Select Product...</option>
                                    {optGroups}
                                </select>
                                {this.renderCounter()}
                            </div>
                        </div>
                        <div className="flex-row flex-space-between button-wrapper">
                            <button className="btn-success dialog-btn-ok" disabled={!selectedProduct} onClick={this.onSaveClick}>
                                Add
                            </button>
                            <button className="btn-primary dialog-btn-ok" onClick={this.clearProduct}>Cancel</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderButtonRow() {
        const {productGroupsMap, manifest} = this.state;
        if(productGroupsMap && manifest) {
            return (
                <div className="flex-column text-center space-bottom">
                    <div className="flex-row flex-space-around button-wrapper">
                        <button className="btn-primary dialog-btn-ok" onClick={() => this.setState({isAdding: true})}>Add Product</button>
                        <button className="btn-success dialog-btn-ok" onClick={this.sendOrder}>Update Order</button>
                    </div>
                </div>
            )
        } else {
            return (
                <Loading />
            )
        }
    }

    render() {
        const {customerId} = this.state;
        return (
            <div>
                <Header />
                <CustomerNav history={this.props.history} customerId={customerId} />
                <div className="sdrop-body flex-column">
                    <Details history={this.props.history} />
                    {this.renderEditProductModal()}
                    {this.renderSendModal()}
                    <Col xs={12} className="flex-row flex-space-around" style={{marginTop: -80}}>               
                        <div className="table-wrapper products-table">                     
                            <h2>Update Manifest</h2>
                            {this.renderButtonRow()}
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Product Type</th>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th className="text-right">Price</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderProductRows()}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {manifestRes, manifestError, customer, error} = state.customers;
    const {productGroups, productGroupsError} = state.products;
    const {putManifestSuccess, putManifestError, puttingManifest} = state.orders;
    return {manifestRes, manifestError, productGroups, productGroupsError, putManifestSuccess, putManifestError, puttingManifest, customer, error};
}

export default connect(mapStateToProps, {
    getProductGroups,
    putManifest,
    getManifest,
    getCustomer
})(Manifest);