import React, { Component } from 'react';
import Header from '../Header'
import { connect } from 'react-redux';
import {simulate, getAutoRounder, optimize} from '../../actions';
import moment from 'moment';
import {Alert} from 'react-bootstrap';
import './simulator.css';
import base64 from 'base-64';
import utf8 from 'utf8';
import Demographics from './Demographics';

class AutoSimulator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            simulations: [],
            demographics: [],
            manifests: [],
            allOptions: []
        }
    }

    componentDidMount() {
        const {demographics} = this.state;
        const demos = Demographics({keyDemos: true});
        let i = 0;
        demos.forEach(demo => {
            this.props.simulate(demo, i);
            this.props.getAutoRounder(demo, i);
            demo.timestamp = i;
            demographics.push(demo);
            i++;
        })
    }

    componentDidUpdate() {
        const {simulations, demographics, csv, settingCSV, allOptions, manifests, settingGroups} = this.state;
        if(this.props.simulations.length === demographics.length && !settingGroups) {
            this.setState({settingGroups: true});
            this.groupSimulationProducts(this.props.simulations);
        }
        if(simulations.length && simulations.length === demographics.length && demographics.length === allOptions.length) {
            if(!settingCSV && !csv) {
                this.setState({settingCSV: true})
                this.setCSV();
            }
        }
        if(this.props.manifests) {
            if(this.props.manifests.length && this.props.manifests.length === demographics.length && !manifests.length){
                this.setState({manifests: this.props.manifests});
                this.props.manifests.forEach(m => {this.setOptions(m)});
            }
        }
    }

    groupSimulationProducts(simulations) {
        simulations.forEach(simulation => {
            simulation.simulations.forEach(sim => {
                const groups = {};
                sim.products.forEach(product => {
                    const {
                        groupName,
                        productId,
                        packages,
                        articlesPerPackage,
                        articleType,
                        brand,
                        displayName,
                        type,
                        pricePerArticle
                    } = product
                    if(groups[groupName]) {
                        groups[groupName].articles += (articlesPerPackage * packages);
                        groups[groupName].manifest[productId] = packages;
                    } else {
                        groups[groupName] = {};
                        groups[groupName].groupName = groupName;
                        groups[groupName].packages = packages;
                        groups[groupName].articleType = articleType;
                        groups[groupName].brand = brand;
                        groups[groupName].displayName = displayName;
                        groups[groupName].manifest = {[productId]: packages};
                        groups[groupName].type = type;
                        groups[groupName].articles = (packages * articlesPerPackage)
                        groups[groupName].pricePerArticle = pricePerArticle;
                    }
                })
                sim.products = groups;
            })
        })
        this.setState({simulations});
    }

    setOptions(rounder) {   
        const {allOptions} = this.state;
        let options = [];
        rounder.manifest.forEach(option => {
            let group = options.filter(o => o.id === option.groupName)[0];
            if(group) {
                options = options.filter(o => o.id !== option.groupName);
                group.rawArticleCounts.push({
                    productId: option.productId,
                    articlesPerPackage: option.articlesPerPackage,
                });
                group.articleCounts = this.props.optimize(group.rawArticleCounts).payload;
                group.rawArticleCounts.sort((a,b) => {
                    return a.articlesPerPackage - b.articlesPerPackage;
                });
                group.manifest = group.articleCounts[JSON.stringify(group.articles)];
                options.push(group);
            } else {
                const group = {
                    id: option.groupName,
                    name: option.name,
                    type: option.type,
                    brand: option.brand,
                    manifest: {[option.productId]: option.roundedPackages},
                    articles: option.roundedPackages * option.articlesPerPackage, 
                    proposedArticles: option.proposedArticles,
                    predictedArticles: option.predictedArticles,
                    preferred: option.preferred,
                    price: (option.pricePerUnit / 100),
                    rawArticleCounts: [{
                        productId: option.productId,
                        articlesPerPackage: option.articlesPerPackage,
                    }],
                    articleCounts: []
                }
    
                options.push(group);
            }
        })

        const canRemove = options.filter(o => o.type.toLowerCase() !== "trashbagskitchen" && o.type.toLowerCase() !== "toiletpaper" && o.type.toLowerCase() !== "papertowels");
        const cantRemove = options.filter(o => o.type.toLowerCase() === "trashbagskitchen" || o.type.toLowerCase() === "toiletpaper" || o.type.toLowerCase() === "papertowels");
        const newOptions = []

        cantRemove.sort((a, b) => (a.type > b.type) ? 1 : -1);
        
        newOptions.push(...cantRemove, ...canRemove);

        const typeGroups = [];
        options.forEach(product => {
            const typeGroup = typeGroups.filter(t => t.type === product.type);
            if(typeGroup.length) {
                if(product.preferred) {
                    typeGroup[0].selectedProduct = product
                }
                typeGroup[0].products.push(product)
            } else {
                typeGroups.push({type: product.type, selectedProduct: product.preferred ? product : undefined, products: [product]})
            }
        });

        allOptions.push({options: typeGroups, timestamp: rounder.timestamp});

        this.setState({allOptions});
    }

    setCSV() {
        const {demographics, allOptions, simulations} = this.state;
        demographics.sort((a,b) => {
            if(a.timestamp < b.timestamp) { return -1; }
            if(a.timestamp > b.timestamp) { return 1; }
            return 0;
        });
        allOptions.sort((a,b) => {
            if(a.timestamp < b.timestamp) { return -1; }
            if(a.timestamp > b.timestamp) { return 1; }
            return 0;
        });
        simulations.sort((a,b) => {
            if(a.timestamp < b.timestamp) { return -1; }
            if(a.timestamp > b.timestamp) { return 1; }
            return 0;
        });
        let headers = ["","Timestamp","Interview","","","","","","","","","","","","","","","","","","","","","","Products","\n"];
        let subheaders = ["","Male","","","","","","","Female","","","","","","","Total Family","Total Stays Home","Dog","Puppy","Cat","Kitten","Other","Bathrooms","Tier Preference","Trash","","","Guests","Dishwashing Frequency (weekly)","Dishwashing Products","Refills","Scents Preferred","Laundry Frequency (weekly)","Laundry Preferences", "Housekeeping"];
        let tertiaryHeaders = ["","0 to 3","4 to 11","12 to 17","18 to 24","25 to 44","45 to 64","65+","0 to 3","4 to 11","12 to 17","18 to 24","25 to 44","45 to 64","65+","","","","","","","","","","Type","Bags Used","Frequency (weekly)","","","","","","","",""];
        const rows = [];
        const fullRows = [];
        const encodedRows = [];
        const totals = [];
        const allTotals = [];
        let rowTemplate = ["", // 0- Timestamp
            0, //1- Male 0-3
            0, //2- Male 4-11
            0, //3- Male 12-17
            0, //4- Male 18-24
            0, //5- Male 25-44
            0, //6- Male 45-64
            0, //7- Male 65+
            0, //8- Female 0-3
            0, //9- Female 4-11
            0, //10- Female 12-17
            0, //11- Female 18-24
            0, //12- Female 25-44
            0, //13- Female 45-64
            0, //14- Female 65+
            0, //15- Family Total
            0, //16- Total that Stay Home
            0, //17 Dog
            0, //18- Puppy
            0, //19- Cat
            0, //20- Kitten
            0, //21- Other
            0, //22- Bathrooms
            0, //23- Brand/Tier Preferences
            0, //24- Trash Types
            0, //25- Trash Bags
            0, //26- Trash Frequency
            0, //27- Guests
            0, //28- Dishwashing Frequency
            0, //29- Dishwashing Products
            0, //30- Refills
            0, //31- Laundry Frequency
            0, //32- Laundry Preferences
            0, //33- Housekeeping
        ];

        demographics.forEach(interview => {
            let row = [...rowTemplate];
            row[0] = '"' + interview.timestamp + ' / ' + interview.name + '"';
            let staysHome = 0;
            interview.family.forEach(member => {
                if(member.staysHome) staysHome++;
                const age = member.age;
                const gender = member.gender;
                if(age < 4) {
                    gender === "Female" ? row[8] = parseInt(row[8])+1 : row[1] = parseInt(row[1])+1;
                } else if(age < 12) {
                    gender === "Female" ? row[9] = parseInt(row[9])+1 : row[2] = parseInt(row[2])+1;
                } else if(age < 18) {
                    gender === "Female" ? row[10] = parseInt(row[10])+1 : row[3] = parseInt(row[3])+1;
                } else if(age < 25) {
                    gender === "Female" ? row[11] = parseInt(row[11])+1 : row[4] = parseInt(row[4])+1;
                } else if(age < 45) {
                    gender === "Female" ? row[12] = parseInt(row[12])+1 : row[5] = parseInt(row[5])+1;
                } else if(age < 65) {
                    gender === "Female" ? row[13] = parseInt(row[13])+1 : row[6] = parseInt(row[6])+1;
                } else {
                    gender === "Female" ? row[14] = parseInt(row[14])+1 : row[7] = parseInt(row[7])+1;
                }
            });

            const trashType = [];
            const trashBags = [];
            const trashFrequency = [];
            interview.trashPreferences.forEach(t => {
                trashType.push(t.type);
                trashBags.push(t.bags);
                trashFrequency.push(t.frequency);
            });

            trashType.map(it => `${it}`).join(',');
            trashBags.map(it => `${it}`).join(',');
            trashFrequency.map(it => `${it}`).join(',');

            row[15] = interview.family.length;
            row[16] = staysHome;
            row[17] = interview.pets.Dog;
            row[18] = interview.pets.Puppy;
            row[19] = interview.pets.Cat;
            row[20] = interview.pets.Kitten;
            row[21] = interview.pets.Other;
            row[22] = interview.bathrooms;
            row[23] = '"' + interview.tierPreferences + '"';
            row[24] = '"' + trashType + '"'; // Sort out types and frequency
            row[25] = '"' + trashBags + '"'; // Sort out types and frequency
            row[26] = '"' + trashFrequency + '"'; // Sort out types and frequency
            row[27] = interview.guests;            
            row[28] = interview.dishwashing.frequency;            
            row[29] = '"' + interview.dishwashing.products + '"';
            row[30] = interview.refills;
            row[31] = '"' + interview.scentPreferences + '"';
            row[32] = interview.laundry.frequency;
            row[33] = '"' + interview.laundry.preferences + '"';
            row[34] = interview.housekeeping;

            rows.push(row);
            const text = JSON.stringify(interview);
            const bytes = utf8.encode(text);
            const encoded = base64.encode(bytes);
            encodedRows.push(['"' + encoded + '\n"']);
        })

        const types = [];
        for (let i = 0; i < allOptions.length; i++){
            const options = allOptions[i].options;
            let total = 0;
            for(let k = 0; k < options.length; k++ ) {
                const option = options[k].selectedProduct;
                if(option) {

                    if(types.includes(option.type)) {
                        const checkType = (type) => {return type === option.type};
                        const index = types.findIndex(checkType);
                        
                        let diff = rows[i].length - (index + 1) * 5 + 19
                        if(diff < 0) {
                            for(let j = 0; j < diff; j++) {
                                rows[i][rows[i].length + 1] = "";
                            }
                        }
                        const manifest = this.renderManifestStr(option.manifest);
                        const product = `"${option.brand} ${option.name}"`;
                        
                        rows[i][(index + 1) * 5 + 30] = option.proposedArticles;
                        rows[i][(index + 1) * 5 + 31] = option.articles;
                        rows[i][(index + 1) * 5 + 32] = '"' + manifest + '"';
                        rows[i][(index + 1) * 5 + 33] = product;
                        rows[i][(index + 1) * 5 + 34] = '"$' + (option.price * option.articles).toFixed(2) + '"';
                        total += option.price * option.articles;
                    } else {
                        // Prevent added cells from commas in product name
                        const header = `"${option.type}"`;
                        subheaders.push(header, "", "", "", "");
                        tertiaryHeaders.push("Proposed", "Rounded", "Manifest", "Product Group", "Price")
                        const manifest = this.renderManifestStr(option.manifest);
                        const product = `"${option.brand} ${option.name}"`;
                        types.push(option.type);
                        rows[i].push(option.proposedArticles, option.articles, `"${manifest}"`, product, `"$${(option.price * option.articles).toFixed(2)}"`);
                        total += option.price * option.articles;
                    }
                }
                if(k === options.length - 1) {
                    totals.push(`$${total.toFixed(2)}`);
                }
            }
        }

        for (let i = 0; i < simulations.length; i++) {
            fullRows.push(rows[i]);
            allTotals.push(totals[i]);
            for(let j = 0; j < simulations[i].simulations.length; j++) {
                let newRow = [`date: ${moment.utc(simulations[i].simulations[j].deliverAt).format('L')} / ${simulations[i].timestamp}`];
                for (let n = 0; n < rows[i].length -1; n++) {
                    newRow.push("");
                }
                let total = 0;
                if(simulations[i].simulations[j].products && Object.keys(simulations[i].simulations[j].products).length) {
                    const options = Object.entries(simulations[i].simulations[j].products);
                    for(let o = 0; o < options.length; o++) {
                        const option = options[o][1];
                        const {articles} = option;

                        if(types.includes(option.type)) {
                            const checkType = (type) => {return type === option.type};
                            const index = types.findIndex(checkType);
                            
                            let diff = rows[i].length - (index) * 5 + 18
                            if(diff < 0) {
                                for(let k = 0; k < diff; k++) {
                                    newRow[newRow.length + 1] = "";
                                }
                            }

                            newRow[(index + 1) * 5 + 31] = articles;
                            newRow[(index + 1) * 5 + 33] = `"${option.brand} ${option.displayName}"`;
                            newRow[(index + 1) * 5 + 34] = `$${(option.pricePerArticle/100 * articles).toFixed(2)}`;
                            total += option.pricePerArticle/100 * articles;
                        }
                    }
                }
                allTotals.push(`$${total.toFixed(2)}`);
                fullRows.push(newRow);
            }
        }
        // Spacing
        subheaders.push("Total", "\n");
        tertiaryHeaders.push("\n");

        // Convert to csv strings
        tertiaryHeaders = tertiaryHeaders.map(it => `${it}`).join(',');
        for(let i = 0; i < fullRows.length; i++) {
            const row = fullRows[i];
            if(row.length < subheaders.length) {
                const diff = subheaders.length - row.length -2;
                for(let j = 0; j < diff; j++) {
                    row.push("");
                }
            }
            row.push(allTotals[i], "\n");
            row.map(it => `${it}`).join(',');
        }
        fullRows.map(it => `${it}`).join(',');

        headers = headers.map(it => `${it}`).join(',');
        subheaders = subheaders.map(it => `${it}`).join(',');

        encodedRows.forEach(row => {
            row.push("\n");
        });
        encodedRows.map(it => `${it}`).join(',');

        let data = [
            headers,
            subheaders,
            tertiaryHeaders,
            fullRows,
            encodedRows
        ]

        this.setState({csv:data});
    }

    renderError() {
        const {error} = this.state;

        if(error) {
            return (
                <Alert variant="danger">{error}</Alert>
            )
        }
    }

    renderManifestStr(manifest) {
        let allProducts = '';       
        Object.entries(manifest).forEach(entry => {
            allProducts += allProducts ? `, ${entry[0]}: ${entry[1]}` : `${entry[0]}: ${entry[1]}`;
        });
        return allProducts;
    }

    setTypeGroups(options) {
        const {allOptions} = this.state;
        const typeGroups = [];
        options.forEach(product => {
            const typeGroup = typeGroups.filter(t => t.type === product.type);
            if(typeGroup.length) {
                if(product.preferred) {
                    typeGroup[0].selectedProduct = product
                }
                typeGroup[0].products.push(product)
            } else {
                typeGroups.push({type: product.type, selectedProduct: product.preferred ? product : undefined, products: [product]})
            }
        });

        const newGroups = typeGroups.filter(t => t.selectedProduct);
        allOptions.push(newGroups);
        this.setState({typeGroups: newGroups, allOptions});
    }

    renderDownloadBtn() {
        const {csv, settingCSV, demographics, simulations} = this.state;
        if(csv) {
            return (
                <a className="btn btn-primary update-button" href={`data:text/csv;charset=utf-8, ${encodeURIComponent(csv)}`} download="simulations.csv">Export to CSV</a>
            )
        } else if(settingCSV) {
            return (
                <div>
                    {demographics.length - simulations.length} Left
                </div>
            )
        } else {
            return (
                <div>
                    <h2>Parsing to CSV...</h2>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <Header />
                <div className="text-center sdrop-body">{this.renderDownloadBtn()}</div>
            </div>
        )
    }
} 

const mapStateToProps = (state) => {
    const {simulations, manifests, error} = state.interviews;
    return { simulations, manifests, error };
}

export default connect(mapStateToProps,{
    simulate,
    getAutoRounder,
    optimize
})(AutoSimulator);
