import React from 'react';
import images from '../../assets/images'
// import '../../Style/header.css'

export const Header = () => {
    return (
        <div style={{marginTop: 20}} className="flex-row flex-center">
            <img className="nav-logo" src={images.logo} alt="Supply Drop Home" />
        </div>
    )
}