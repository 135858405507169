import * as Sentry from '@sentry/browser';

export const formatProductType = (productType) => {
    try {
        let baseChar = 0;
        let newType = '';
        // there's gotta be a more graceful way to do this
        switch (productType) {
            case "TrashBagsKitchen":
                newType = "Kitchen Trash Bags";
                break;
            case "TrashBagsSmall":
                newType = "Small Trash Bags";
                break;
            case "TrashBagsOutdoor":
                newType = "Outdoor Trash Bags";
                break;
            case "TrashBagsCompost":
                newType = "Compost Trash Bags";
                break;
            case "TrashBagsRecycling":
                newType = "Recycling Trash Bags";
                break;
            case "FaceMasksSurgical":
                newType = "Face Masks";
                break;
            default:
                for (let i = 1; i < productType.length; i++) {
                    if (productType[i] === productType[i].toUpperCase()) {
                        if (newType.length) newType += ' ';
                        newType += productType.substring(baseChar, i);
                        baseChar += productType.substring(baseChar, i).length;
                    }
                }
                if (productType.length > newType.length) {
                    newType += ` ${productType.substring(newType.length, productType.length)}`
                }
                break;
        }
        return newType;
    } catch (err) {
        Sentry.captureException(err);
        console.error(err);
        return productType;
    }
}

export const getUnits = (articleType, articles, capitalizeFirst) => {
    const lastChar = articleType.slice(-1).toLowerCase();
    if(articles !== 1){
        if(lastChar === 'x' || lastChar === 's' || lastChar === 'o') {
            articleType += "es";
        } else {
            articleType += 's';
        }
    }

    if(capitalizeFirst)
        return articleType.charAt(0).toUpperCase() + articleType.slice(1)
    else
        return articleType.toUpperCase();
}

export function reverseFormatPhone(value) {
    try {
        let num = '';
        num = `(${value.slice(2,5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`;
        return num;
    } catch(err) {
        Sentry.captureException(err);
    }
}