import React, { Component } from 'react';
import { connect } from 'react-redux';
import Home from './components/Index.js';
import Simulator from './components/Simulator/Simulator.js'
import QRGenerator from './components/QRGenerator';
import AutoSimulator from './components/Simulator/AutoSimulator.js';
import Customers from './components/Customers/Customers.js';
import CustomerDetails from './components/Customers/CustomerDetails.js';
import CustomerOrders from './components/Customers/Orders.js';
import CustomerEvents from './components/Customers/Events.js';
import Orders from './components/Orders/Orders.js';
import EmergencyOrder from './components/Orders/EmergencyOrder.js';
import Manifest from './components/Customers/Manifest.js';
import NotFound from './components/NotFound';
import Monthaversaries from './components/Orders/Monthaversaries';
import OrderReview from './components/CustomerOrders/OrderReview.js';
import PackingSlip from './components/CustomerOrders/PackingSlip.js';
import Feedback from './components/Feedback/Feedback.js';
import Products from './components/Products/Products.js';
import Resources from './components/Resources/Resources.js';
import Reports from './components/Reports.js';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { clearGlobalErrors } from './actions';
import './App.css';
import './components/styles.css';

import Amplify from 'aws-amplify';
import awsconfig from './aws-config';
Amplify.configure(awsconfig);

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {};
  }
  componentDidUpdate() {
    if (this.props.signedInAuthErr || this.props.nonSignedInAuthErr) {
      this.props.clearGlobalErrors();
    }
  }
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/simulator" component={Simulator} exact />
            <Route path="/autosimulator" component={AutoSimulator} exact />
            <Route path="/customers" component={Customers} exact />
            <Route path="/customers/:customerId" component={CustomerDetails} exact />
            <Route path="/customers/:customerId/orders" component={CustomerOrders} exact />
            <Route path="/customers/:customerId/manifest" component={Manifest} exact />
            <Route path="/customers/:customerId/events" component={CustomerEvents} exact />
            <Route path="/customers/:customerId/emergency_order" component={EmergencyOrder} exact />
            <Route path="/customers/:customerId/orders/:orderId/review" component={OrderReview} exact />
            <Route path="/customers/:customerId/orders/:orderId/packing-slip" component={PackingSlip} exact />
            <Route path="/products" component={Products} exact />
            <Route path="/orders" component={Orders} exact />
            <Route path="/upcoming" component={Monthaversaries} exact />
            <Route path="/feedback" component={Feedback} exact />
            <Route path="/resources" component={Resources} exact />
            <Route path="/reports" component={Reports} exact />
            <Route path="/qr-generator" component={QRGenerator} exact />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { signedInAuthErr, nonSignedInAuthErr } = state.globalErrors;
  return { signedInAuthErr, nonSignedInAuthErr };
}

export default connect(mapStateToProps, {
  clearGlobalErrors
})(App);
