import React, {Component} from 'react';
import {signIn, isAdmin} from '../../actions';
import images from '../../assets/images';
import './signIn.css'
import { connect } from 'react-redux';
import {Auth} from 'aws-amplify';
import FontAwesome from 'react-fontawesome';

class SignIn extends Component {
    constructor(props){
        super(props);

        this.state = {
            email: "",
            password: undefined,
            errMessage: undefined,
            loading: false
        }

        this.updateStateFromEvent = this.updateStateFromEvent.bind(this);
        this.signIn = this.signIn.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.requestNewCode = this.requestNewCode.bind(this);
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(data => {
            const jwt = data.signInUserSession.idToken.jwtToken;
            const authed = isAdmin(jwt);
            console.log(authed);
            if(authed) {
                this.props.setSignedIn();
            } else {
                console.error('not authed');
                Auth.signOut();
            }
         }).catch(err => {
            Auth.signOut();
         })
    }

    componentDidUpdate() {
        const {loading, errMessage} = this.state;
        // if(this.props.unauthorized && !this.props.signingIn && errMessage !== 'You do not have Admin permissions') {
        //     this.setState({errMessage: 'You do not have Admin permissions', loading: false})
        // }
        
        if(this.props.user && !this.props.signedIn) {
            this.props.setSignedIn();
        }

        if(errMessage && !this.props.signingIn && loading) {
            this.setState({errMessage: 'Username or password is incorrect', loading: false})
        }

        if(this.props.signedIn && !this.state.signedIn) this.setState({signedIn: true})

        if(this.state.signedIn && !loading) {
           this.props.setSignedIn();
           this.setState({loading: true})
        }
    }

    updateStateFromEvent(name, value) {
        try {
            this.setState({[name]: value, loading: false, errMessage: ''});
        }
        catch (err) {
            console.error(err);
        }
    }

    renderError() {
        const {errMessage} = this.state;
        if(errMessage) {
            return (
                <div className="alert alert-danger">{errMessage}</div>
            )
        }
    }

    signIn() {
        const {email, password} = this.state;
        this.setState({verifying: true});
        try {
            Auth.signIn(email, password).then(data => {
                this.props.setSignedIn();
            }).catch(err => {
                console.error(err);
                this.setState({errMessage: err.message ? err.message : JSON.stringify(err), verifying: false});
            });
        } catch(error) {
            console.error(error);
            this.setState({errMessage: error.message ? error.message : JSON.stringify(error), verifying: false});
        }
    }

    forgotPassword() {
        const {email} = this.state;
        this.setState({verifying: true});
        Auth.forgotPassword(email).then(data => {
            this.setState({codeSent: true, verifying: false})
        }).catch(err => {
            console.error(err);
            this.setState({errMessage: 'Username not found', verifying: false});
        }) 
    }

    handleKeydown(e) {
        const {
            email,
            password,
            otp,
            forgot,
            codeSent
        } = this.state;

        this.setState({errMessage: ''})

        if(e.key && e.key.toLowerCase() === 'enter') {
            if(forgot) {
                if(codeSent && otp && password) {
                    this.resetPassword();
                } else if (!codeSent && email) {
                    this.forgotPassword();
                } 
            } else if (email && password) {
                this.signIn()
            }
        }
    }

    resetPassword() {
        const {password, otp, email} = this.state;
        this.setState({verifying: true})
        Auth.forgotPasswordSubmit(email, otp, password).then(data => {
            this.setState({signedIn: true, verifying: false})
        }).catch(err => {
            console.log(err);
            this.setState({errMessage: err.message, verifying: false});
        })
    }

    requestNewCode() {
        const {email} = this.state;
        if(email) {
          Auth.forgotPassword(email)
            .then(() => {
              console.log('sending verification');
            }).catch(e => {
              console.error('failed with errror ', e)
              this.setState({errMessage: e.message})
            });
        }
      }

    render() {
        const {
            email,
            password,
            otp,
            codeSent,
            errMessage,
            verifying,
            forgot,
            signedIn
        } = this.state;
        if(!signedIn) {
            if(forgot) {
                if(codeSent) {
                    return (
                        <div className="modal-container">
                            <div className="alert-dialog-medium flex-column text-center">
                            <div className="signin-image" id="signin-logo"><img className="header-logo" src={images.logo} alt="logo" /></div>
                                <div className="dialog-body">
                                    <div className="signin-subheader">
                                        Welcome to the Admin Portal
                                    </div>
                                    <div className="signin-title-text">
                                        Reset Password
                                    </div>
                                    <div className="dialog-subheader">
                                        <span style={{color: 'red' }}>
                                            {errMessage ? errMessage : ""}
                                        </span>
                                    </div>
                                    <div className="flex-row flex-space-around signin-input-container">
                                        <input
                                            name="otp"
                                            className="win-textbox signin-input"
                                            value={otp ? otp : ''}
                                            placeholder="Verification code"
                                            onChange={(e) => this.updateStateFromEvent('otp', e.target.value)}
                                            onKeyDown={this.handleKeydown}
                                        />
                                        <input
                                            name="password"
                                            className="win-textbox signin-input"
                                            value={password}
                                            type="password"
                                            placeholder="New Password"
                                            onChange={(e) => this.updateStateFromEvent('password', e.target.value)}
                                            onKeyDown={this.handleKeydown}
                                        />
                                    </div>
                                </div>
                                <div className="dialog-subheader">
                                    <span>
                                        Didn't receive a confirmation code? <strong id="request-code" onClick={this.requestNewCode}>Request new code.</strong>
                                    </span>
                                </div>
                                <div className="flex-row flex-space-between button-wrapper">
                                    <button className="btn-secondary dialog-btn-ok" onClick={() => this.setState({forgot: false})}>Back</button>
                                    <button disabled={!password || !otp} className="btn btn-primary dialog-btn-ok" onClick={this.resetPassword}><span className={verifying ? '' : 'hide'}><FontAwesome
                                        key={"placing-order-spinner"}
                                        name="spinner"
                                        className="marRight5"
                                        spin
                                    /></span>Continue</button>
                                </div>
                                {this.renderError()}
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="modal-container">
                            <div className="alert-dialog-medium flex-column text-center">
                                <div className="signin-image" id="signin-logo"><img className="header-logo" src={images.logo} alt="logo" /></div>
                                <div className="dialog-body">
                                    <div className="signin-subheader">
                                        Welcome to the Admin Portal
                                    </div>
                                    <div className="signin-title-text">
                                        Forgot Password
                                    </div>
                                    <div className="dialog-subheader">
                                        <span style={{color: errMessage ? 'red' : '#828282' }}>
                                            {errMessage ? errMessage : 'Enter your email address to reset password'}
                                        </span>
                                    </div>
                                    <div className="signin-input-container">
                                        <input
                                        name="email"
                                        className="win-textbox signin-input"
                                        value={email}
                                        placeholder="Email address"
                                        onChange={(e) => this.updateStateFromEvent('email', e.target.value)}
                                        onKeyDown={this.handleKeydown}
                                        />
                                    </div>
                                </div>
                                <div className="flex-row flex-space-between button-wrapper">
                                    <button className="btn-secondary dialog-btn-ok" onClick={() => this.setState({forgot: false})}>Back</button>
                                    <button disabled={!email} className="btn btn-primary dialog-btn-ok" onClick={this.forgotPassword}><span className={verifying ? '' : 'hide'}><FontAwesome
                                        key={"placing-order-spinner"}
                                        name="spinner"
                                        className="marRight5"
                                        spin
                                    /></span>Continue</button>
                                </div>
                            {this.renderError()}
                            </div>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="modal-container">
                            <div className="alert-dialog-medium flex-column text-center">
                                <div className="signin-image" id="signin-logo"><img className="header-logo" src={images.logo} alt="logo" /></div>
                                <div className="dialog-body">
                                    <div className="signin-subheader">
                                        Welcome to the Admin Portal
                                    </div>
                                    <div className="signin-title-text">
                                        Sign In
                                    </div>
                                    <div className="flex-row flex-space-around signin-input-container">
                                        <input
                                            className={`win-textbox signin-input`}
                                            type="email"
                                            placeholder="Email address"
                                            value={email}
                                            onChange={(e) => this.updateStateFromEvent('email', e.target.value)}
                                            onKeyDown={this.handleKeydown}
                                        />
                                        <input
                                            className={`win-textbox signin-input`}
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => this.updateStateFromEvent('password', e.target.value)}
                                            onKeyDown={this.handleKeydown}
                                        />
                                    </div>
                                    <div className="flex-row flex-space-between button-wrapper">
                                        <button className="btn-secondary dialog-btn-ok" onClick={() => this.setState({forgot: true, password: ''})}>Forgot Password?</button>
                                        <button disabled={!email || !password} className="btn btn-primary dialog-btn-ok" onClick={this.signIn}><span className={verifying ? '' : 'hide'}><FontAwesome
                                            key={"placing-order-spinner"}
                                            name="spinner"
                                            className="marRight5"
                                            spin
                                        /></span>Sign In</button>
                                    </div>
                                </div>
                            {this.renderError()}
                            </div>
                        </div>
                )
            }
        } else {
            return (
                <div>Loading...</div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const { unauthorized, user, error, signingIn } = state.users;
    return { user, error, unauthorized, signingIn };
}

export default connect( mapStateToProps, {
    signIn,
    isAdmin
})(SignIn);