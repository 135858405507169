import Logger from './Logger'
const logger = new Logger();

// TODO: 
// Make universal logic for creating products Arr, productsMap, productGroupsMap, etc
// Get the required fields from api response and create the required data objects
export function getProductsFromEntries(productGroupEntries, productGroups) {
    const { productGroupsMap, error } = createProductGroupsMap(productGroups);
    if(productGroupsMap)
        return mapProductGroupToProduct(productGroupEntries, productGroupsMap)
    else {
        logger.error(error, new Error('Could not createProductGroupsMap()'));
        return []
    }
}

export function createProductGroupsMap(productGroups) {
    try {
        const productGroupsMap = {}
        productGroups.forEach(productGroup => {
            const { groupName } = productGroup
            if (groupName)
                productGroupsMap[groupName] = productGroup;
            else
                logger.error(new Error(`No groupName in productGroup: ${productGroup}`))
        })
        return { productGroupsMap };
    } catch (error) {
        logger.error(error, new Error('Could not createProductGroupsMap'));
        return { error }
    }
}

function mapProductGroupToProduct(productGroupEntries, productGroupsMap) {
    return productGroupEntries.map(productGroupEntry => {
        const { groupName } = productGroupEntry;
        const productGroup = productGroupsMap[groupName];
        Object.assign(productGroup, productGroupEntry);
        const {
            pricePerArticleCents,
            imageUrl,
            productType,
            articleType,
            brandName,
            displayName,
        } = productGroup;
        const title = brandName.concat(' ', displayName)
        const productFields = {
            groupName,
            pricePerArticleCents,
            imageUrl,
            productType,
            articleType,
            title,
            brandName
        }
        const { products } = productGroup;
        const updatedProducts = products.map(product => {
            Object.assign(product, productFields);
            const { quantity, articlesPerPackage } = product;
            product.articles = quantity * articlesPerPackage;
            return product;
        })
        return [...updatedProducts];
    }).flat();
}


export function createProductTypesMapFromEntries(productGroupEntries, productGroupsMap) {
    try {
        const productTypesMap = {};
        productGroupEntries.forEach(productGroupEntry => {
            const { groupName } = productGroupEntry;
            const productGroup = productGroupsMap[groupName];
            Object.assign(productGroup, productGroupEntry)
            const { productType } = productGroup;
            productTypesMap[productType] = productGroup;
        })
        return { productTypesMap }
    } catch (error) {
        logger.error(error, new Error('Could not createProductTypesMapFromEntries()'))
        return { error }
    }
}