import axios from 'axios';
import {
	GET_CUSTOMER_SUCCESS,
	GET_CUSTOMER_FAIL,
	GET_CUSTOMERS_SUCCESS,
	GET_CUSTOMERS_FAIL,
	GET_FEEDBACK_FAIL,
	GET_FEEDBACK_SUCCESS,
	GET_MANIFEST_SUCCESS,
	GET_MANIFEST_FAIL,
	GET_CUSTOMER_EVENTS_SUCCESS,
	GET_CUSTOMER_EVENTS_FAIL,
	CLEAR_CUSTOMERS_STATE,
	UPDATING_STATUS,
	UPDATE_STATUS_SUCCESS,
	UPDATE_STATUS_FAIL,
	UNAUTHORIZED
} from './types';
import { getAuthHeaders, handleApiError } from '../helpers/apiHelpers';
import keys from '../config';
const url = keys.apiUrl;

export const getCustomers = () => {
	return (dispatch) => {
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/customers/`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_CUSTOMERS_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, GET_CUSTOMERS_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_CUSTOMERS_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, GET_CUSTOMERS_FAIL))
		})
	}
}

export const getCustomer = (customerId) => {
	return (dispatch) => {
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/customers/${customerId}`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_CUSTOMER_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, GET_CUSTOMER_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_CUSTOMER_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, GET_CUSTOMER_FAIL))
		})
	}
}

export const getCustomerEvents = (customerId) => {
	return (dispatch) => {
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/customers/${customerId}/events`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_CUSTOMER_EVENTS_SUCCESS, payload: res.data.events });
					else
						dispatch(handleApiError(res.error, GET_CUSTOMER_EVENTS_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_CUSTOMER_EVENTS_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, GET_CUSTOMER_EVENTS_FAIL))
		})
	}
}

export const getFeedback = () => {
	return (dispatch) => {
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/feedbacks`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_FEEDBACK_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, GET_FEEDBACK_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_FEEDBACK_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, GET_FEEDBACK_FAIL))
		})
	}
}

export const getManifest = (customerId) => {
	return (dispatch) => {
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/manifests/${customerId}`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_MANIFEST_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, GET_MANIFEST_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_MANIFEST_FAIL))
				})
		}).catch(err => {	
			dispatch(handleApiError(err, GET_MANIFEST_FAIL))
		})
	}
}

export const updateCustomerStatus = (customerId, status, deliverAt) => {
	return (dispatch) => {
		dispatch({ type: UPDATING_STATUS })
		getAuthHeaders().then(headers => {
			const body = deliverAt ? {deliverAt} : {};
			axios.put(`${url}/admin/customers/${customerId}/status/${status}`, body, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: UPDATE_STATUS_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, UPDATE_STATUS_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, UPDATE_STATUS_FAIL))
				})
		}).catch(err => {
			dispatch(handleApiError(err, UPDATE_STATUS_FAIL))
		})
	}
}

export const clearCustomersState = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_CUSTOMERS_STATE })
	}
}
