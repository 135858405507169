import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Header';
import OrderRow from './OrderRow';
import {
    getOrdersPendingApproval,
    getOrdersPendingShipment,
    getCustomer,
    approveOrder,
    getReports,
    isAdmin,
    getCustomers
} from '../../actions';
import '../Simulator/factors.css';
import '../CustomerOrders/details.css';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Loading } from '../Common';

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetchingData: false,
            orders: undefined,
            ordersMap: undefined,
            pageNumber: undefined,
            orderTotal: undefined,
            goingForward: false,
            goingBack: false,
            products: undefined,
            order: undefined,
            loading: false,
            pendingApprovalToday: "...",
            pendingShippingToday: "...",
            allShippingToday: "..."
        }

        this.props.getOrdersPendingApproval();
        this.props.getOrdersPendingShipment();
        this.props.getReports();
        this.props.getCustomers();
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(data => {
            const jwt = data.signInUserSession.idToken.jwtToken;
            const authed = isAdmin(jwt);
            if (!authed) {
                Auth.signOut().then(() => {
                    this.props.history.push('/');
                })
            }
        }).catch(err => {
            console.error(err);
            this.props.history.push('/');
        });
    }

    componentDidUpdate() {
        const { shippingOrders, approvalOrders, customers, reports } = this.props;
        let { allShippingToday } = this.state;
        if (this.props.approvalOrders && !this.state.ordersMap) {
            const orders = approvalOrders;
            const { ordersMap, pending } = this.mapOrders(orders);
            if (isNaN(allShippingToday)) allShippingToday = pending;
            else allShippingToday += pending;
            this.setState({ ordersMap, orders, pendingApprovalToday: pending, allShippingToday });
        }

        if (reports && !this.state.dropoff && !this.state.dropoffError) {
            try {
                const { generatedReports } = reports;
                const { dropoff } = generatedReports;
                const { presignedUrl } = dropoff;
                this.setState({ dropoff: presignedUrl })
            } catch (err) {
                console.error(err);
                this.setState({ dropoffError: err })
            }
        }

        if (this.props.shippingOrders && !this.state.shippingMap) {
            const { ordersMap, pending } = this.mapOrders(shippingOrders);
            if (isNaN(allShippingToday)) allShippingToday = pending;
            else allShippingToday += pending;
            this.setState({ shippingMap: ordersMap, shippingOrders, pendingShippingToday: pending, allShippingToday });
        }

        if (this.props.products && !this.state.products) {
            this.setState({ products: this.props.products });
        }

        if (this.props.csv && !this.state.csv) {
            this.setState({ csv: this.props.csv })
        }

        if (customers && !this.state.customersMap) {
            const customersMap = {};
            customers.forEach(customer => {
                const id = customer.customerId;
                customersMap[id] = {};
                Object.entries(customer).forEach(entries => {
                    customersMap[id][entries[0]] = entries[1];
                })

            });
            this.setState({ customersMap });
        }
    }

    mapOrders(orders) {
        const ordersMap = {};
        let pending = 0;
        orders.forEach(order => {
            const { expectedShipDate, orderId, customerId, expectedDeliveryDate, deliveryMethod, createdAt, interviewId, products, netSuite } = order
            if (moment(expectedShipDate).isSame(moment().format('YYYY-MM-DD'))) pending++;
            ordersMap[orderId] = {
                orderId,
                customerId,
                expectedShipDate: moment.utc(expectedShipDate).format('ll'),
                deliveryDate: moment.utc(expectedDeliveryDate).format('ll'),
                createdDate: moment.utc(createdAt).local().format('lll'),
                deliveryMethod,
                netSuite,
                //TODO: Loop through products to show product counts
                interviewId,
                products
            };
        })
        return { ordersMap, pending }
    }

    focusOn(element) {
        if (this.refs[element]) {
            this.refs[element].scrollIntoView();
        }
        else
            console.error(`No such element: ${element}`);
    }

    renderOrderRows() {
        const { ordersMap, customersMap } = this.state;
        if (ordersMap && customersMap) {
            const rows = [];
            for (let _id in ordersMap) {
                const order = ordersMap[_id];
                const { orderId, customerId, createdDate, expectedShipDate, deliveryDate, deliveryMethod, netSuite } = order;
                const customer = customersMap[customerId];
                let customerStr;
                let customerSince;
                let createdAtDate;
                if (customer) {
                    const { firstName, lastName, nsCustomerId, shipping, email, customerId, phone, createdAt } = customer;
                    createdAtDate = createdAt;
                    netSuite.nsCustomerId = nsCustomerId;
                    customerSince = moment.utc(createdAt).local().format('lll');
                    customerStr = (
                        <div>
                            <b>{firstName} {lastName}</b><br />
                            {email} — {phone}<br />
                            {shipping.address}<br />
                            {shipping.city}, {shipping.state} {shipping.zipcode}<br />
                            {customerId}<br />
                        </div>
                    )

                } else {
                    customerStr = customerId
                }
                rows.push(
                    <OrderRow
                        key={_id}
                        orderId={orderId}
                        customerId={customerId}
                        customerStr={customerStr}
                        createdDate={createdDate}
                        expectedShipDate={expectedShipDate}
                        deliveryDate={deliveryDate}
                        deliveryMethod={deliveryMethod}
                        netSuite={netSuite}
                        createdAt={createdAtDate}
                        status="Pending Approval"
                        customerSince={customerSince}
                    />
                );
            }
            return rows;
        }
    }

    renderShippingRows() {
        const { shippingMap, customersMap } = this.state;
        if (shippingMap && customersMap) {
            const rows = [];
            for (let _id in shippingMap) {
                const { customerId, orderId, createdDate, expectedShipDate, deliveryMethod, deliveryDate, netSuite } = shippingMap[_id];
                const customer = customersMap[customerId];
                let customerStr;
                let customerSince;
                if (customer) {
                    const { firstName, lastName, shipping, email, customerId, phone, createdAt } = customer;
                    customerSince = moment.utc(createdAt).local().format('lll');
                    customerStr = (
                        <div>
                            <b>{firstName} {lastName}</b><br />
                            {email} — {phone}<br />
                            {shipping.address}<br />
                            {shipping.city}, {shipping.state} {shipping.zipcode}<br />
                            {customerId}<br />
                        </div>
                    )
                } else {
                    customerStr = customerId
                }
                rows.push(<OrderRow
                    key={_id}
                    orderId={orderId}
                    customerId={customerId}
                    netSuite={netSuite}
                    customerStr={customerStr}
                    createdDate={createdDate}
                    expectedShipDate={expectedShipDate}
                    status="Pending Shipping"
                    deliveryDate={deliveryDate}
                    deliveryMethod={deliveryMethod}
                    customerSince={customerSince}
                />);
            }
            return rows;
        }
    }

    renderTitle() {
        if (this.props.orders && this.props.orders.pageNumber) {
            var total = this.props.orders.total;
            var orderStart = ((parseInt(this.props.orders.pageNumber) - 1) * 20) + 1;
            var orderEnd = orderStart + 19;
            orderEnd = orderEnd > total ? total : orderEnd;
            return (
                <div className="title-text">Orders { orderStart}-{ orderEnd} of { total}</div>
            );
        }
    }

    renderOrderError() {
        const { approvalOrdersError, gettingApprovalOrders } = this.props;
        if (approvalOrdersError) {
            return (
                <tr>
                    <th className="red">Could not get orders pending shipping</th>
                    <td><div className="link" onClick={this.props.getOrdersPendingApproval}>Try again?</div></td>
                </tr>
            )
        } else if (gettingApprovalOrders) {
            return <Loading />
        }
    }

    renderShippingError() {
        const { shippingOrdersError, gettingShippingOrders } = this.props;
        if (shippingOrdersError) {
            return (
                <tr>
                    <th className="red">Could not get orders pending shipping</th>
                    <td><div className="link" onClick={this.props.getOrdersPendingShipment}>Try again?</div></td>
                </tr>
            )
        } else if (gettingShippingOrders) {
            return <Loading />
        }
    }

    render() {
        const { dropoff, pendingApprovalToday, pendingShippingToday, allShippingToday } = this.state;
        return (
            <div>
                <Header />
                <Navbar collapseOnSelect bg="dark" variant="dark" className="secondary-navbar" fixed="top">
                    <Nav className="mr-auto">
                        <Nav.Link onClick={() => this.focusOn('approval')}>Pending Approval</Nav.Link>
                        <Nav.Link onClick={() => this.focusOn('shipping')}>Pending Shipping</Nav.Link>
                    </Nav>
                    <Nav>
                        <Button className={!dropoff ? "hide" : 'btn'} variant="outline-light" href={dropoff} download="dropoff.csv">Get Dropoff CSV</Button>
                    </Nav>
                </Navbar>
                <div className="sdrop-body flex-column">
                    <div className="table-wrapper">
                        <h3>Orders Shipping Today: {allShippingToday}</h3>
                        <h2 ref="approval">Orders Pending Approval {!isNaN(pendingApprovalToday) ? `  — ${pendingApprovalToday} shipping today` : ''}</h2>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Order Id</th>
                                    <th scope="col">Packing Slip</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Ship Date</th>
                                    <th scope="col">Delivery Date</th>
                                    <th scope="col">Delivery Method</th>
                                    <th scope="col">Netsuite Customer Id</th>
                                    <th scope="col">Date Created</th>
                                    <th scope="col">Customer Since</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderOrderRows()}
                                {this.renderOrderError()}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginBottom: 100 }} />
                    <div className="table-wrapper">
                        <h2 ref="shipping">Orders Pending Shipping {!isNaN(pendingShippingToday) ? `  — ${pendingShippingToday} shipping today` : ''}</h2>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Order Id</th>
                                    <th scope="col" className="text-center">Customer</th>
                                    <th scope="col">Ship Date</th>
                                    <th scope="col">Delivery Date</th>
                                    <th scope="col">Delivery Method</th>
                                    <th scope="col">Netsuite Customer Id</th>
                                    <th scope="col" className="text-center">Date Created</th>
                                    <th scope="col" className="text-center">Customer Since</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderShippingRows()}
                                {this.renderShippingError()}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginBottom: 100 }} />
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    const { shippingOrders, approvalOrders, approvalOrdersError, shippingOrdersError, gettingShippingOrders, gettingApprovalOrders } = state.orders;
    const { reports, gettingReports, getReportsError } = state.reports;
    const { error, unauthorized } = state.users;
    const { customers } = state.customers;
    return { shippingOrders, approvalOrders, error, unauthorized, customers, reports, gettingReports, getReportsError, approvalOrdersError, shippingOrdersError, gettingShippingOrders, gettingApprovalOrders };
}

export default connect(mapStateToProps, {
    getOrdersPendingApproval,
    getOrdersPendingShipment,
    getCustomer,
    approveOrder,
    getReports,
    getCustomers
})(Orders)