import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Header from '../Header';
import {
	getCustomers,
	getMonthaversaries,
	isAdmin
} from '../../actions';
import CustomerRow from './CustomerRow.js';
import '../Simulator/factors.css';
import { Auth } from 'aws-amplify';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Loading } from '../Common';

class Customers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customers: undefined,
			customersMap: undefined,
			customerRows: undefined,
			activeCount: 0,
			inactiveCount: 0,
			abandonedCount: 0,
			pausedCount: 0
		}

		this.renderCustomerRows = this.renderCustomerRows.bind(this);
		this.focusOn = this.focusOn.bind(this);
		this.sortByCadenceWeeks = this.sortByCadenceWeeks.bind(this);
	}

	componentDidMount() {
		this.props.getCustomers();
		this.props.getMonthaversaries();
		Auth.currentAuthenticatedUser().then(data => {
			const jwt = data.signInUserSession.idToken.jwtToken;
			const authed = isAdmin(jwt);
			if (!authed) {
				this.props.history.push('/');
			}
		}).catch(err => {
			this.props.history.push('/');
		})
	}

	componentDidUpdate() {
		const { customers, manifests } = this.props;
		let { activeCount, inactiveCount, abandonedCount, pausedCount } = this.state;
		if (customers && !this.state.customers) {
			customers.sort((a, b) => {
				if (a.createdAt > b.createdAt) { return -1; }
				if (a.createdAt < b.createdAt) { return 1; }
				return 0;
			})

			customers.forEach(customer => {
				if (customer.status !== "Active") {
					if(customer.status === "Created") {
						abandonedCount++
					} else if (customer.status === "Paused") {
						pausedCount++;
					} else {
						inactiveCount++;
					}
				} else {
					activeCount++
				};
			})
			this.setState({ customers, inactiveCount, activeCount, abandonedCount, pausedCount });
			this.convertToCSV(customers);
		}

		if(manifests && !this.state.manifestMap) {
			const manifestMap = {};
			manifests.forEach(manifest => {
				manifestMap[manifest.customerId] = manifest;
			})
			this.setState({manifestMap})
		}
	}

	renderBackToFactorsButton() {
		return (
			<button className="btn-primary btn-update" onClick={() => { this.props.history.push('/') }}>View Factors</button>
		);
	}

	sortByCadenceWeeks() {
		const {orderBy, customers, manifestMap} = this.state;
		customers.sort((a,b) => {
			const aWeeks = manifestMap[a.customerId] ? manifestMap[a.customerId].cadenceWeeks : 0;
			const bWeeks = manifestMap[b.customerId] ? manifestMap[b.customerId].cadenceWeeks : 0;
			if(aWeeks > bWeeks) return orderBy === "asc" ? 1 : -1
			else if(aWeeks < bWeeks) return orderBy === "asc" ? -1 : 1
			return 0
		})
		this.setState({customers, orderBy: orderBy === "asc" ? "desc" : "asc"})
	}

	focusOn(element) {
        if(this.refs[element]) {
            this.refs[element].scrollIntoView();
        }
        else 
            console.error(`No such element: ${element}`);
    }

	renderExportBtn() {
		const { csv } = this.state;
		if (csv) {
			return (
				<Button variant="outline-light" href={`data:text/csv;charset=utf-8, ${encodeURIComponent(csv)}`} download="interviews.csv">Export Interviews</Button>
			)
		}
	}

	convertToCSV(customers) {
		const headers = ["", "Customer Id", "Order Date", "Name", "Family", "Pets", "Bathrooms", "Dishwashing", "Laundry", "Scents", "Trash", "Housekeeping", "Guests", "Tier Preference", "Refills", "\n"]
		const rows = [];

		customers.forEach(customer => {
			rows.push('"' + customer.customerId + '"');
			rows.push(customer.createdAt ? moment.utc(customer.createdAt).local().format('MMM DD YYYY') : "Unknown")
			rows.push(customer.firstName + " '" + customer.lastName);
			const { interview } = customer;
			const { Cat, Kitten, Dog, Other, Puppy } = interview.pets;
			let famStr = '';
			interview.family.forEach(member => {
				famStr += member.gender + ' ' + member.age + ' staysHome: ' + member.staysHome + ' | ';
			})
			rows.push(famStr);
			rows.push('Cats:' + Cat + '|Kittens:' + Kitten + '|Dogs:' + Dog + '|Puppies:' + Puppy + '|Other:' + Other);
			rows.push(interview.bathrooms);
			let dpStr = '';
			interview.dishwashingProducts.forEach(dp => {
				dpStr += dp + ' | ';
			})
			rows.push("Frequency:" + interview.dishwashingFrequency + "| Products:" + dpStr);
			let lpStr = '';
			interview.laundryPreferences.forEach(lp => {
				lpStr += lp + ' | ';
			})
			rows.push("Frequency:" + interview.laundryFrequency + "| Preferences:" + lpStr);
			let scentStr = '';
			interview.scentPreferences.forEach(s => {
				scentStr += s + ' | ';
			})
			rows.push(scentStr);
			let trashStr = '"';
			interview.trash.forEach(t => {
				trashStr += t.type + ' bags: ' + t.bags + ' frequency: ' + t.frequency + ' | '
			})
			trashStr += '"';
			rows.push(trashStr);
			rows.push(interview.housekeeping);
			rows.push(interview.guests);
			let tierStr = '"';
			interview.tierPreferences.forEach(t => {
				tierStr += t + ' | '
			})
			tierStr += '"';
			rows.push(tierStr);
			rows.push(interview.refills);

			rows.push("\n");
		})

		headers.map(it => `${it}`).join(',');
		rows.map(it => `${it}`).join(',');

		this.setState({ csv: [headers, rows] })
	}

	renderCadenceArrow() {
		const {orderBy} = this.state;
		if(orderBy === "asc") {
            return (<span>&uarr;</span>)
        } else if (orderBy === "desc") {
            return (<span>&darr;</span>)
        } 
	}

	renderTableBody(rows) {
        return (
            <table className="table table-striped">
				<thead>
					<tr>
						<th scope="col">Customer</th>
						<th scope="col" className="pointer" onClick={this.sortByCadenceWeeks}>Cadence Weeks{this.renderCadenceArrow()}</th>
						<th scope="col" className="text-center">Manifest Details</th>
						<th scope="col">Sign Up Date</th>
						<th scope="col" className="text-center"></th>
						<th scope="col" className="text-center"></th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
        )
    }

	renderCustomerRows() {
		const { customers, manifestMap } = this.state;
		const activeRows = [];
		const createdRows = [];
		const inactiveRows = [];
		const pausedRows = [];
		if (customers && manifestMap) {
			customers.forEach(customer => {
				const {customerId, email, firstName, lastName, createdAt, phone, shipping} = customer;
				const row = <CustomerRow
								key={customerId}
								name={`${firstName} ${lastName}`}
								customerId={customerId}
								email={email}
								phone={phone}
								shipping={shipping}
								manifest={manifestMap[customerId]}
								createdAt={createdAt ? moment.utc(createdAt).local().format('lll') : ""}
								onManifestClick={() => this.onViewManifestClick(customer)}
								status={customer.status}
							/>
				if(customer.status === "Active")
					activeRows.push(row)
				else if(customer.status === "Created") {
					createdRows.push(row)
				} else if (customer.status === "Paused") {
					pausedRows.push(row)	
				} else {
					inactiveRows.push(row)
				}
			});
			return (
				<Fragment>
					<h2 ref="active-customers" className="customers-table-header">Active Customers</h2>
					{this.renderTableBody(activeRows)}
					<h2 ref="created-customers" className="customers-table-header">Abandoned Customers</h2>
					{this.renderTableBody(createdRows)}
					<h2 ref="paused-customers" className="customers-table-header">Paused Customers</h2>
					{this.renderTableBody(pausedRows)}
					<h2 ref="inactive-customers" className="customers-table-header">Inactive Customers</h2>
					{this.renderTableBody(inactiveRows)}
				</Fragment>
			)
		} else {
			return <Loading />
		}
	}

	render() {
		const { activeCount, inactiveCount, abandonedCount, pausedCount } = this.state;
		return (
			<div>
				<Header />
				<Navbar collapseOnSelect bg="dark" variant="dark" className="secondary-navbar" fixed="top">
					<Nav className="mr-auto">
						<Nav.Link onClick={() => this.focusOn('active-customers')}>Active Customers</Nav.Link>
						<Nav.Link onClick={() => this.focusOn('created-customers')}>Abandoned Customers</Nav.Link>
						<Nav.Link onClick={() => this.focusOn('paused-customers')}>Paused Customers</Nav.Link>
						<Nav.Link onClick={() => this.focusOn('inactive-customers')}>Inactive Customers</Nav.Link>
					</Nav>
					<Nav>
						{this.renderExportBtn()}
					</Nav>
				</Navbar>
				<div className="sdrop-body flex-column">
					<div className="table-wrapper">
						<h2>Customers</h2>
						<p>Active: {activeCount}</p>
						<p className="gold">Abandoned: {abandonedCount}</p>
						<p className="blue">Paused: {pausedCount}</p>
						<p className="red">Inactive: {inactiveCount}</p>
						{this.renderCustomerRows()}
					</div>
					<div style={{ marginBottom: 100 }} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
    const {manifests} = state.orders;
	const { customers, customer, error } = state.customers;
	return { customers, customer, error, manifests };
}

export default connect(mapStateToProps, {
	getCustomers,
	getMonthaversaries
})(Customers)
