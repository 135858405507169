import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Header';
import {
  getProducts,
  getProductGroups,
  isAdmin,
  formatProductType
} from '../../actions';
import '../Simulator/factors.css';
import {Auth} from 'aws-amplify';
import {Navbar, Nav, Button} from 'react-bootstrap';
import { Loading } from '../Common';

class Products extends Component{

  constructor(props){
    super(props);
    this.state = {
		sortFields: [
			"GroupName",
			"Brand",
			"Price",
			"Type",
			"Status",
			"Inventory",
		]
	}

    this.props.getProductGroups();
	this.props.getProducts();
	
    this.setRows = this.setRows.bind(this);
    this.sortByField = this.sortByField.bind(this);
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then(data => {
      const jwt = data.signInUserSession.idToken.jwtToken;
      const authed = isAdmin(jwt);
      if(!authed) {
          this.props.history.push('/');
      }
    }).catch(err => {
        this.props.history.push('/');
    })
  }

  componentDidUpdate(){
	const {products, productGroups} = this.props;
	const {groupRows, creatingRows, sorted} = this.state;

    if(products && !this.state.products) {
        const hasPurchaseDescription = !!products[0].purchaseDescription
		this.setState({products, hasPurchaseDescription});
		this.setProductsMap(products);
	}
    if(productGroups && !this.state.productGroups) {
        this.setState({productGroups});
	}

    if(this.props.unauthorized) {
      this.props.history.push('/');
	}
	if(this.state.products && this.state.productGroups && !groupRows && !creatingRows) {
		this.setState({creatingRows: true});
		this.setRows();
		this.createCSV();
	} 
	if(sorted) {
		this.setState({sorted: false});
		this.setRows();
	}
}

sortByField(field) {
	try {
		const {sortFields, productGroups} = this.state;
		let pgField;
		switch(field) {
			case "ProductType":
				pgField = "productType";
				break;
			case "GroupName":
				pgField = "groupName";
				break;
			case "Brand":
				pgField = "brandName";
				break;
			case "Price":
				pgField = "pricePerArticle";
				break;
			default:
				pgField = field.toLowerCase();
				break;
		}
		if(this.state[`sortBy${field}`]) {
			productGroups.sort((a,b) => {
				if(a[pgField] < b[pgField]) { return -1; }
				if(a[pgField] > b[pgField]) { return 1; }
				return 0;
			});
		} else {
			productGroups.sort((a,b) => {
				if(a[pgField] < b[pgField]) { return 1; }
				if(a[pgField] > b[pgField]) { return -1; }
				return 0;
			})
		}
	
		this.setState({[`sortBy${field}`]: !this.state[`sortBy${field}`], productGroups, sorted: true});
		sortFields.forEach(sf => {
			if(sf !== field && this.state[`sortBy${sf}`] !== undefined) {
				this.setState({[`sortBy${sf}`]: undefined})
			}
		})
	} catch (err) {
		console.error(err);
	}
}

setProductsMap(products) {
	try {
		const productGroupsMap = {};
		products.forEach(product => {
			const {groupName} = product;
		 	if(!productGroupsMap[groupName]) {
				 productGroupsMap[groupName] = [];
			}
			productGroupsMap[groupName].push(product)
		})
		this.setState({productGroupsMap})
	} catch(err) {
		console.error(err)
	}
}

toggleProductRows(groupName) {
	this.setState({[groupName]: !this.state[groupName]})
}

setRows() {
	const { productGroupsMap, productGroups, products } = this.state;
    const groupRows = {};
	const productRows = {};
	try {
		if(productGroupsMap && productGroups && products){
			productGroups.forEach(group => {
				const {groupName,
					productType,
					pricePerArticle,
					articleType,
					brandName,
					displayName,
					imageUrl,
					badges,
					description,
					possibleArticleCounts,
					status} = group;
				let inventory = 0;
				let articleCountShortList = [];
					let arrLength = possibleArticleCounts.length > 5 ? 5 : possibleArticleCounts.length;
					for(let i = 0; i < arrLength; i++) {
						articleCountShortList.push(possibleArticleCounts[i]);
					}
				
				const groupProducts = products.filter(p => p.groupName === groupName);
				
				groupProducts.forEach(product => {
					const {
						productId,
						articlesPerPackage,
						quantityAvailable,
						hidden,
						stripeSkuId,
						stripeProductId,
						stripePriceCents
					} = product;
					inventory += quantityAvailable * articlesPerPackage;
					group.inventory = inventory;

					if(!productRows[groupName]) {
						productRows[groupName] = [(
							<tr>
								<th></th>
								<th>Product Id</th>
								<th>Articles Per Package</th>
								<th>Hidden?</th>
								<th>Inventory</th>
								<th>Stripe Price</th>
								<th>Stripe Product Id</th>
								<th>Stripe SKU Id</th>
							</tr>
						)]
					}
					productRows[groupName].push(
						<tr className="product-row">
							<td></td>
							<td>{productId}</td>
							<td>{articlesPerPackage}</td>
							<td>{JSON.stringify(hidden)}</td>
							<td className={status.toLowerCase() === "active" && !quantityAvailable ? "red" : ""}>{quantityAvailable * articlesPerPackage} articles</td>
							<td>${(stripePriceCents/100).toFixed(2)} each</td>
							<td>{stripeProductId}</td>
							<td>{stripeSkuId}</td>
						</tr>
					);
				})
				groupRows[groupName] =(
					<tr onClick={() => this.toggleProductRows(groupName)}>
						<th>{brandName} {displayName}</th>
						<td>{groupName}</td>
						<td>{formatProductType(productType)}</td>
						<td className={status.toLowerCase() === "active" ? "green" : ""}>{status}</td>
						<td className={status.toLowerCase() === "active" && !inventory ? "red" : ""}>{inventory} articles</td>
						<td>${(pricePerArticle/100).toFixed(2)}/{articleType}</td>
						<td>{articleCountShortList.map(it => `${it}`).join(',')}{possibleArticleCounts.length > 5 ? "...": ""}</td>
						<td>{description}</td>
						<td>{possibleArticleCounts.length ?
						`${possibleArticleCounts[0]} / ${possibleArticleCounts[possibleArticleCounts.length - 1]}` :
						`0 / 0`}
						</td>
						<td>{badges.map(it => {return `${it}, `})}</td>
						<td className="table-product-img"><img src={imageUrl} alt={`${brandName} ${displayName}`}/></td>
					</tr>
				)
			})
		}

		this.setState({groupRows, productRows, productGroups})
	} catch (err) {
		console.error(err);
	}
}

renderProductRow(groupName) {
	const {productRows} = this.state;
	if(this.state[groupName]) {
		return productRows[groupName];
	}
}
renderTableBody(){
	const {groupRows, productRows} = this.state;
	
	if(productRows && groupRows) {
		const rows = [];
		Object.entries(groupRows).forEach(entry => {
			const groupName = entry[0];
			const row = entry[1];
			rows.push(row);
			if(productRows[groupName])
				rows.push(this.renderProductRow(groupName))
		})

		return rows;
	} else {
		return <Loading />
	}
}
renderArrow(sort) {
  if(sort === false) {
      return (<span>&uarr;</span>)
  } else if (sort) {
      return (<span>&darr;</span>)
  } 
}
renderExportBtn() {
	const {csv} = this.state;
	if(csv) {
		return (
			<Button variant="outline-light" href={`data:text/csv;charset=utf-8, ${encodeURIComponent(csv)}`} download="stripe_products.csv">Export Stripe Data</Button>
		)
	}
}

createCSV() {
	const {products, productGroups} = this.state;

    const headers = ["","Brand/Display Name", "Group Name", "Type", "Status", "Inventory", "Price", "", "", "\n"]
    const subheaders = ["", "Product Id", "Articles Per Package", "Hidden", "Inventory", "Stripe Price", "Stripe Product Id", "Stripe SKU Id", "\n"]
    const rows = [];

    productGroups.forEach(group => {
		const {
			brandName,
			displayName,
			productType,
			status,
			pricePerArticle,
			quantityAvailable,
			groupName
		} = group;

		const groupProducts = products.filter(p => p.groupName === groupName);
		const productRows = [];

		if(groupProducts.length) {
			productRows.push(...subheaders);
			groupProducts.forEach(product => {
				const {
					productId,
					articlesPerPackage,
					quantityAvailable,
					hidden,
					stripeSkuId,
					stripeProductId,
					stripePriceCents
				} = product;
				productRows.push(
					"",
					productId,
					articlesPerPackage,					
					'"' + JSON.stringify(hidden) + '"',
					quantityAvailable,
					(stripePriceCents/100).toFixed(2),
					stripeProductId,
					stripeSkuId,
					"\n"
				)
			})
		}
		const name = `"${brandName} ${displayName}"`;
		rows.push(name);
		rows.push(groupName);
		rows.push(formatProductType(productType));
		rows.push(status);
		rows.push(quantityAvailable);
		const price = `"$${(pricePerArticle/100).toFixed(2)}"`;
		rows.push(price)
		rows.push("\n");
		if(productRows.length) rows.push(...productRows);
		
	})

    headers.map(it => `${it}`).join(',');
    rows.map(it => `${it}`).join(',');
    
    this.setState({csv: [headers,rows]}) 
  }

  render(){
    return(
      <div>
        <Header />
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="secondary-navbar" fixed="top">
            <Nav className="mr-auto">
            </Nav>
            <Nav>
                {this.renderExportBtn()}
            </Nav>
        </Navbar>
        <div className="sdrop-body flex-column">
          <div className="table-wrapper products-table">    
              <h2>Products</h2>                 
              <table className="table">
                  <thead>
                      <tr>
                          <th scope="col" onClick={() => this.sortByField('Brand')}>Brand/Display Name {this.renderArrow(this.state.sortByBrand)}</th>
                          <th scope="col" onClick={() => this.sortByField('GroupName')}>Group Name {this.renderArrow(this.state.sortByGroupName)}</th>
                          <th scope="col" className="text-center" onClick={() => this.sortByField('ProductType')}>Product Type {this.renderArrow(this.state.sortByProductType)}</th>
                          <th scope="col" className="text-center" onClick={() => this.sortByField('Status')}>Status {this.renderArrow(this.state.sortByStatus)}</th>
                          <th scope="col" className="text-center" onClick={() => this.sortByField('Inventory')}>Inventory {this.renderArrow(this.state.sortByInventory)}</th>
                          <th scope="col" className="text-center" onClick={() => this.sortByField('Price')}>Price {this.renderArrow(this.state.sortByPrice)}</th>
                          <th scope="col" className="text-center">Possible Article Count</th>
                          <th scope="col" className="text-center">Description</th>
                          <th scope="col" className="text-center">Min/Max Quantity</th>
                          <th scope="col" className="text-center">Badges</th>
                          <th scope="col" className="text-center">Product Image</th>
                      </tr>
                  </thead>
                  <tbody>
                      { this.renderTableBody() }
                  </tbody>
              </table>
          </div>
          <div style={{ marginBottom: 100 }} />
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  const {productGroups, productGroupsError, products, productsError} = state.products;
  const { unauthorized } = state.users;
  return { productGroups, productGroupsError, products, productsError, unauthorized };
}

export default connect(mapStateToProps, {
    getProducts,
    getProductGroups
})(Products);
