import {
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_FAIL,
    GETTING_CUSTOMER,
    GET_CUSTOMER_EVENTS_SUCCESS,
    GET_CUSTOMER_EVENTS_FAIL,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_FAIL,
    GET_FEEDBACK_FAIL,
    GET_FEEDBACK_SUCCESS,
    GET_MANIFEST_SUCCESS,
    GET_MANIFEST_FAIL,
    CLEAR_CUSTOMERS_STATE,
    UPDATING_STATUS,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_FAIL
} from '../actions/types';

const INITIAL_STATE = {
    customers: undefined,
    customer: undefined,
    feedback: undefined,
    events: undefined,
    error: undefined,
    eventsError: undefined
}

export default (state = INITIAL_STATE, action) => {
        switch(action.type){
            case GET_CUSTOMER_SUCCESS:
                return { ...state, customer: action.payload, getCustomerError: undefined, gettingCustomer: false };
            case GET_CUSTOMER_FAIL:
                return { ...state, customer: undefined, eventsError: action.error, gettingCustomer: false };
            case GETTING_CUSTOMER:
                return { ...state, customer: undefined, eventsError: undefined, gettingCustomer: true };
            case GET_CUSTOMER_EVENTS_SUCCESS:
                return { ...state, events: action.payload, eventsError: '' };
            case GET_CUSTOMER_EVENTS_FAIL:
                return { ...state, customer: undefined, error: action.payload };
            case GET_CUSTOMERS_SUCCESS:
                return { ...state, customers: action.payload, error: undefined };
            case GET_CUSTOMERS_FAIL:
                return { ...state, customers: undefined, error: action.payload };
            case GET_FEEDBACK_SUCCESS:
                return { ...state, feedback: action.payload, error: undefined };
            case GET_FEEDBACK_FAIL:
                return { ...state, feedback: undefined, error: action.payload };
            case GET_MANIFEST_SUCCESS:
                return { ...state, manifestRes: action.payload, manifestError: undefined };
            case GET_MANIFEST_FAIL:
                return { ...state, manifestRes: undefined, manifestError: action.error };
            case UPDATING_STATUS:
                return { ...state, updatingStatus: true, updateStatusSuccess: false, updateStatusError: undefined}
            case UPDATE_STATUS_SUCCESS:
                return { ...state, updatingStatus: false, updateStatusSuccess: action.payload, updateStatusError: undefined}
            case UPDATE_STATUS_FAIL:
                return { ...state, updatingStatus: false, updateStatusSuccess: false, updateStatusError: action.error}
            case CLEAR_CUSTOMERS_STATE:
                return { 
                    ...state,
                    manifest: undefined,
                    manifestRes: undefined,
                    manifestError: undefined,
                    customer: undefined,
                    customers: undefined,
                    feedback: undefined,
                    eventsError: undefined, 
                    events: undefined,
                    updateStatusError: undefined,
                    updateStatusSuccess: undefined,
                }
            default:
                return state;
        }
}