import React from 'react';
import {Navbar, Button, Nav} from 'react-bootstrap';

const CustomerNav = ({history, showMessage, showDeactivate, customerId}) => {
    return (
        <Navbar collapseOnSelect bg="dark" variant="dark" className="secondary-navbar" fixed="top">
            <Nav className="mr-auto">
                <Nav.Link onClick={() => history.push(`/customers/${customerId}`)}>Customer Details</Nav.Link>
                <Nav.Link onClick={() => history.push(`/customers/${customerId}/orders`)}>Order History</Nav.Link>
                <Nav.Link onClick={() => history.push(`/customers/${customerId}/manifest`)}>Update Manifest</Nav.Link>
                <Nav.Link onClick={() => history.push(`/customers/${customerId}/emergency_order`)}>Emergency Order</Nav.Link>
                <Nav.Link onClick={() => history.push(`/customers/${customerId}/events`)}>Events</Nav.Link>
            </Nav>
            <Nav className={`${showMessage ? '' : 'hide'}`}>
                <Button className='btn' variant="outline-light" onClick={showMessage}>Message User</Button>
            </Nav>
        </Navbar>
    )
}

export default CustomerNav;