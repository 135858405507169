import{
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCT_GROUPS_SUCCESS,
  GET_PRODUCT_GROUPS_FAIL,
} from '../actions/types';

const INITIAL_STATE = {
  error: '',
  products: undefined,
  isProductSaving: false,
  isProductDeleting: false
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case GET_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload, productError: '' };
    case GET_PRODUCTS_FAIL:
      return { ...state, products: undefined, productsError: action.error };
    case GET_PRODUCT_GROUPS_SUCCESS:
      return { ...state, productGroups: action.payload, productGroupsError: '' };
    case GET_PRODUCT_GROUPS_FAIL:
      return { ...state, productGroups: undefined, productGroupsError: action.error };
    default:
      return state;
  }
}
