import {
    GET_ROUNDER_SUCCESS,
    GET_ROUNDER_FAIL,
    GET_AUTOROUNDER_SUCCESS,
    GET_AUTOROUNDER_FAIL,
    CLEAR_INTERVIEW,
    CLEAR_SIMULATOR,
    SIMULATE_FAIL,
    SIMULATE_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    rounder: undefined,
    error: '',
    simulations: [],
    manifests: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ROUNDER_SUCCESS:
            return { ...state, rounder: action.payload, error: '' };
        case GET_ROUNDER_FAIL:
            return { ...state, rounder: undefined, error: action.payload };
        case CLEAR_INTERVIEW:
            return { ...state, rounder: undefined, error: ''}
        case CLEAR_SIMULATOR:
            return { ...state, simulations: [], error: undefined}
        case SIMULATE_FAIL:
            return { ...state, simulations: [], error: action.error}
        case SIMULATE_SUCCESS:
            return { ...state, simulations: [...state.simulations, action.payload], error: undefined}
        case GET_AUTOROUNDER_FAIL:
            return { ...state, manifests: [...state.manifests], error: action.error}
        case GET_AUTOROUNDER_SUCCESS:
            return { ...state, manifests: [...state.manifests, action.payload], error: undefined}
        default:
            return state;
    }
};
