import axios from 'axios';
import {
	GET_PRODUCTS_SUCCESS,
	GET_PRODUCTS_FAIL,
	GET_PRODUCT_GROUPS_SUCCESS,
	GET_PRODUCT_GROUPS_FAIL,
	UNAUTHORIZED
} from './types';
import keys from '../config';
import { getAuthHeaders, handleApiError } from '../helpers/apiHelpers';
const url = keys.apiUrl;

export const getProducts = () => {
	return (dispatch) => {
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/products`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_PRODUCTS_SUCCESS, payload: res.data });
					else
						dispatch(handleApiError(res.error, GET_PRODUCTS_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_PRODUCTS_FAIL))
				})
        }).catch(err => {
			dispatch(handleApiError(err, GET_PRODUCTS_FAIL))
		})
	}
}

export const getProductGroups = () => {
	return (dispatch) => {
		getAuthHeaders().then(headers => {
			axios.get(`${url}/admin/product_groups`, headers)
				.then(res => {
					if (res.status === 200)
						dispatch({ type: GET_PRODUCT_GROUPS_SUCCESS, payload: res.data.productGroups });
					else
						dispatch(handleApiError(res.error, GET_PRODUCT_GROUPS_FAIL))
				})
				.catch(err => {
					if (err === 'UNAUTHORIZED')
						dispatch(handleApiError(err, UNAUTHORIZED))
					else
						dispatch(handleApiError(err, GET_PRODUCT_GROUPS_FAIL))
				})
        }).catch(err => {
			dispatch(handleApiError(err, GET_PRODUCT_GROUPS_FAIL))
		})
	}
}
