import {
    SEND_SMS_SUCCESS,
    SEND_SMS_FAIL,
    SENDING_SMS,
    CLEAR_SMS
} from '../actions/types';

const INITIAL_STATE = {
    messageSuccess: undefined,
    messageError: undefined,
    sendingSMS: undefined
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_SMS_SUCCESS:
            return { ...state, messageSuccess: true, messageError: false, sendingSMS: false };
        case SEND_SMS_FAIL:
            return { ...state, messageSuccess: true, messageError: action.error, sendingSMS: false };
        case SENDING_SMS:
            return { ...state, messageError: undefined, messageSuccess: undefined, sendingSMS: true};
        case CLEAR_SMS:
            return {...state, messageSuccess: undefined, messageError: undefined, sendingSMS: false}
        default:
            return state;
    }
}