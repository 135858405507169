import React, { Component } from 'react';
import Details from './Details';
import CustomerNav from './CustomerNav';
import Header from '../Header';
import Interview from './Interview';
import FirstOrder from './FirstOrder';
import Projection from './Projection';


class CustomerDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        const {customerId} = params; 
        this.setState({ customerId });
    }

    render() {
        let { customerId } = this.state;
        return (
            <div>
                <Header />
                <CustomerNav history={this.props.history} customerId={customerId} showMessage={() => this.setState({ showMessage: true })} />
                <div className="sdrop-body flex-column extra-margin-top">
                    <Details
                        history={this.props.history}
                        showMessage={this.state.showMessage}
                        closeMessage={() => this.setState({ showMessage: false })}
                    />
                    <Projection />
                    <FirstOrder />
                    <Interview />
                </div>
            </div>
        )
    }
}

export default CustomerDetails;