import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import {connect} from 'react-redux';
import { Loading } from '../Common';

class Interview extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidUpdate() {
        const {customer} = this.props;
        if(customer && !this.state.customer) {
            this.setState({customer});
        }
    }

    renderInterview() {
        const {customer} = this.state;
        if(customer) {
            const {interview} = customer;
            const entries = Object.entries(interview);  
            const rows = entries.map(entry => {
                return (
                    <tr className="customer-table-row">
                    <th>{entry[0]}</th>
                    <td>{JSON.stringify(entry[1])}</td>
                    </tr>
                )
            })
        
            return (
                <Row>
                    <Col xs={12}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Question</th>              
                            <th>Response</th>              
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                    </Col>
                </Row>
            )
        } else {
            return (<Loading />)
        }
      }

    render() {
        return (
            <Container>
                <h2>Interview Responses</h2>
                {this.renderInterview()}
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    const {customer, error} = state.customers;
    return {customer, error}
}

export default connect(mapStateToProps, {})(Interview);