import React, {Component, Fragment} from 'react';
import Slider from '@material-ui/core/Slider';
import {connect} from 'react-redux';
import {adjustCadence} from '../../actions'

class CadenceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newCadence: ""
        }

        this.handleChange = this.handleChange.bind(this);
    }
    
    componentDidMount() {
        this.setState({newCadence: this.props.cadenceWeeks})
    }

    getMarks() {
        const marks = [];
        for(let i = 2; i < 14; i++) {
            marks.push({
                value: i,
                label: i
            })
        }
        return marks;
    }

    handleChange(value) {
        this.setState({newCadence: parseInt(value)})
    }

    renderSlider() {
        const {cadenceWeeks} = this.props;
        const marks = this.getMarks();
        return (
            <Slider
                min={marks[0].value}
                max={marks[marks.length - 1].value}
                marks={marks}
                defaultValue={cadenceWeeks}
                onChange={(e, value) => this.handleChange(value)}
            />
        )
    }

    renderHeader() {
        const {customer} = this.props;
        return (
            <div className="space-bottom">
                Adjust cadence weeks for {customer.firstName} {customer.lastName}
            </div>
        )
    }

    renderNewCadence() {
        const {newCadence} = this.state;
        return (
            <div style={{fontSize: 32, marginTop: 20}} className="space-bottom">
                New Cadence: every {newCadence} weeks
            </div>
        )
    }

    renderOldCadence() {
        const {cadenceWeeks} = this.props;
        return (
            <div style={{fontSize: 20, marginTop: 20}} className="space-bottom">
                Current Cadence: every {cadenceWeeks} weeks
            </div>
        )
    }

    renderButtons() {
        const {newCadence} = this.state;
        const {cadenceWeeks, cancel, customer} = this.props;
        return (
            <div className="flex-row flex-space-around">
                <button className="btn btn-secondary dialog-btn-ok" onClick={cancel}>Cancel</button>
                <button className="btn-primary dialog-btn-ok" disabled={cadenceWeeks === newCadence} onClick={() => this.props.adjustCadence(customer.customerId, newCadence)}>Submit</button>
            </div>
        )
    }

    renderStatus() {
        const {adjustingCadence, adjustCadenceError, adjustCadenceSuccess, successClose, cancel} = this.props;
    
        let body, button;
        let bodyClasslist = "dialog-title-text";

        if(adjustingCadence) {
            body = "Attempting update..."
        } else if (adjustCadenceSuccess) {
            body = "Cadence update was successful!"
            bodyClasslist += " green";
            button = <button className="btn btn-secondary dialog-btn-ok" onClick={successClose}>Close</button>
        } else if (adjustCadenceError) {
            body = <p>Update failed<br/>{adjustCadenceError.response && adjustCadenceError.response.data ? adjustCadenceError.response.data : null}</p>
            bodyClasslist += " red";
            button = <button className="btn btn-secondary dialog-btn-ok" onClick={cancel}>Close</button>
        }

        return (
            <div className="modal-container">
                <div className="alert-dialog-medium flex-column text-center">
                    <div className={bodyClasslist}>
                        {body}
                    </div>
                    {button}
                </div>
            </div>
        )
    }

    renderMain() {
        const {adjustingCadence, adjustCadenceError, adjustCadenceSuccess}  = this.props;
        if(adjustingCadence || adjustCadenceError || adjustCadenceSuccess) {
            return this.renderStatus();
        } else {
            return (
                <Fragment>
                    {this.renderHeader()}
                    {this.renderOldCadence()}
                    {this.renderSlider()}
                    {this.renderNewCadence()}
                    {this.renderButtons()}
                </Fragment>
            )
        }
    }

    render() {
        return (
            <div className="modal-container">
                <div className="alert-dialog-medium flex-column text-center">
                   {this.renderMain()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {adjustCadenceSuccess, adjustCadenceError, adjustingCadence} = state.orders;
    return {adjustCadenceSuccess, adjustCadenceError, adjustingCadence}
}

export default connect(mapStateToProps, {
    adjustCadence
})(CadenceModal);