import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	sendSMS,
	getCustomer,
	getManifest,
	isAdmin,
	getRounder,
	clearMessageState,
	updateCustomerStatus,
	clearCustomersState,
	clearOrderState
} from '../../actions';
import { Row, Col, Container, Alert } from 'react-bootstrap';
import '../Simulator/factors.css';
import '../CustomerOrders/details.css';
import { Auth } from 'aws-amplify';
import CustomerStatus from './CustomerStatus';
import DateSelection from './DateSelection';
import CadenceModal from './CadenceModal';
import { Loading } from '../Common';

class Details extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.sendSMS = this.sendSMS.bind(this);
		this.formatInterview = this.formatInterview.bind(this);
		this.viewDates = this.viewDates.bind(this);
		this.closeDates = this.closeDates.bind(this);
		this.resetManifest = this.resetManifest.bind(this);
	}

	componentDidMount() {
		const customerId = window.location.pathname.split('/')[2]
		this.props.getCustomer(customerId);
		this.props.getManifest(customerId);
		this.setState({customerId})
		Auth.currentAuthenticatedUser().then(data => {
			const jwt = data.signInUserSession.idToken.jwtToken;
			const authed = isAdmin(jwt);
			if (!authed) {
				this.props.history.push('/');
			}
		}).catch(err => {
			this.props.history.push('/');
		})
	}
	
	componentWillUnmount() {
		this.props.clearCustomersState();
	}

	componentDidUpdate() {
		const { messageSuccess, showMessage, messageError, manifestRes, manifestError } = this.props;
		if (this.props.customer && !this.state.customer) {
			this.setState({ customer: this.props.customer });
		}
		if (this.state.customer && !this.state.gettingRounder && !this.state.rounder) {
			const interview = this.formatInterview(this.state.customer.interview);
			this.props.getRounder(interview);
			this.setState({ interview, gettingRounder: true })
		}

		if (manifestRes && !this.state.manifest) {
			this.setState({ manifest: manifestRes.manifest, allowedDeliveryDates: manifestRes.allowedDeliveryDates });
		}

		if (manifestError && !this.state.manifestError) {
			this.setState({ manifestError })
		}

		if (messageSuccess && showMessage) {
			setTimeout(() => {
				this.props.clearMessageState();
				this.props.closeMessage();
			}, 3000)
		}
		if (messageError && showMessage) {
			setTimeout(() => {
				this.props.clearMessageState();
				this.props.closeMessage();
			}, 3000)
		}
	}

	formatInterview(interview) {
		const { customer } = this.state;
		const results = {
			name: customer.firstName,
			bathrooms: interview.bathrooms,
			dishwashing: {
				frequency: interview.dishwashingFrequency,
				products: interview.dishwashingProducts
			},
			laundry: { preferences: interview.laundryPreferences, frequency: interview.laundryFrequency },
			family: interview.family,
			guests: interview.guestFrequency,
			housekeeping: interview.housekeepingFrequency,
			pets: interview.pets,
			scentPreferences: interview.scentPreferences,
			refills: interview.refills,
			trashPreferences: interview.trash,
			tierPreferences: interview.tierPreferences
		}
		return results;
	}

	formatPhone(value) {
		let num = '';
		num = `(${value.slice(2, 5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`;
		return num;
	}

	renderCustomerInfo() {
		const { customer } = this.state;
		const { firstName, lastName, customerId, shipping, status, phone, email, createdAt } = customer;
		const { address, city, deliveryInstructions, zipcode, state } = shipping;

		return (
			<Fragment>
				<Col xs={6} className="customer-card">
					<Row><b>{firstName} {lastName}</b></Row>
					<Row>{customerId}</Row>
					<Row>{address}</Row>
					<Row>{city}, {state} {zipcode}</Row>
					<Row>Delivery Instructions: {deliveryInstructions ? deliveryInstructions : "None"}</Row>
					<Row>{this.formatPhone(phone)} - {email}</Row>
					<Row><b>Customer Since:</b> {createdAt ? moment.utc(createdAt).local().format('lll') : "Unknown"}</Row>
					<br />
					<Row>Current Status:<b> {status}</b></Row>
					<Row className="link pointer" onClick={() => this.setState({ showPauseModal: true })}>{status === "Paused" ? "Need to resume customer?" : "Need to Pause/Deactivate Customer?"}</Row>
				</Col>
				{this.renderManifestDetails()}
			</Fragment>
		)
	}

	renderManifestDetails() {
		const {manifest, manifestError, allowedDeliveryDates} = this.state;
		if(manifest) {
			const {cadenceWeeks} = manifest;
			return (
				<Col xs={6}>
					<Row><b>Manifest:</b></Row>
					<Row>Contact Date: {moment(manifest.contactAt).format('ll')}</Row>
					<Row>Ship Date: {moment(manifest.shipAt).format('ll')}</Row>
					<Row>Deliver Date: {moment(manifest.deliverAt).format('ll')}</Row>
					<Row>Customer Viewed? {manifest.viewed.toString()}</Row>
					<Row>Customer Modified? {manifest.modified.toString()}</Row>
					<Row className={allowedDeliveryDates && allowedDeliveryDates.length ? "" : "hide"}><div className="link" onClick={this.viewDates}>Change Manifest Date</div></Row>
					<br />
					<Row>Cadence:<b> {cadenceWeeks} weeks</b></Row>
					<Row className="link pointer" onClick={() => this.setState({ showCadenceModal: true })}>Adjust cadence</Row>
				</Col>
			)
		} else if (manifestError) {
			return (
				<Col xs={6}>
					Could not retrive manifest for user
				</Col>
			)
		} else {
			return <Loading />
		}
	}

	viewDates() {
		this.setState({viewingDates: true})
	}

	closeDates() {
		this.setState({viewingDates: false})
	}

	sendSMS() {
		const { customer } = this.state;
		this.props.sendSMS(customer.customerId)
	}

	resetManifest() {
		const {customerId} = this.state;
		this.props.clearOrderState()
		this.props.clearCustomersState()
		this.props.getManifest(customerId)
		this.setState({showCadenceModal: false, manifest: undefined})
	}

	renderDates() {
		const {viewingDates, allowedDeliveryDates, manifest, customer} = this.state;
		if(viewingDates && allowedDeliveryDates && allowedDeliveryDates.length) {
			return <DateSelection allowedDeliveryDates={allowedDeliveryDates} customer={customer} manifest={manifest} close={() => this.setState({viewingDates: false})} updateCustomerStatus={this.props.updateCustomerStatus} />
		}
	}

	renderPauseModal() {
		const { showPauseModal, customer, manifest, allowedDeliveryDates } = this.state;
		if (showPauseModal) {
			return (<CustomerStatus customer={customer} closeModal={() => this.setState({ showPauseModal: false })} manifest={manifest} allowedDeliveryDates={allowedDeliveryDates} />)
		}
	}
	
	renderCadenceModal() {
		const { showCadenceModal, customer, manifest } = this.state;
		if (showCadenceModal && manifest && customer) {
			return (<CadenceModal customer={customer} cancel={() => this.setState({ showPauseModal: false })} successClose={this.resetManifest} cadenceWeeks={manifest.cadenceWeeks} />)
		}
	}

	renderMessageModal() {
		const { customer } = this.state;
		const { showMessage, closeMessage, sendingSMS, messageSuccess, messageError } = this.props;

		if (showMessage) {
			if (sendingSMS) {
				return (
					<div className="modal-container">
						<div className="alert-dialog-medium flex-column text-center">
							<div className="dialog-title-text">
								Sending...
                  			</div>
						</div>
					</div>
				)
			} else if (messageSuccess) {
				return (
					<div className="modal-container">
						<div className="alert-dialog-medium flex-column text-center">
							<div className="dialog-title-text green">
								Message sent successfully!! :)
                  			</div>
						</div>
					</div>
				)
			} else if (messageError) {
				return (
					<div className="modal-container">
						<div className="alert-dialog-medium flex-column text-center">
							<div className="dialog-title-text red">
								Message failed :(
                  			</div>
						</div>
					</div>
				)
			} else {
				return (
					<div className="modal-container">
						<div className="alert-dialog-medium flex-column text-center">
							<div className="dialog-title-text">
								Send SMS to User
                  			</div>
							<div>Send an SMS alert to {customer.firstName} {customer.lastName} @ {customer.phone} that their next order is upcoming?</div>
							<div className="flex-row flex-space-between">
								<button className="btn btn-secondary dialog-btn-ok" onClick={closeMessage}>Cancel</button>
								<button className="btn-primary dialog-btn-ok" onClick={this.sendSMS}>Send</button>
							</div>
						</div>
					</div>
				)
			}
		}
	}

	render() {
		const { customer, manifestError } = this.state;
		if (customer) {
			return (
				<div>
					<Container>
						<Row><h2 style={{ marginTop: 20 }}>Customer Details</h2></Row>
						<Row className="flex-row flex-space-between">
							{this.renderDates()}
							{this.renderMessageModal()}
							{this.renderPauseModal()}
							{this.renderCadenceModal()}
						</Row>
						<div style={{ marginBottom: 20 }} />
						<Row>
							{this.renderCustomerInfo()}
						</Row>
					</Container>
					<div style={{ marginBottom: 100 }} />
				</div>
			);
		} else if (!manifestError) {
			return (
				<Loading />
			)
		} else {
			return <Alert variant="danger">Error getting manifest{manifestError.data ? ` ${manifestError.data.response}` : ""}</Alert>
		}
	}
}

const mapStateToProps = (state) => {
	const { customer, error, manifestRes, manifestError } = state.customers;
	const { rounder } = state.interviews;
	const { messageSuccess, messageError, sendingSMS } = state.messaging;
	return { customer, error, messageSuccess, messageError, rounder, sendingSMS, manifestRes, manifestError };
}

export default connect(mapStateToProps, {
	sendSMS,
	getCustomer,
	getRounder,
	getManifest,
	clearMessageState,
	updateCustomerStatus,
	clearOrderState,
	clearCustomersState
})(Details);
