import React, {Component, Fragment} from 'react';
import {updateCustomerStatus, clearCustomersState} from '../../actions';
import {connect} from 'react-redux';
import DateSelection from './DateSelection';

class CustomerStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.updateStatus = this.updateStatus.bind(this);
        this.clearAndClose = this.clearAndClose.bind(this);
    }

    updateStatus(status) {
        try {
            const {customer} = this.props;
            const {customerId} = customer;
            this.props.updateCustomerStatus(customerId, status);
            this.setState({status})
        } catch(err) {
            console.error(err);
        }
    }

    clearAndClose() {
        this.props.clearCustomersState();
        this.props.closeModal();
    }

    renderUpdatingModal() {
        const {updatingStatus} = this.props;
        if(updatingStatus) {
            return (
            <div className="modal-container">
                <div className="alert-dialog-medium flex-column text-center">
                    <div className="dialog-title-text">
                        Updating...
                    </div>
                </div>
            </div>
            )
        }
    }

    renderSuccess() {
        const {updateStatusSuccess} = this.props;
        if (updateStatusSuccess) {
            return (
            <div className="modal-container">
                <div className="alert-dialog-medium flex-column text-center">
                    <div className="dialog-title-text green">
                        Update was successful! :)
                    </div>
                    <button className="btn btn-secondary dialog-btn-ok" onClick={this.clearAndClose}>Close</button>
                </div>
            </div>
            )
        }
    }

    renderError() {
        const {updateStatusError} = this.props;
        if (updateStatusError) {
            return (
                <div className="modal-container">
                    <div className="alert-dialog-medium flex-column text-center">
                        <div className="dialog-title-text red">
                            Update failed :(
                        </div>
                        <button className="btn btn-secondary dialog-btn-ok" onClick={this.clearAndClose}>Close</button>
                    </div>
                </div>
            )
        }
    }

    renderChoices() {
        const {customer, closeModal, manifest, allowedDeliveryDates} = this.props;
        if(customer.status === "Paused") {
            return (
                <DateSelection customer={customer} manifest={manifest} allowedDeliveryDates={allowedDeliveryDates} close={closeModal} updateCustomerStatus={this.props.updateCustomerStatus} />
            )
        } else {
            return (
                <Fragment>
                    <div className="dialog-title-text">
                        Pause/Deactivate Customer
                    </div>
                    <div>Update status of {customer.firstName} {customer.lastName} in {customer.shipping.city}, {customer.shipping.state}?</div>
                    <div className="flex-col-center flex-space-around modal-button-col">
                        <button className="btn btn-secondary dialog-btn-ok" onClick={closeModal}>Back</button>
                        <button className="btn-primary dialog-btn-ok" onClick={() => this.updateStatus('pause')}>Pause</button>
                        <button className="btn-danger dialog-btn-ok" onClick={() => this.updateStatus('deactivate')}>Deactivate</button>
                    </div>
                </Fragment>
            )
        }
    }

    render() {
        return (
            <div className="modal-container">
                <div className="alert-dialog-medium flex-column text-center">
                    {this.renderChoices()}
                    {this.renderError()}
                    {this.renderSuccess()}
                    {this.renderUpdatingModal()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {updatingStatus, updateStatusSuccess, updateStatusError} = state.customers;;
    return {updatingStatus, updateStatusSuccess, updateStatusError}
}   

export default connect(mapStateToProps, {
    updateCustomerStatus,
    clearCustomersState
})(CustomerStatus);
