import {
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  UNAUTHORIZED,
  SIGNING_IN,
  CLEAR_UNAUTHED_STATE
} from '../actions/types';

const INITIAL_STATE = {
  error: '',
  user: undefined,
  unauthorized: false,
  signingIn: false
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case SIGNING_IN: 
        return { ...state, signingIn: true, error: '', unauthorized: false }
    case SIGNIN_SUCCESS:
        return { ...state, user: action.payload, signingIn: false, error: ''};
    case SIGNIN_FAIL:
        return { ...state, error: action.payload, signingIn: false, user: undefined};
    case CLEAR_UNAUTHED_STATE:
        return {...state, unauthorized: false}
    case UNAUTHORIZED:
        return { ...state, unauthorized: true}
    default:
      return state;
  }
}
