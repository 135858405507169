import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { refundOrder } from '../../actions';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { Alert } from 'react-bootstrap';

class Refund extends Component {
    constructor(props) {
        super(props);

        this.state = {
            taxRate: 0,
            discountPercentage: 0,
            memo: ""
        };

        this.setRefundProducts = this.setRefundProducts.bind(this);
        this.setProductRefundQuantity = this.setProductRefundQuantity.bind(this);
        this.toggleRefundingAll = this.toggleRefundingAll.bind(this);
        this.renderRefundTotals = this.renderRefundTotals.bind(this);
        this.setTaxAndDiscountRate = this.setTaxAndDiscountRate.bind(this);
        this.createRefund = this.createRefund.bind(this);
        this.validateMemo = this.validateMemo.bind(this);
        this.setIds = this.setIds.bind(this);
    }

    componentDidMount() {
        this.setRefundProducts();
        this.setTaxAndDiscountRate();
        this.setIds();
    }

    setIds() {
        const { order, customer } = this.props;
        const { orderId } = order;
        const { customerId } = customer;
        this.setState({ customerId, orderId });
    }

    setRefundProducts() {
        try {
            const { productsMap } = this.props;
            const productsToRefund = {};
            Object.entries(productsMap).forEach(entry => {
                const productId = entry[0];
                productsToRefund[productId] = 0;
            })
            this.setState({ productsToRefund });
        } catch (err) {
            console.error(err);
        }
    }

    setTaxAndDiscountRate() {
        try {
            const { order } = this.props;
            const { discountCents, taxCents, taxes, discounts } = order;
            const discountRate = discountCents ? discounts[0].rate : 0;
            const taxRate = taxCents ? taxes[0].rate : 0;

            if(taxes.length > 1 || discounts.length > 1) {
                Sentry.captureException(new Error("Taxes or discounts length greater than expected"))
            }
            
            this.setState({ taxRate, discountRate });
        } catch (err) {
            console.error(err);
        }
    }

    validateMemo() {
        const { memo } = this.state;
        if (!memo) {
            this.setState({ memoInvalid: true })
        }
    }

    checkForSameGroupName(groupName) {
        try {
            const { groups } = this.props;
            const { productsToRefund } = this.state;
            if (groups[groupName].length > 1) {
                groups[groupName].forEach(product => {
                    if (!productsToRefund[product.productId].quantity) {
                        let e = new Error(groupName)
                        e.name = 'groupNameWarning';
                        throw e;
                    }
                })
            }
        } catch (err) {
            if (err.name === 'groupNameWarning') {
                return groupName;
            } else {
                console.error(err);
                Sentry.captureException(err);
                return new Error(err);
            }
        }
    }

    createRefund() {
        try {
            const { isRefundingAll, memo, productsToRefund, customerId, orderId, groupNameWarning } = this.state;
            const { productsMap } = this.props;
            if (memo) {
                let body;
                if (isRefundingAll) {
                    body = { full: true, memo };
                } else {
                    const products = {};
                    Object.entries(productsToRefund).forEach(entry => {
                        const productId = entry[0];
                        const value = entry[1];
                        if (value) {
                            const product = productsMap[productId];
                            if (product.packages === value && !groupNameWarning) {
                                const groupName = this.checkForSameGroupName(product.groupName);
                                if (groupName) {
                                    let e = new Error(`Some products of ${groupName} are not included in refund. Proceed anyway?`);
                                    e.name = "groupNameWarning";
                                    throw e;
                                }
                            }
                            products[productId] = value
                        }
                    })
                    if (Object.values(products).length) {
                        body = { products, memo }
                    } else throw new Error('Products list cannot be empty');
                }
                this.props.refundOrder(customerId, orderId, body);
            } else {
                this.setState({ isMemoInvalid: true })
            }
        } catch (err) {
            if (err.message === 'Products list cannot be empty') {
                this.setState({ emptyProductsError: 'Products list cannot be empty' })
            } else if (err.name === "groupNameWarning") {
                this.setState({ groupNameWarning: err.message })
            } else {
                Sentry.captureException(err)
                console.error(err);
            }
        }
    }

    toggleRefundingAll() {
        const { isRefundingAll } = this.state;
        this.setState({ isRefundingAll: !isRefundingAll })
    }

    setProductRefundQuantity(productId, value) {
        try {
            const { productsToRefund } = this.state;
            const { productsMap } = this.props;
            const product = productsMap[productId];
            if (parseInt(value) <= product.chargedQuantity) {
                productsToRefund[productId] = parseInt(value)
                this.setState({ productsToRefund, emptyProductsError: false });
            }
        } catch (err) {
            console.error(err);
        }
    }

    renderProducts() {
        const { productsMap } = this.props;
        const { productsToRefund, isRefundingAll } = this.state;
        if (productsMap && productsToRefund) {
            const rows = Object.values(productsMap).map(product => {
                const { pricePerArticleCents, displayName, brandName, articlesPerPackage, chargedQuantity, productId } = product;
                const refundQuantity = isRefundingAll ? chargedQuantity : productsToRefund[productId];
                return (
                    <tr className={`${refundQuantity ? "refund-select-row" : ""}${refundQuantity === chargedQuantity ? " full" : " partial"}`}>
                        <td>
                            <input
                                type="number"
                                onChange={e => this.setProductRefundQuantity(productId, e.target.value)}
                                min={0}
                                max={chargedQuantity}
                                value={refundQuantity}
                            />
                        </td>
                        <td>
                            {brandName} {displayName}
                        </td>
                        <td>
                            {chargedQuantity} packages<br />
                                ({articlesPerPackage} articles per package)<br />
                        </td>
                        <td>
                            ${(pricePerArticleCents / 100).toFixed(2)}
                        </td>
                    </tr>
                )
            })
            return (
                <Fragment>
                    {this.renderOrderInfo()}
                    <h4>Manifest:</h4>
                    <input
                        type="checkbox"
                        checked={isRefundingAll}
                        onChange={this.toggleRefundingAll}
                    />
                    <label className="link" onClick={this.toggleRefundingAll} style={{ paddingLeft: 12 }}>Refund Entire Order</label>
                    <table>
                        <thead>
                            <tr>
                                <th>Refund Quantity</th>
                                <th>Product Description</th>
                                <th>Quantity</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </Fragment>
            )
        }
    }

    renderOrderInfo() {
        const { order, customer } = this.props;
        if (customer && order) {
            const { firstName, lastName, customerId, shipping } = customer;
            const { state, city } = shipping;
            const { orderId, expectedDeliveryDate } = order;
            return (
                <div className="refund-order-info">
                    <h3>{firstName} {lastName} - {city}, {state}</h3>
                    <p><b>Customer Id:</b> {customerId}<br />
                        <b>Order Id:</b> {orderId}<br />
                        <b>Delivery Date:</b> {moment(expectedDeliveryDate).format('ll')}</p>
                </div>
            )
        }
    }

    renderButtons() {
        const { close } = this.props;
        return (
            <div className="flex-row flex-space-around">
                <button className="btn btn-secondary dialog-btn-ok" onClick={close}>Cancel</button>
                <button className="btn-primary dialog-btn-ok" onClick={this.createRefund}>Submit Refund</button>
            </div>
        )
    }

    renderRefundTotals() {
        try {
            const { productsToRefund, discountRate, taxRate } = this.state;
            const { productsMap } = this.props;
            let subtotalCents = 0;
            Object.entries(productsToRefund).forEach(entry => {
                const productId = entry[0];
                const quantity = entry[1];
                if (quantity) {
                    const product = productsMap[productId];
                    const { pricePerArticleCents, articlesPerPackage } = product;
                    subtotalCents += (pricePerArticleCents * quantity * articlesPerPackage);
                }
            })
            return (
                <p>
                    <b>Subtotal:</b> ${(subtotalCents / 100).toFixed(2)}<br />
                    {discountRate ? <Fragment><b>Discount:</b> ${(subtotalCents / 100 * discountRate).toFixed(2)}<br /></Fragment> : null}
                    <b>Estimated Tax:</b> ${(taxRate * subtotalCents / 100).toFixed(2)}<br />
                    <b>Total:</b> ${(((taxRate * subtotalCents) + (subtotalCents / 100 * discountRate) + subtotalCents) / 100).toFixed(2)}
                </p>
            )
        } catch (err) {
            console.error(err);
        }

    }

    renderTotals() {
        const { order } = this.props;
        const { isRefundingAll, productsToRefund } = this.state;
        const { taxCents, discountCents, subtotalCents } = order;

        if (order && productsToRefund) {
            const originalTotals = () => {
                return (
                    <p>
                        <b>Subtotal:</b> ${(subtotalCents / 100).toFixed(2)}<br />
                        {discountCents ? <Fragment><b>Discount:</b> ${(discountCents / 100).toFixed(2)}<br /></Fragment> : null}
                        <b>Tax:</b> ${(taxCents / 100).toFixed(2)}<br />
                        <b>Total:</b> ${((taxCents + discountCents + subtotalCents) / 100).toFixed(2)}
                    </p>
                )
            }

            const refundTotals = isRefundingAll ? originalTotals : this.renderRefundTotals;

            return (
                <div className="refund-totals-container">
                    <div>
                        <u><b>Original Totals:</b></u><br />
                        {originalTotals()}
                    </div>
                    <div>
                        <u><b>Refund Totals:</b></u><br />
                        {refundTotals()}
                    </div>
                </div>
            )
        }
    }

    renderStatus() {
        const { refunding, refundSuccess, refundError, close } = this.props;

        let body, button;
        let bodyClasslist = "dialog-title-text";

        if (refunding) {
            body = "Attempting refund..."
        } else if (refundSuccess) {
            body = "Refund was successful!"
            bodyClasslist += " green";
            button = <button className="btn btn-secondary dialog-btn-ok" onClick={close}>Close</button>
        } else if (refundError) {
            body = <p>Refund failed<br />{refundError.response && refundError.response.data ? refundError.response.data : null}</p>
            bodyClasslist += " red";
            button = <button className="btn btn-secondary dialog-btn-ok" onClick={close}>Close</button>
        }

        return (
            <div className="modal-container">
                <div className="alert-dialog-medium flex-column text-center">
                    <div className={bodyClasslist}>
                        {body}
                    </div>
                    {button}
                </div>
            </div>
        )
    }

    renderMemoInput() {
        const { memo, isMemoInvalid } = this.state;
        return (
            <div id="refund-memo-container">
                <textarea
                    id="refund-memo-input"
                    type="text"
                    value={memo}
                    onChange={e => this.setState({ memo: e.target.value, isMemoInvalid: false })}
                    placeholder="Memo"
                    className={isMemoInvalid ? "invalid" : ""}
                    onBlur={this.validateMemo}
                />
                <p id="refund-memo-invalid">{isMemoInvalid ? "Memo value is required" : ""}</p>
            </div>
        )
    }

    renderEmptyProductsError() {
        const { emptyProductsError } = this.state;
        if (emptyProductsError) {
            return <Alert variant="danger">{emptyProductsError}</Alert>
        }
    }

    renderGroupNameWarning() {
        const { groupNameWarning } = this.state;
        return (
            <div className="modal-container">
                <div className="alert-dialog-medium flex-column text-center">
                    <div className="dialog-title-text">
                        {groupNameWarning}
                    </div>
                    <div className="flex-row flex-space-around">
                        <button className="btn btn-secondary dialog-btn-ok" onClick={() => this.setState({ groupNameWarning: false })}>Go Back</button>
                        <button className="btn-primary dialog-btn-ok" onClick={this.createRefund}>Confirm Refund</button>
                    </div>
                </div>
            </div>
        )
    }

    renderMain() {
        const { groupNameWarning } = this.state;
        const { refunding, refundSuccess, refundError } = this.props;
        if (refundSuccess || refundError || refunding) {
            return this.renderStatus()
        } else if (groupNameWarning) {
            return this.renderGroupNameWarning();
        } else {
            return (
                <Fragment>
                    {this.renderProducts()}
                    {this.renderTotals()}
                    {this.renderMemoInput()}
                    {this.renderEmptyProductsError()}
                    {this.renderButtons()}
                </Fragment>
            )
        }
    }

    render() {
        return (
            <div className="modal-container">
                <div className="refund-container">
                    {this.renderMain()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { refundSuccess, refundError, refunding } = state.orders;
    return { refundSuccess, refundError, refunding };
}

export default connect(mapStateToProps, {
    refundOrder
})(Refund);