const images = {
    logo: require('./img/logo-supplydrop-orange.svg'),
    parachute: require('./img/SupplyDrop-Logo-Parachute.svg'),
    logoDark: require('./img/logo_supplyDrop_dark.svg'),
    trashcan: require('./img/trashcan.png'),
    edit: require('./img/edit.png'),
    addCircle: require('./img/circlePlus.png'),
    maleIcon: require('./img/ic_man.svg'),
    femaleIcon: require('./img/ic_woman.svg'),
    maleKidIcon: require('./img/ic_kid.svg'),
    femaleKidIcon: require('./img/ic-kid-girl.svg'),
    checkCircle: require('./img/circleCheck.png'),
    calculator: require('./img/ic-calculator.png'),
    calculatorBlue: require('./img/ic-calculator-blue.png'),
    tp: require('./img/tp_cartoon.jpeg'),
    instagram: require('./img/instagram_logo.svg'),
    parachute_lg: require('./img/parachute_lg.svg'),
    cloud_right: require('./img/cloud_right.png'),
    cloud_left: require('./img/cloud_left.svg'),

};

export default images;