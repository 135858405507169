import {
	SIGNIN_SUCCESS,
	SIGNIN_FAIL,
	UNAUTHORIZED,
	SIGNING_IN,
	CLEAR_UNAUTHED_STATE
} from './types';
import { Auth } from 'aws-amplify';
const jwtDecode = require('jwt-decode');

const doLog = true;

export const signIn = (email, password) => {
	doLog && console.log('[signIn]', email);

	return (dispatch) => {
		dispatch({
			type: SIGNING_IN
		});

		Auth.signIn(email, password).then(user => {
			Auth.currentSession()
				.then(data => {
					if (isAdmin(data.idToken.jwtToken)) {
						dispatch({
							type: SIGNIN_SUCCESS,
							payload: user
						})
					} else {
						dispatch({
							type: UNAUTHORIZED,
						})
					}
				})
		}).catch(err => {
			dispatch({
				type: SIGNIN_FAIL,
				payload: err
			})
		})
	}
}

export const isAdmin = (jwt) => {
	const decoded = jwtDecode(jwt);
	const groups = decoded['cognito:groups'];
	let admin = false;
	if (groups && groups.length > 0) {
		let i = 0;
		do {
			admin = groups[i] === 'supplyDropAdmins';
			i++;
		} while (admin === false || i < groups.length);
	}
	return admin;
}

export const clearUnauthed = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_UNAUTHED_STATE })
	}
}